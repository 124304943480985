unit Zemljevid;

interface

uses
  System.SysUtils, System.Variants, System.Classes, Web, System.DateUtils, Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.StdCtrls, TerminalForm,
  WEBLib.StdCtrls, WEBLib.ExtCtrls, Data.DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.Controls, WEBLib.Grids, WEBLib.DBCtrls, WEBLib.Forms,
  XData.Web.Connection, Vcl.Grids, WEBLib.Buttons, WEBLib.WebCtrls;

type
  TfrmZemljevid = class(TTerminalForm)
    wdsPotPodrobno: TXDataWebDataSet;
    dsPotPodrobno: TDataSource;
    panFooter: TPanel;
    btnSelect: TButton;
    geoLoc: TGeoLocation;
    tmrLocate: TTimer;
    btnRefresh: TButton;
    mapMain: TGoogleMaps;
    procedure WebFormCreate(Sender: TObject);
    procedure wdsPotPodrobnoAfterOpen(DataSet: TDataSet);
    procedure geoLocGeolocation(Sender: TObject; Lat, Lon, Alt: Double);
    procedure tmrLocateTimer(Sender: TObject);
    procedure btnSelectClick(Sender: TObject);
    procedure mapMain2MapInitialized(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure btnRefreshClick(Sender: TObject);
    procedure mapMainMapZoom(Sender: TObject; ZoomLevel: Integer);
  private
    { Private declarations }
    CurLatD, CurLatL, CurLonL, CurLonD: string;

    procedure VrniLokacije(Flt, Srt: string);
    function VrniPozicijo(LatLon: double; PremikM: integer): double;
    procedure ApplyTheme(Dark: boolean); reintroduce;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses Client.Core, Main, Client.Settings;

procedure TfrmZemljevid.ApplyTheme(Dark: boolean);
begin
  inherited;

end;

procedure TfrmZemljevid.btnRefreshClick(Sender: TObject);
begin
  tmrLocateTimer(nil);
  tmrLocate.Enabled := false;
  tmrLocate.Enabled := true;
end;

procedure TfrmZemljevid.btnSelectClick(Sender: TObject);
begin
  frmMain.ShowLokacije;
end;

procedure TfrmZemljevid.mapMain2MapInitialized(Sender: TObject);
begin
  mapMain.SetZoom(AppSettings.DefaultZoom);
  tmrLocateTimer(nil);
  tmrLocate.Enabled := true;
end;

procedure TfrmZemljevid.mapMainMapZoom(Sender: TObject; ZoomLevel: Integer);
begin
//  modMain.SetVar('Location.Zoom', ZoomLevel.ToString);
  AppSettings.DefaultZoom := ZoomLevel;
end;

procedure TfrmZemljevid.tmrLocateTimer(Sender: TObject);
begin
//  modMain.log('Refreshing geoloction...');
  geoLoc.GetGeolocation;
end;

procedure TfrmZemljevid.geoLocGeolocation(Sender: TObject; Lat, Lon, Alt: Double);
var Flt, Srt: string;
    vozilo: string;
begin
  mapMain.BeginUpdate;

  mapMain.ClearCircles;
  mapMain.AddCircle(Lat, Lon, AppSettings.LocationLength, clInfoBk,0,2,0.3);
  vozilo := 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAANZJREFUSEvtlNENAiEQRIdOtBOtRK1ErUQ70U60E71JmAs5ISMEfoz7d8cyj90dCBgcYbA+fgewAXABsGpsWbETWrgBIKQ1LOAVlWtnYvdJ0CYWSrP7egFS/gnAWT9GAJ4ADtNM74T0AkjnOImyAkLWIwC0Oe1OR15ZSe8KeGhCHnJlL0Dp/oQcIO0jy5wdkVGRTb8GtN7kasA2OkG9dOBS/twiCehNoo9pNX7TzxTIhc1fvj37aLNUjDDOIhc2fwmgxejhXbSbG7LNr3093Sw+1v8A27I3Nx01GW789uAAAAAASUVORK5CYII=';

  mapMain.ClearMarkers;
  mapMain.AddMarker(Lat, Lon, vozilo, 'To si ti');

  modMain.Log('Position: Lat='+Lat.ToString+', Lon='+Lon.ToString);

  // Vrne kote kvadrata +- 100m od točke
  CurLatL := VrniPozicijo(Lat, 0-AppSettings.LocationLength).ToString.Replace(',', '.');
  CurLatD := VrniPozicijo(Lat, AppSettings.LocationLength).ToString.Replace(',', '.');
  CurLonL := VrniPozicijo(Lon, 0-AppSettings.LocationLength).ToString.Replace(',', '.');
  CurLonD := VrniPozicijo(Lon, AppSettings.LocationLength).ToString.Replace(',', '.');

  modMain.log('CurLatL='+CurLatL);
  modMain.log('CurLatD='+CurLatD);
  modMain.log('CurLonL='+CurLonL);
  modMain.log('CurLonD='+CurLonD);

  Flt := 'lokacija/lat gt '+CurLatL+' and lokacija/lat lt '+CurLatD+' and lokacija/lon gt '+CurLonL+' and lokacija/lon lt '+CurLonD+'';
  Srt := 'lokacija/stranka/naziv,lokacija/naziv';

  VrniLokacije(Flt, Srt);

  mapMain.SetCenter(Lat, Lon);
end;

procedure TfrmZemljevid.VrniLokacije(Flt, Srt: string);
var Qry: string;
begin
  modMain.log('VrniLokacije');

  if Flt<>'' then
    Flt := Flt + ' and ';

  Flt := Flt + 'lokacija/aktivna eq 1 and pot/frakcija eq '+modMain.FrakcijaId.ToString+' and pot/vozilo/id eq '+modMain.VoziloId.ToString+'and pot/danvtednu eq '+modMain.IzbranDan.ToString;
  Qry := '$filter='+Flt+'&$orderby='+Srt+'&$expand=lokacija,lokacija/stranka,pot';

  modMain.Log('Qry='+Qry);

  wdsPotPodrobno.Close;
  wdsPotPodrobno.QueryString := Qry;
  wdsPotPodrobno.Load;
end;

procedure TfrmZemljevid.wdsPotPodrobnoAfterOpen(DataSet: TDataSet);
var
  title: string;
begin
  modMain.log('Dataset after open');
  while not wdsPotPodrobno.Eof do begin
    title := wdsPotPodrobno.FieldByName('Lokacija.Stranka.Naziv').AsString+', '+wdsPotPodrobno.FieldByName('Lokacija.Naziv').AsString;
    mapMain.AddMarker(wdsPotPodrobno.FieldByName('Lokacija.Lat').Asfloat, wdsPotPodrobno.FieldByName('Lokacija.Lon').Asfloat, Title);
    wdsPotPodrobno.Next;
  end;
  mapMain.EndUpdate;
end;

function TfrmZemljevid.VrniPozicijo(LatLon: double; PremikM: integer): double;
var earth: double;
    pi: double;
    m: double;
begin
  earth := 6378.137;  // radij zamlje v km

  asm
    pi = Math.PI;
  end;

  m := (1 / ((2 * pi / 360) * earth)) / 1000;  // 1 meter v stopinjah

  Result := LatLon + (PremikM * m);
end;

procedure TfrmZemljevid.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  tmrLocate.Enabled := false;
  inherited;
end;

procedure TfrmZemljevid.WebFormCreate(Sender: TObject);
begin
  inherited;

  mapMain.Options.DefaultLatitude := AppSettings.DefaultLat;
  mapMain.Options.DefaultLongitude:= AppSettings.DefaultLon;
  mapMain.Options.DefaultZoomLevel:= AppSettings.DefaultZoom;

  tmrLocate.Interval := 1000 * AppSettings.LocationInterval;  // v skundah
  ApplyTheme(AppSettings.DarkMode);
end;

procedure TfrmZemljevid.LoadDFMValues;
begin
  inherited LoadDFMValues;

  panFooter := TPanel.Create(Self);
  btnSelect := TButton.Create(Self);
  btnRefresh := TButton.Create(Self);
  mapMain := TGoogleMaps.Create(Self);
  wdsPotPodrobno := TXDataWebDataSet.Create(Self);
  dsPotPodrobno := TDataSource.Create(Self);
  geoLoc := TGeoLocation.Create(Self);
  tmrLocate := TTimer.Create(Self);

  panFooter.BeforeLoadDFMValues;
  btnSelect.BeforeLoadDFMValues;
  btnRefresh.BeforeLoadDFMValues;
  mapMain.BeforeLoadDFMValues;
  wdsPotPodrobno.BeforeLoadDFMValues;
  dsPotPodrobno.BeforeLoadDFMValues;
  geoLoc.BeforeLoadDFMValues;
  tmrLocate.BeforeLoadDFMValues;
  try
    Name := 'frmZemljevid';
    Left := 0;
    Top := 0;
    Align := alClient;
    Caption := 'Lokacije';
    Color := clBtnFace;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    panFooter.SetParentComponent(Self);
    panFooter.Name := 'panFooter';
    panFooter.Left := 0;
    panFooter.Top := 706;
    panFooter.Width := 831;
    panFooter.Height := 120;
    panFooter.Align := alBottom;
    panFooter.BorderStyle := bsNone;
    panFooter.ChildOrder := 3;
    panFooter.Color := clWhite;
    panFooter.Padding.Top := 5;
    panFooter.TabOrder := 0;
    btnSelect.SetParentComponent(panFooter);
    btnSelect.Name := 'btnSelect';
    btnSelect.AlignWithMargins := True;
    btnSelect.Left := 656;
    btnSelect.Top := 10;
    btnSelect.Width := 165;
    btnSelect.Height := 107;
    btnSelect.Margins.Top := 5;
    btnSelect.Margins.Right := 10;
    btnSelect.Align := alRight;
    btnSelect.Caption := '<i class="material-icons" style="font-size:40px;">done</i>';
    btnSelect.ChildOrder := 1;
    btnSelect.ElementClassName := 'btn btn-xxl btn-success';
    btnSelect.ElementFont := efCSS;
    btnSelect.HeightPercent := 100.000000000000000000;
    btnSelect.WidthPercent := 100.000000000000000000;
    SetEvent(btnSelect, Self, 'OnClick', 'btnSelectClick');
    btnRefresh.SetParentComponent(panFooter);
    btnRefresh.Name := 'btnRefresh';
    btnRefresh.AlignWithMargins := True;
    btnRefresh.Left := 10;
    btnRefresh.Top := 8;
    btnRefresh.Width := 102;
    btnRefresh.Height := 109;
    btnRefresh.Margins.Left := 10;
    btnRefresh.Margins.Right := 10;
    btnRefresh.Align := alLeft;
    btnRefresh.Caption := '<i class="material-icons">refresh</i>';
    btnRefresh.ChildOrder := 1;
    btnRefresh.ElementClassName := 'btn btn-xxl btn-primary';
    btnRefresh.ElementFont := efCSS;
    btnRefresh.HeightPercent := 100.000000000000000000;
    btnRefresh.WidthPercent := 100.000000000000000000;
    SetEvent(btnRefresh, Self, 'OnClick', 'btnRefreshClick');
    mapMain.SetParentComponent(Self);
    mapMain.Name := 'mapMain';
    mapMain.Left := 0;
    mapMain.Top := 0;
    mapMain.Width := 831;
    mapMain.Height := 706;
    mapMain.Align := alClient;
    mapMain.APIKey := 'AIzaSyAvcd34ShYOvU1IFBq4JAJ2an032gl3MFo';
    mapMain.ChildOrder := 2;
    mapMain.Options.DefaultLatitude := -34.397000000000000000;
    mapMain.Options.DefaultLongitude := 150.644000000000000000;
    SetEvent(mapMain, Self, 'OnMapLoaded', 'mapMain2MapInitialized');
    SetEvent(mapMain, Self, 'OnMapZoom', 'mapMainMapZoom');
    wdsPotPodrobno.SetParentComponent(Self);
    wdsPotPodrobno.Name := 'wdsPotPodrobno';
    wdsPotPodrobno.AfterOpen := wdsPotPodrobnoAfterOpen;
    wdsPotPodrobno.EntitySetName := 'PotPodrobno';
    wdsPotPodrobno.Connection := modMain.connServer;
    wdsPotPodrobno.SubpropsDepth := 2;
    wdsPotPodrobno.QueryString := 'filter=Aktivna%20ne%200&$orderby=Stranka/Naziv,PrevzemnoMesto&$expand=stranka';
    wdsPotPodrobno.Left := 104;
    wdsPotPodrobno.Top := 216;
    dsPotPodrobno.SetParentComponent(Self);
    dsPotPodrobno.Name := 'dsPotPodrobno';
    dsPotPodrobno.DataSet := wdsPotPodrobno;
    dsPotPodrobno.Left := 104;
    dsPotPodrobno.Top := 272;
    geoLoc.SetParentComponent(Self);
    geoLoc.Name := 'geoLoc';
    geoLoc.HighPrecision := True;
    geoLoc.TimeOut := 3000;
    SetEvent(geoLoc, Self, 'OnGeolocation', 'geoLocGeolocation');
    geoLoc.Left := 104;
    geoLoc.Top := 392;
    tmrLocate.SetParentComponent(Self);
    tmrLocate.Name := 'tmrLocate';
    tmrLocate.Enabled := False;
    tmrLocate.Interval := 30000;
    SetEvent(tmrLocate, Self, 'OnTimer', 'tmrLocateTimer');
    tmrLocate.Left := 108;
    tmrLocate.Top := 328;
  finally
    panFooter.AfterLoadDFMValues;
    btnSelect.AfterLoadDFMValues;
    btnRefresh.AfterLoadDFMValues;
    mapMain.AfterLoadDFMValues;
    wdsPotPodrobno.AfterLoadDFMValues;
    dsPotPodrobno.AfterLoadDFMValues;
    geoLoc.AfterLoadDFMValues;
    tmrLocate.AfterLoadDFMValues;
  end;
end;

end.
