unit TerminalForm;

interface

uses
  System.SysUtils, System.Classes, Vcl.Forms, JS, Web, WEBLib.Graphics, WEBLib.Controls,  WEBLib.Calendar,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  Data.DB, XData.Web.JsonDataset, XData.Web.Dataset, XData.Web.Connection,
  WEBLib.ExtCtrls, WEBLib.DB, WEBLib.DBCtrls, WEBLib.Grids;

type
  TTerminalForm = class(TForm)
    procedure FormShow(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
  protected
    FIsOnLine: boolean;

    FCurTextColor: TColor;
    FCurBckColor: TColor;
    FCurDisabledTextColor: TColor;

    FCurSelTextColor: TColor;
    FCurSelBckColor: TColor;
    procedure ApplyTheme(Dark: boolean);
    procedure ApplyGridTheme(Grid: TDBGrid);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmTerminal: TTerminalForm;

implementation

{$R *.dfm}

uses Main, Client.Core, Client.Settings;

procedure TTerminalForm.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  inherited;

  modMain.OnConnected := nil;
  Action := caFree;
end;

procedure TTerminalForm.ApplyTheme(Dark: boolean);
var I: integer;
    itm: TComponent;
begin
  FCurTextColor := frmMain.panMain.Font.Color;
  FCurBckColor := frmMain.panMain.Color;

  if Dark then begin
    self.SetColorScheme(csDark);
    FCurSelTextColor := FCurTextColor; //clSilver;
    FCurSelBckColor := $00D94600;
    FCurDisabledTextColor := clSilver;
  end else begin
    self.SetColorScheme(csLight);
    FCurSelTextColor := FCurBckColor;
    FCurSelBckColor := $00FF8F5B;
    FCurDisabledTextColor := clGray;
  end;

//  FCurSelTextColor := FCurBckColor;
  self.Color := FCurBckColor;

  for I := 0 to self.ComponentCount-1 do begin
    itm := self.Components[I];
    if itm is TEdit then begin
      if (TEdit(itm).ElementFont = efCSS) and Dark then begin
        TDBGrid(itm).ElementClassName := TDBGrid(itm).ElementClassName+' text-white';
        TDBGrid(itm).ElementClassName := TDBGrid(itm).ElementClassName+' bg-dark';
      end;
      TEdit(itm).Color := FCurBckColor;
      TEdit(itm).Font.Color := FCurTextColor;
    end;

    if itm is TDBMemo then begin
      TDBMemo(itm).Color := FCurBckColor;
      TDBMemo(itm).Font.Color := FCurTextColor;
    end;

    if itm is TPanel then begin
      TPanel(itm).Color := FCurBckColor;
    end;

    if itm is TGridPanel then begin
      TGridPanel(itm).Color := FCurBckColor;
    end;

    if itm is TLabel then begin
      if (TDBGrid(itm).ElementFont = efCSS) and Dark then begin
        TDBGrid(itm).ElementClassName := TDBGrid(itm).ElementClassName+' text-white';
      end;
      TLabel(itm).Color := FCurBckColor;
      TLabel(itm).Font.Color := FCurTextColor;
    end;

    if itm is TDBGrid then begin
      if (TDBGrid(itm).ElementFont = efCSS) and Dark then begin
        TDBGrid(itm).ElementClassName := TDBGrid(itm).ElementClassName+' text-white';
      end;

      ApplyGridTheme(TDBGrid(itm));
    end;

    if itm is TCalendar then begin
      TCalendar(itm).Color := FCurBckColor;
      TCalendar(itm).Font.Color := FCurTextColor;
    end;

  end;
end;

procedure TTerminalForm.ApplyGridTheme(Grid: TDBGrid);
begin
  Grid.Color := self.Color;
  Grid.Font.Color := FCurTextColor;

  Grid.SelectionColor := FCurSelBckColor;
  Grid.SelectionTextColor := FCurSelTextColor;
end;

procedure TTerminalForm.FormShow(Sender: TObject);
begin
  inherited;

  modMain.Log('Showing form');
end;

procedure TTerminalForm.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
    Name := 'TerminalForm';
    Width := 660;
    Height := 815;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -27;
    Font.Name := 'Segoe UI';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnShow', 'FormShow');
  finally
  end;
end;

end.
