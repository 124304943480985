unit Settings;

interface

uses
  System.SysUtils, System.Classes, Vcl.Forms, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls, TerminalForm,
  Data.DB, XData.Web.JsonDataset, XData.Web.Dataset, XData.Web.Connection,
  WEBLib.ExtCtrls, WEBLib.DB, WEBLib.DBCtrls, WEBLib.Grids, WEBLib.WebCtrls;

type
  TfrmSettings = class(TTerminalForm)
    wdsVozniki: TXDataWebDataSet;
    wdsLoginData: TXDataWebDataSet;
    wdsLoginDataVozilo: TXDataWebEntityField;
    wdsLoginDataStranka: TXDataWebEntityField;
    wdsLoginDataPodrobno: TXDataWebDatasetField;
    wdsLoginDataId: TIntegerField;
    wdsLoginDataCasOdvoza: TDateTimeField;
    wdsLoginDataPodpisStranke: TBlobField;
    wdsLoginDataOpombe: TStringField;
    dsLoginData: TDataSource;
    panFooter: TPanel;
    btnSelect: TButton;
    btnCancel: TButton;
    barLocationTimer: TTrackBar;
    lblTitle: TLabel;
    lblLocationTimer: TLabel;
    lblLocationLength: TLabel;
    barLocationLength: TTrackBar;
    chxDarkMode: TCheckBox;
    WebPanel1: TPanel;
    btnDelNote: TButton;
    procedure btnCancelClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure btnSelectClick(Sender: TObject);
    procedure barLocationTimerChange(Sender: TObject);
    procedure barLocationLengthChange(Sender: TObject);
    procedure btnDelNoteClick(Sender: TObject);
  private
    { Private declarations }
    procedure ApplyTheme(Dark: boolean); reintroduce;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmSettings: TfrmSettings;

implementation

{$R *.dfm}

uses Main, Client.Core, Client.Settings;

procedure TfrmSettings.ApplyTheme(Dark: boolean);
begin
  inherited;

end;

procedure TfrmSettings.barLocationLengthChange(Sender: TObject);
begin
  lblLocationLength.Caption := 'Lokacja osveževanje ('+barLocationLength.Position.ToString+' metrov)';
end;

procedure TfrmSettings.barLocationTimerChange(Sender: TObject);
begin
  lblLocationTimer.Caption := 'Lokacja osveževanje ('+barLocationTimer.Position.ToString+' sekund)';
end;

procedure TfrmSettings.btnCancelClick(Sender: TObject);
begin
//  frmMain.ShowPrevious;
  frmMain.ShowLokacije;
end;

procedure TfrmSettings.btnDelNoteClick(Sender: TObject);
begin
  Application.Navigate('https://kamijoncin.okoljepiran.si/install', ntPage);
end;

procedure TfrmSettings.btnSelectClick(Sender: TObject);
begin
  AppSettings.DarkMode := chxDarkMode.Checked;
  AppSettings.LocationInterval := barLocationTimer.Position;
  AppSettings.LocationLength := barLocationLength.Position;
  AppSettings.DarkMode := chxDarkMode.Checked;

  modMain.ZazeniSledenje;
  frmMain.ApplyTheme(AppSettings.DarkMode);

  frmMain.ShowLokacije;
end;

procedure TfrmSettings.WebFormCreate(Sender: TObject);
begin
  inherited;

  barLocationTimer.Position := AppSettings.LocationInterval;
  barLocationLength.Position := AppSettings.LocationLength;
  barLocationLengthChange(nil);
  barLocationTimerChange(nil);
  chxDarkMode.Checked := AppSettings.DarkMode;

  ApplyTheme(AppSettings.DarkMode);
end;

procedure TfrmSettings.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lblTitle := TLabel.Create('lblTitle');
  lblLocationTimer := TLabel.Create(Self);
  lblLocationLength := TLabel.Create(Self);
  panFooter := TPanel.Create(Self);
  btnSelect := TButton.Create(Self);
  btnCancel := TButton.Create(Self);
  barLocationTimer := TTrackBar.Create(Self);
  barLocationLength := TTrackBar.Create(Self);
  chxDarkMode := TCheckBox.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  btnDelNote := TButton.Create(Self);
  wdsVozniki := TXDataWebDataSet.Create(Self);
  wdsLoginData := TXDataWebDataSet.Create(Self);
  wdsLoginDataVozilo := TXDataWebEntityField.Create(Self);
  wdsLoginDataStranka := TXDataWebEntityField.Create(Self);
  wdsLoginDataPodrobno := TXDataWebDatasetField.Create(Self);
  wdsLoginDataId := TIntegerField.Create(Self);
  wdsLoginDataCasOdvoza := TDateTimeField.Create(Self);
  wdsLoginDataPodpisStranke := TBlobField.Create(Self);
  wdsLoginDataOpombe := TStringField.Create(Self);
  dsLoginData := TDataSource.Create(Self);

  lblTitle.BeforeLoadDFMValues;
  lblLocationTimer.BeforeLoadDFMValues;
  lblLocationLength.BeforeLoadDFMValues;
  panFooter.BeforeLoadDFMValues;
  btnSelect.BeforeLoadDFMValues;
  btnCancel.BeforeLoadDFMValues;
  barLocationTimer.BeforeLoadDFMValues;
  barLocationLength.BeforeLoadDFMValues;
  chxDarkMode.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  btnDelNote.BeforeLoadDFMValues;
  wdsVozniki.BeforeLoadDFMValues;
  wdsLoginData.BeforeLoadDFMValues;
  wdsLoginDataVozilo.BeforeLoadDFMValues;
  wdsLoginDataStranka.BeforeLoadDFMValues;
  wdsLoginDataPodrobno.BeforeLoadDFMValues;
  wdsLoginDataId.BeforeLoadDFMValues;
  wdsLoginDataCasOdvoza.BeforeLoadDFMValues;
  wdsLoginDataPodpisStranke.BeforeLoadDFMValues;
  wdsLoginDataOpombe.BeforeLoadDFMValues;
  dsLoginData.BeforeLoadDFMValues;
  try
    Name := 'frmSettings';
    Left := 0;
    Top := 0;
    Caption := 'Nastavitve';
    Color := clBtnFace;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -27;
    Font.Name := 'Segoe UI';
    Font.Style := [];
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lblTitle.SetParentComponent(Self);
    lblTitle.Name := 'lblTitle';
    lblTitle.Left := 10;
    lblTitle.Top := 0;
    lblTitle.Width := 624;
    lblTitle.Height := 88;
    lblTitle.Margins.Left := 10;
    lblTitle.Margins.Right := 10;
    lblTitle.Align := alTop;
    lblTitle.Alignment := taCenter;
    lblTitle.AutoSize := False;
    lblTitle.Caption := 'Nastavitve';
    lblTitle.Color := clBtnFace;
    lblTitle.ElementClassName := 'display-1 text-danger';
    lblTitle.ElementFont := efCSS;
    lblTitle.HeightPercent := 100.000000000000000000;
    lblTitle.WidthPercent := 100.000000000000000000;
    lblLocationTimer.SetParentComponent(Self);
    lblLocationTimer.Name := 'lblLocationTimer';
    lblLocationTimer.Left := 10;
    lblLocationTimer.Top := 88;
    lblLocationTimer.Width := 624;
    lblLocationTimer.Height := 37;
    lblLocationTimer.Margins.Left := 10;
    lblLocationTimer.Margins.Right := 10;
    lblLocationTimer.Align := alTop;
    lblLocationTimer.Caption := 'Lokacija osve'#382'evanje (sekunde)';
    lblLocationTimer.Color := clBtnFace;
    lblLocationTimer.ElementClassName := 'display-4';
    lblLocationTimer.ElementFont := efCSS;
    lblLocationTimer.HeightStyle := ssAuto;
    lblLocationTimer.HeightPercent := 100.000000000000000000;
    lblLocationTimer.WidthPercent := 100.000000000000000000;
    lblLocationLength.SetParentComponent(Self);
    lblLocationLength.Name := 'lblLocationLength';
    lblLocationLength.Left := 10;
    lblLocationLength.Top := 145;
    lblLocationLength.Width := 624;
    lblLocationLength.Height := 37;
    lblLocationLength.Margins.Left := 10;
    lblLocationLength.Margins.Right := 10;
    lblLocationLength.Align := alTop;
    lblLocationLength.Caption := 'Lokacija osve'#382'evanje (metri)';
    lblLocationLength.Color := clBtnFace;
    lblLocationLength.ElementClassName := 'display-4';
    lblLocationLength.ElementFont := efCSS;
    lblLocationLength.HeightStyle := ssAuto;
    lblLocationLength.HeightPercent := 100.000000000000000000;
    lblLocationLength.WidthPercent := 100.000000000000000000;
    panFooter.SetParentComponent(Self);
    panFooter.Name := 'panFooter';
    panFooter.Left := 10;
    panFooter.Top := 656;
    panFooter.Width := 624;
    panFooter.Height := 120;
    panFooter.ElementClassName := 'card';
    panFooter.Align := alBottom;
    panFooter.BorderStyle := bsNone;
    panFooter.ChildOrder := 3;
    panFooter.Color := clWhite;
    panFooter.ElementBodyClassName := 'card-body';
    panFooter.ElementFont := efCSS;
    panFooter.Padding.Top := 5;
    panFooter.TabOrder := 0;
    btnSelect.SetParentComponent(panFooter);
    btnSelect.Name := 'btnSelect';
    btnSelect.AlignWithMargins := True;
    btnSelect.Left := 449;
    btnSelect.Top := 10;
    btnSelect.Width := 165;
    btnSelect.Height := 107;
    btnSelect.Margins.Top := 5;
    btnSelect.Margins.Right := 10;
    btnSelect.Align := alRight;
    btnSelect.Caption := '<i class="material-icons" style="font-size:40px;">done</i>';
    btnSelect.ChildOrder := 1;
    btnSelect.ElementClassName := 'btn btn-success';
    btnSelect.ElementFont := efCSS;
    btnSelect.HeightPercent := 100.000000000000000000;
    btnSelect.WidthPercent := 100.000000000000000000;
    SetEvent(btnSelect, Self, 'OnClick', 'btnSelectClick');
    btnCancel.SetParentComponent(panFooter);
    btnCancel.Name := 'btnCancel';
    btnCancel.AlignWithMargins := True;
    btnCancel.Left := 10;
    btnCancel.Top := 8;
    btnCancel.Width := 111;
    btnCancel.Height := 109;
    btnCancel.Margins.Left := 10;
    btnCancel.Margins.Right := 10;
    btnCancel.Align := alLeft;
    btnCancel.Caption := '<i class="material-icons" style="font-size:40px;">close</i>';
    btnCancel.ChildOrder := 1;
    btnCancel.ElementClassName := 'btn btn-danger';
    btnCancel.ElementFont := efCSS;
    btnCancel.HeightPercent := 100.000000000000000000;
    btnCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCancel, Self, 'OnClick', 'btnCancelClick');
    barLocationTimer.SetParentComponent(Self);
    barLocationTimer.Name := 'barLocationTimer';
    barLocationTimer.Left := 10;
    barLocationTimer.Top := 125;
    barLocationTimer.Width := 624;
    barLocationTimer.Height := 20;
    barLocationTimer.ElementClassName := 'custom-range display-3';
    barLocationTimer.Align := alTop;
    barLocationTimer.ChildOrder := 2;
    barLocationTimer.ElementFont := efCSS;
    barLocationTimer.Max := 60;
    barLocationTimer.Min := 10;
    barLocationTimer.Position := 0;
    barLocationTimer.Role := '';
    SetEvent(barLocationTimer, Self, 'OnChange', 'barLocationTimerChange');
    barLocationLength.SetParentComponent(Self);
    barLocationLength.Name := 'barLocationLength';
    barLocationLength.Left := 10;
    barLocationLength.Top := 182;
    barLocationLength.Width := 624;
    barLocationLength.Height := 20;
    barLocationLength.ElementClassName := 'custom-range display-3';
    barLocationLength.Align := alTop;
    barLocationLength.ChildOrder := 2;
    barLocationLength.ElementFont := efCSS;
    barLocationLength.Max := 900;
    barLocationLength.Min := 50;
    barLocationLength.Position := 0;
    barLocationLength.Role := '';
    SetEvent(barLocationLength, Self, 'OnChange', 'barLocationLengthChange');
    chxDarkMode.SetParentComponent(Self);
    chxDarkMode.Name := 'chxDarkMode';
    chxDarkMode.Left := 10;
    chxDarkMode.Top := 202;
    chxDarkMode.Width := 624;
    chxDarkMode.Height := 57;
    chxDarkMode.Align := alTop;
    chxDarkMode.Caption := 'Temni na'#269'in';
    chxDarkMode.ChildOrder := 7;
    chxDarkMode.ElementClassName := 'checkbox display-4';
    chxDarkMode.ElementButtonClassName := 'form-check-input display-5';
    chxDarkMode.ElementLabelClassName := ' display-4';
    chxDarkMode.ElementFont := efCSS;
    chxDarkMode.HeightStyle := ssAuto;
    chxDarkMode.HeightPercent := 100.000000000000000000;
    chxDarkMode.WidthPercent := 100.000000000000000000;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 10;
    WebPanel1.Top := 259;
    WebPanel1.Width := 624;
    WebPanel1.Height := 110;
    WebPanel1.ElementClassName := 'card';
    WebPanel1.Align := alTop;
    WebPanel1.BorderStyle := bsNone;
    WebPanel1.ChildOrder := 3;
    WebPanel1.Color := clWhite;
    WebPanel1.ElementBodyClassName := 'card-body';
    WebPanel1.ElementFont := efCSS;
    WebPanel1.Padding.Top := 5;
    WebPanel1.Padding.Bottom := 5;
    WebPanel1.TabOrder := 4;
    btnDelNote.SetParentComponent(WebPanel1);
    btnDelNote.Name := 'btnDelNote';
    btnDelNote.AlignWithMargins := True;
    btnDelNote.Left := 3;
    btnDelNote.Top := 8;
    btnDelNote.Width := 209;
    btnDelNote.Height := 94;
    btnDelNote.Margins.Right := 10;
    btnDelNote.Align := alLeft;
    btnDelNote.Caption := 'Prenesi Fully';
    btnDelNote.ChildOrder := 2;
    btnDelNote.ElementClassName := 'btn btn-xxl btn-primary';
    btnDelNote.ElementFont := efCSS;
    btnDelNote.HeightStyle := ssAuto;
    btnDelNote.HeightPercent := 100.000000000000000000;
    btnDelNote.WidthPercent := 100.000000000000000000;
    SetEvent(btnDelNote, Self, 'OnClick', 'btnDelNoteClick');
    wdsVozniki.SetParentComponent(Self);
    wdsVozniki.Name := 'wdsVozniki';
    wdsVozniki.EntitySetName := 'Uporabnik';
    wdsVozniki.Connection := modMain.connServer;
    wdsVozniki.QueryString := '$filter=PIN eq '#39'123'#39;
    wdsVozniki.QueryTop := 1;
    wdsVozniki.Left := 272;
    wdsVozniki.Top := 16;
    wdsLoginData.SetParentComponent(Self);
    wdsLoginData.Name := 'wdsLoginData';
    wdsLoginData.EntitySetName := 'Odvoz';
    wdsLoginData.Connection := modMain.connServer;
    wdsLoginData.SubpropsDepth := 1;
    wdsLoginData.QueryString := '$filter=ID eq -1';
    wdsLoginData.QueryTop := 1;
    wdsLoginData.Left := 176;
    wdsLoginData.Top := 16;
    wdsLoginDataVozilo.SetParentComponent(wdsLoginData);
    wdsLoginDataVozilo.Name := 'wdsLoginDataVozilo';
    wdsLoginDataVozilo.FieldName := 'Vozilo';
    wdsLoginDataVozilo.Required := True;
    wdsLoginDataStranka.SetParentComponent(wdsLoginData);
    wdsLoginDataStranka.Name := 'wdsLoginDataStranka';
    wdsLoginDataStranka.FieldName := 'Stranka';
    wdsLoginDataStranka.Required := True;
    wdsLoginDataPodrobno.SetParentComponent(wdsLoginData);
    wdsLoginDataPodrobno.Name := 'wdsLoginDataPodrobno';
    wdsLoginDataPodrobno.FieldName := 'Podrobno';
    wdsLoginDataPodrobno.ReadOnly := True;
    wdsLoginDataId.SetParentComponent(wdsLoginData);
    wdsLoginDataId.Name := 'wdsLoginDataId';
    wdsLoginDataId.FieldName := 'Id';
    wdsLoginDataId.Required := True;
    wdsLoginDataCasOdvoza.SetParentComponent(wdsLoginData);
    wdsLoginDataCasOdvoza.Name := 'wdsLoginDataCasOdvoza';
    wdsLoginDataCasOdvoza.FieldName := 'CasOdvoza';
    wdsLoginDataPodpisStranke.SetParentComponent(wdsLoginData);
    wdsLoginDataPodpisStranke.Name := 'wdsLoginDataPodpisStranke';
    wdsLoginDataPodpisStranke.FieldName := 'PodpisStranke';
    wdsLoginDataOpombe.SetParentComponent(wdsLoginData);
    wdsLoginDataOpombe.Name := 'wdsLoginDataOpombe';
    wdsLoginDataOpombe.FieldName := 'Opombe';
    wdsLoginDataOpombe.Size := 50;
    dsLoginData.SetParentComponent(Self);
    dsLoginData.Name := 'dsLoginData';
    dsLoginData.DataSet := wdsLoginData;
    dsLoginData.Left := 176;
    dsLoginData.Top := 72;
  finally
    lblTitle.AfterLoadDFMValues;
    lblLocationTimer.AfterLoadDFMValues;
    lblLocationLength.AfterLoadDFMValues;
    panFooter.AfterLoadDFMValues;
    btnSelect.AfterLoadDFMValues;
    btnCancel.AfterLoadDFMValues;
    barLocationTimer.AfterLoadDFMValues;
    barLocationLength.AfterLoadDFMValues;
    chxDarkMode.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    btnDelNote.AfterLoadDFMValues;
    wdsVozniki.AfterLoadDFMValues;
    wdsLoginData.AfterLoadDFMValues;
    wdsLoginDataVozilo.AfterLoadDFMValues;
    wdsLoginDataStranka.AfterLoadDFMValues;
    wdsLoginDataPodrobno.AfterLoadDFMValues;
    wdsLoginDataId.AfterLoadDFMValues;
    wdsLoginDataCasOdvoza.AfterLoadDFMValues;
    wdsLoginDataPodpisStranke.AfterLoadDFMValues;
    wdsLoginDataOpombe.AfterLoadDFMValues;
    dsLoginData.AfterLoadDFMValues;
  end;
end;

end.
