unit Client.Constants;

interface

uses System.SysUtils;

type
  TKoledarNacin = (knPrivzeto, knZgodovina);

  // Eanko kot v Common.Constants, samo da se začne z 0, ker Pas2JS ne podpira začetek z 1 ...
  TNalogTip = (ntRedni, ntIzredni, ntAkcija, ntOdvoz, ntPremik);
  TNalogStatus = (nsVpisan, nsVObdelavi, nsZakljucen);
  TKontejnerStatus = (ksVpisan, ksPriStranki, ksNalozen, ksStehtan, ksZakljucen);
  TVoziloTip = (tvNorba, tvSamonakladac, tvZDvigalom, tvJolly);
  TPranjeTip = (ptBrez, ptVoda, ptCistila);
  TNamen = (kmPrijava, kmOdjava, kmGorivo);

var
  Fmt, FmtDisp, FmtDispDT: TFormatSettings;

implementation

initialization

  Fmt := TFormatSettings.Create('en-us');
  Fmt.ShortDateFormat := 'yyyy-mm-dd';

  FmtDisp := TFormatSettings.Create('sl-SI');
  FmtDisp.ShortDateFormat := 'dd.mm.yyyy';

  FmtDispDT := TFormatSettings.Create('sl-SI');
  FmtDispDT.ShortDateFormat := 'dd.mm.yyyy hh:nn';

end.
