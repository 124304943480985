program Terminal;

{$R *.dres}

uses
  Vcl.Forms,
  WEBLib.Forms,
  Auth.Service in 'Auth.Service.pas',
  Client.Settings in 'Client.Settings.pas' {*.html},
  Client.Core in 'Client.Core.pas',
  Main in 'Main.pas'{*.html},
  Login in 'Frames\Login.pas'{*.html},
  Lokacija in 'Frames\Lokacija.pas'{*.html},
  Vozilo in 'Frames\Vozilo.pas'{*.html},
  VoziloMenjava in 'Frames\VoziloMenjava.pas'{*.html},
  Datum in 'Frames\Datum.pas'{*.html},
  Settings in 'Frames\Settings.pas'{*.html},
  Zemljevid in 'Frames\Zemljevid.pas'{*.html},
  Zgodovina in 'Frames\Zgodovina.pas'{*.html},
  Frakcija in 'Frames\Frakcija.pas'{*.html},
  Odvoz in 'Frames\Odvoz.pas'{*.html},
  OdvozInfo in 'Frames\OdvozInfo.pas'{*.html},
  TerminalForm in '..\Terminal.Common\Templates\TerminalForm.pas',
  Client.Constants in '..\Terminal.Common\Client.Constants.pas',
  Kilometri in 'Frames\Kilometri.pas'{*.html},
  Gorivo in 'Frames\Gorivo.pas'{*.html},
  Potrditev in 'Frames\Potrditev.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateFormDirect(TmodMain, modMain);
  Application.CreateForm(TfrmMain, frmMain);
  Application.Run;
end.
