unit Gorivo;

interface

uses
  System.SysUtils, System.Classes, Vcl.Forms, JS, Web, WEBLib.Graphics, WEBLib.Controls, WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, TerminalForm,
  Vcl.Controls, Data.DB, XData.Web.JsonDataset, XData.Web.Dataset, XData.Web.Connection, WEBLib.ExtCtrls, WEBLib.DB, WEBLib.DBCtrls, WEBLib.Grids, Client.Constants;

type
  TfrmGorivo = class(TTerminalForm)
    panInput: TPanel;
    panStevilke: TGridPanel;
    btn1: TButton;
    btn2: TButton;
    btn3: TButton;
    btn4: TButton;
    btn5: TButton;
    btn6: TButton;
    btn7: TButton;
    btn8: TButton;
    btn9: TButton;
    WebLabel1: TLabel;
    btn0: TButton;
    btnComma: TButton;
    lblError: TLabel;
    panFooter: TPanel;
    btnOk: TButton;
    btnCancel: TButton;
    lblL: TLabel;
    tmrStart: TTimer;
    btnMenjava: TButton;
    procedure btn1Click(Sender: TObject);
    procedure btnOkClick(Sender: TObject);
    procedure btnDeleteClick(Sender: TObject);
    procedure connServerConnect(Sender: TObject);
    procedure FormShow(Sender: TObject);
    procedure btnCancelClick(Sender: TObject);
    procedure tmrStartTimer(Sender: TObject);
  private
    { Private declarations }
    FLitri: string;
    function GetLitri: double;
    property Litri: double read GetLitri;
    procedure ToggleOk(Enabled: boolean);
    procedure ShowL;
    function DisplayL(Litri: double): string;
    procedure ApplyTheme(Dark: boolean); reintroduce;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmGorivo: TfrmGorivo;

implementation

{$R *.dfm}

uses Main, Client.Core, Client.Settings, Auth.Service;

procedure TfrmGorivo.btnOkClick(Sender: TObject);
begin
  if self.Litri > 500 then begin  // Več kot 500 L goriva.. sumljivo
   lblError.Caption := 'Vnos preveč odstopa - preveri!';
   lblError.Show;
   FLitri := '';
   ShowL;

   exit;
  end;

  modMain.VpisiGorivo(modMain.VoziloId, self.Litri, 'Ročni vnos goriva: '+FLitri+' L');
  frmMain.ShowLokacije;
end;

procedure TfrmGorivo.btnDeleteClick(Sender: TObject);
begin
  FLitri := '';
  ShowL;
end;

procedure TfrmGorivo.ApplyTheme(Dark: boolean);
begin
  inherited;

  panInput.Color := self.Color;
  panStevilke.Color := panInput.Color;
  panFooter.Color := panInput.Color;

  btnComma.Visible := true;
end;

procedure TfrmGorivo.btn1Click(Sender: TObject);
begin
//  modMain.Log('Tipka: '+TWebButton(Sender).Caption);

  if Sender <> btnComma then begin
    FLitri := FLitri + TButton(Sender).Tag.ToString;
  end else begin
    if FLitri='' then  // Še ni nič vpisano, ne moreš dati vejice
      exit;

    if not FLitri.Contains(',') then   // Samo enkrat ;)
      FLitri := FLitri + ',';
  end;

//  btnComma.Enabled := not FLitri.Contains(',');

//  modMain.Log('FLitri: '+FLitri);
  ShowL;
end;

procedure TfrmGorivo.btnCancelClick(Sender: TObject);
begin
  frmMain.ShowLokacije;
end;

procedure TfrmGorivo.connServerConnect(Sender: TObject);
begin
  ToggleOk(true);
end;

function TfrmGorivo.DisplayL(Litri: double): string;
var
  I, cifer: Integer;
  rez: string;
begin
  rez := Litri.ToString.Replace('.', ',');

  //modMain.Log('DisplayL: rez='+rez);

  // dodati pike na vsake 3 decimalke
  cifer := 0;

  for I := Length(rez) downto 1 do begin
    if (cifer>0) and (cifer mod 3 = 0) and (rez[I]<>',') and (I>0) then begin     // ne sme biti najprej pika...
      Result := '.'+Result;
    end;
    Result := rez[I]+Result;

    if rez[I]=',' then
      cifer := 0
    else
      inc(cifer);
  end;

  Result := Result +' L';
end;

procedure TfrmGorivo.FormShow(Sender: TObject);
begin
  inherited;

  panInput.Width := Round(self.Width  * 0.8);
  panInput.Left := self.Width div 2 - panInput.Width div 2;
  panInput.Top := self.Height div 2 - panInput.Height div 2;

  if panInput.Top + panInput.Height > self.Height then
    panInput.Top := 0;

  tmrStart.Enabled := true;
  lblError.Show;

  ApplyTheme(AppSettings.DarkMode);
end;

function TfrmGorivo.GetLitri: double;
begin
  Result := FLitri.Replace(',', '.').ToDouble;
end;

procedure TfrmGorivo.ShowL;
begin
  lblL.Caption := '';

  if FLitri<>'' then
    lblL.Caption := DisplayL(self.Litri);

  ToggleOk(FLitri <> '');
end;

procedure TfrmGorivo.tmrStartTimer(Sender: TObject);
begin
  tmrStart.Enabled := false;
  ShowL;
end;

procedure TfrmGorivo.ToggleOk(Enabled: boolean);
begin
  btnOk.Enabled := (FLitri<>'') and Enabled; //  (Enabled and modMain.connServer.Connected) or AppSettings.DevMode;
  btnComma.Enabled := (FLitri<>'') and not (FLitri.Contains(','));

  if Enabled then begin
    lblError.Hide;
  end;
end;

procedure TfrmGorivo.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lblError := TLabel.Create(Self);
  lblL := TLabel.Create(Self);
  panInput := TPanel.Create(Self);
  panStevilke := TGridPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  btn1 := TButton.Create(Self);
  btn2 := TButton.Create(Self);
  btn3 := TButton.Create(Self);
  btn4 := TButton.Create(Self);
  btn5 := TButton.Create(Self);
  btn6 := TButton.Create(Self);
  btn7 := TButton.Create(Self);
  btn8 := TButton.Create(Self);
  btn9 := TButton.Create(Self);
  btn0 := TButton.Create(Self);
  btnComma := TButton.Create(Self);
  btnMenjava := TButton.Create(Self);
  panFooter := TPanel.Create(Self);
  btnOk := TButton.Create(Self);
  btnCancel := TButton.Create(Self);
  tmrStart := TTimer.Create(Self);

  lblError.BeforeLoadDFMValues;
  lblL.BeforeLoadDFMValues;
  panInput.BeforeLoadDFMValues;
  panStevilke.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  btn1.BeforeLoadDFMValues;
  btn2.BeforeLoadDFMValues;
  btn3.BeforeLoadDFMValues;
  btn4.BeforeLoadDFMValues;
  btn5.BeforeLoadDFMValues;
  btn6.BeforeLoadDFMValues;
  btn7.BeforeLoadDFMValues;
  btn8.BeforeLoadDFMValues;
  btn9.BeforeLoadDFMValues;
  btn0.BeforeLoadDFMValues;
  btnComma.BeforeLoadDFMValues;
  btnMenjava.BeforeLoadDFMValues;
  panFooter.BeforeLoadDFMValues;
  btnOk.BeforeLoadDFMValues;
  btnCancel.BeforeLoadDFMValues;
  tmrStart.BeforeLoadDFMValues;
  try
    Name := 'frmGorivo';
    Left := 0;
    Top := 0;
    Color := clBtnFace;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -27;
    Font.Name := 'Segoe UI';
    Font.Style := [];
    SetEvent(Self, 'OnShow', 'FormShow');
    lblError.SetParentComponent(Self);
    lblError.Name := 'lblError';
    lblError.Left := 0;
    lblError.Top := 0;
    lblError.Width := 644;
    lblError.Height := 37;
    lblError.Align := alTop;
    lblError.Alignment := taCenter;
    lblError.Caption := 'Vpi'#353'i litre goriva';
    lblError.Color := clBtnFace;
    lblError.ElementClassName := 'display-3 text-danger';
    lblError.ElementFont := efCSS;
    lblError.Font.Charset := DEFAULT_CHARSET;
    lblError.Font.Color := clRed;
    lblError.Font.Height := -27;
    lblError.Font.Name := 'Segoe UI';
    lblError.Font.Style := [];
    lblError.HeightStyle := ssAuto;
    lblError.HeightPercent := 100.000000000000000000;
    lblError.Layout := tlCenter;
    lblError.ParentFont := False;
    lblError.WidthPercent := 100.000000000000000000;
    lblL.SetParentComponent(Self);
    lblL.Name := 'lblL';
    lblL.Left := 0;
    lblL.Top := 37;
    lblL.Width := 644;
    lblL.Height := 81;
    lblL.Align := alTop;
    lblL.Alignment := taCenter;
    lblL.AutoSize := False;
    lblL.Color := clBtnFace;
    lblL.Font.Charset := DEFAULT_CHARSET;
    lblL.Font.Color := clWindowText;
    lblL.Font.Height := -83;
    lblL.Font.Name := 'Segoe UI';
    lblL.Font.Style := [];
    lblL.HeightStyle := ssAuto;
    lblL.HeightPercent := 100.000000000000000000;
    lblL.Layout := tlCenter;
    lblL.ParentFont := False;
    lblL.Transparent := False;
    lblL.WidthPercent := 100.000000000000000000;
    panInput.SetParentComponent(Self);
    panInput.Name := 'panInput';
    panInput.Left := 16;
    panInput.Top := 224;
    panInput.Width := 625;
    panInput.Height := 457;
    panInput.BorderColor := clWhite;
    panInput.BorderStyle := bsNone;
    panInput.ChildOrder := 1;
    panInput.Padding.Left := 10;
    panInput.Padding.Top := 10;
    panInput.Padding.Right := 10;
    panInput.Padding.Bottom := 10;
    panInput.TabOrder := 0;
    panStevilke.SetParentComponent(panInput);
    panStevilke.Name := 'panStevilke';
    panStevilke.Left := 10;
    panStevilke.Top := 10;
    panStevilke.Width := 605;
    panStevilke.Height := 437;
    panStevilke.Margins.Left := 10;
    panStevilke.Margins.Top := 10;
    panStevilke.Margins.Right := 10;
    panStevilke.Margins.Bottom := 10;
    panStevilke.Align := alClient;
    panStevilke.ChildOrder := 14;
    panStevilke.ColumnCollection.Clear;
    with panStevilke.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      MarginLeft := 10;
      MarginRight := 10;
      Value := 33;
    end;
    with panStevilke.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      MarginLeft := 10;
      MarginRight := 10;
      Value := 33;
    end;
    with panStevilke.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      MarginLeft := 10;
      MarginRight := 10;
      Value := 33;
    end;
    panStevilke.ControlCollection.Clear;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btn1;
    end;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btn2;
    end;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btn3;
    end;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btn4;
    end;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btn5;
    end;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btn6;
    end;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btn7;
    end;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btn8;
    end;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btn9;
    end;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btnComma;
    end;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btn0;
    end;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btnMenjava;
    end;
    panStevilke.Color := clWhite;
    panStevilke.GridLineColor := clBlack;
    panStevilke.RowCollection.Clear;
    with panStevilke.RowCollection.Add do
    begin
      Alignment := vaCenter;
      MarginTop := 10;
      MarginBottom := 10;
      Value := 25;
    end;
    with panStevilke.RowCollection.Add do
    begin
      Alignment := vaCenter;
      MarginTop := 10;
      MarginBottom := 10;
      Value := 25;
    end;
    with panStevilke.RowCollection.Add do
    begin
      Alignment := vaCenter;
      MarginTop := 10;
      MarginBottom := 10;
      Value := 25;
    end;
    with panStevilke.RowCollection.Add do
    begin
      Alignment := vaCenter;
      MarginTop := 10;
      MarginBottom := 10;
      Value := 25;
    end;
    WebLabel1.SetParentComponent(panStevilke);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 75;
    WebLabel1.Top := 363;
    WebLabel1.Width := 49;
    WebLabel1.Height := 37;
    WebLabel1.Anchors := [];
    WebLabel1.Caption := '';
    WebLabel1.ElementFont := efCSS;
    WebLabel1.HeightStyle := ssAuto;
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    btn1.SetParentComponent(panStevilke);
    btn1.Name := 'btn1';
    btn1.Tag := 1;
    btn1.Left := 12;
    btn1.Top := 12;
    btn1.Width := 176;
    btn1.Height := 85;
    btn1.Anchors := [];
    btn1.Caption := '1';
    btn1.ChildOrder := 1;
    btn1.ElementClassName := 'btn btn-info fs-1';
    btn1.ElementFont := efCSS;
    btn1.Font.Charset := DEFAULT_CHARSET;
    btn1.Font.Color := clWindowText;
    btn1.Font.Height := -120;
    btn1.Font.Name := 'Arial';
    btn1.Font.Style := [];
    btn1.HeightStyle := ssPercent;
    btn1.HeightPercent := 100.000000000000000000;
    btn1.ParentFont := False;
    btn1.WidthStyle := ssPercent;
    btn1.WidthPercent := 100.000000000000000000;
    SetEvent(btn1, Self, 'OnClick', 'btn1Click');
    btn2.SetParentComponent(panStevilke);
    btn2.Name := 'btn2';
    btn2.Tag := 2;
    btn2.Left := 212;
    btn2.Top := 12;
    btn2.Width := 176;
    btn2.Height := 85;
    btn2.Anchors := [];
    btn2.Caption := '2';
    btn2.ChildOrder := 1;
    btn2.ElementClassName := 'btn btn-info fs-1';
    btn2.ElementFont := efCSS;
    btn2.Font.Charset := DEFAULT_CHARSET;
    btn2.Font.Color := clWindowText;
    btn2.Font.Height := -120;
    btn2.Font.Name := 'Arial';
    btn2.Font.Style := [];
    btn2.HeightStyle := ssPercent;
    btn2.HeightPercent := 100.000000000000000000;
    btn2.ParentFont := False;
    btn2.WidthStyle := ssPercent;
    btn2.WidthPercent := 100.000000000000000000;
    SetEvent(btn2, Self, 'OnClick', 'btn1Click');
    btn3.SetParentComponent(panStevilke);
    btn3.Name := 'btn3';
    btn3.Tag := 3;
    btn3.Left := 412;
    btn3.Top := 12;
    btn3.Width := 176;
    btn3.Height := 85;
    btn3.Anchors := [];
    btn3.Caption := '3';
    btn3.ChildOrder := 1;
    btn3.ElementClassName := 'btn btn-info fs-1';
    btn3.ElementFont := efCSS;
    btn3.Font.Charset := DEFAULT_CHARSET;
    btn3.Font.Color := clWindowText;
    btn3.Font.Height := -120;
    btn3.Font.Name := 'Arial';
    btn3.Font.Style := [];
    btn3.HeightStyle := ssPercent;
    btn3.HeightPercent := 100.000000000000000000;
    btn3.ParentFont := False;
    btn3.WidthStyle := ssPercent;
    btn3.WidthPercent := 100.000000000000000000;
    SetEvent(btn3, Self, 'OnClick', 'btn1Click');
    btn4.SetParentComponent(panStevilke);
    btn4.Name := 'btn4';
    btn4.Tag := 4;
    btn4.Left := 12;
    btn4.Top := 121;
    btn4.Width := 176;
    btn4.Height := 85;
    btn4.Anchors := [];
    btn4.Caption := '4';
    btn4.ChildOrder := 1;
    btn4.ElementClassName := 'btn btn-info fs-1';
    btn4.ElementFont := efCSS;
    btn4.Font.Charset := DEFAULT_CHARSET;
    btn4.Font.Color := clWindowText;
    btn4.Font.Height := -120;
    btn4.Font.Name := 'Arial';
    btn4.Font.Style := [];
    btn4.HeightStyle := ssPercent;
    btn4.HeightPercent := 100.000000000000000000;
    btn4.ParentFont := False;
    btn4.WidthStyle := ssPercent;
    btn4.WidthPercent := 100.000000000000000000;
    SetEvent(btn4, Self, 'OnClick', 'btn1Click');
    btn5.SetParentComponent(panStevilke);
    btn5.Name := 'btn5';
    btn5.Tag := 5;
    btn5.Left := 212;
    btn5.Top := 121;
    btn5.Width := 176;
    btn5.Height := 85;
    btn5.Anchors := [];
    btn5.Caption := '5';
    btn5.ChildOrder := 1;
    btn5.ElementClassName := 'btn btn-info fs-1';
    btn5.ElementFont := efCSS;
    btn5.Font.Charset := DEFAULT_CHARSET;
    btn5.Font.Color := clWindowText;
    btn5.Font.Height := -120;
    btn5.Font.Name := 'Arial';
    btn5.Font.Style := [];
    btn5.HeightStyle := ssPercent;
    btn5.HeightPercent := 100.000000000000000000;
    btn5.ParentFont := False;
    btn5.WidthStyle := ssPercent;
    btn5.WidthPercent := 100.000000000000000000;
    SetEvent(btn5, Self, 'OnClick', 'btn1Click');
    btn6.SetParentComponent(panStevilke);
    btn6.Name := 'btn6';
    btn6.Tag := 6;
    btn6.Left := 412;
    btn6.Top := 121;
    btn6.Width := 176;
    btn6.Height := 85;
    btn6.Anchors := [];
    btn6.Caption := '6';
    btn6.ChildOrder := 1;
    btn6.ElementClassName := 'btn btn-info fs-1';
    btn6.ElementFont := efCSS;
    btn6.Font.Charset := DEFAULT_CHARSET;
    btn6.Font.Color := clWindowText;
    btn6.Font.Height := -120;
    btn6.Font.Name := 'Arial';
    btn6.Font.Style := [];
    btn6.HeightStyle := ssPercent;
    btn6.HeightPercent := 100.000000000000000000;
    btn6.ParentFont := False;
    btn6.WidthStyle := ssPercent;
    btn6.WidthPercent := 100.000000000000000000;
    SetEvent(btn6, Self, 'OnClick', 'btn1Click');
    btn7.SetParentComponent(panStevilke);
    btn7.Name := 'btn7';
    btn7.Tag := 7;
    btn7.Left := 12;
    btn7.Top := 230;
    btn7.Width := 176;
    btn7.Height := 85;
    btn7.Anchors := [];
    btn7.Caption := '7';
    btn7.ChildOrder := 1;
    btn7.ElementClassName := 'btn btn-info fs-1';
    btn7.ElementFont := efCSS;
    btn7.Font.Charset := DEFAULT_CHARSET;
    btn7.Font.Color := clWindowText;
    btn7.Font.Height := -120;
    btn7.Font.Name := 'Arial';
    btn7.Font.Style := [];
    btn7.HeightStyle := ssPercent;
    btn7.HeightPercent := 100.000000000000000000;
    btn7.ParentFont := False;
    btn7.WidthStyle := ssPercent;
    btn7.WidthPercent := 100.000000000000000000;
    SetEvent(btn7, Self, 'OnClick', 'btn1Click');
    btn8.SetParentComponent(panStevilke);
    btn8.Name := 'btn8';
    btn8.Tag := 8;
    btn8.Left := 212;
    btn8.Top := 230;
    btn8.Width := 176;
    btn8.Height := 85;
    btn8.Anchors := [];
    btn8.Caption := '8';
    btn8.ChildOrder := 1;
    btn8.ElementClassName := 'btn btn-info fs-1';
    btn8.ElementFont := efCSS;
    btn8.Font.Charset := DEFAULT_CHARSET;
    btn8.Font.Color := clWindowText;
    btn8.Font.Height := -120;
    btn8.Font.Name := 'Arial';
    btn8.Font.Style := [];
    btn8.HeightStyle := ssPercent;
    btn8.HeightPercent := 100.000000000000000000;
    btn8.ParentFont := False;
    btn8.WidthStyle := ssPercent;
    btn8.WidthPercent := 100.000000000000000000;
    SetEvent(btn8, Self, 'OnClick', 'btn1Click');
    btn9.SetParentComponent(panStevilke);
    btn9.Name := 'btn9';
    btn9.Tag := 9;
    btn9.Left := 412;
    btn9.Top := 230;
    btn9.Width := 176;
    btn9.Height := 85;
    btn9.Anchors := [];
    btn9.Caption := '9';
    btn9.ChildOrder := 1;
    btn9.ElementClassName := 'btn btn-info fs-1';
    btn9.ElementFont := efCSS;
    btn9.Font.Charset := DEFAULT_CHARSET;
    btn9.Font.Color := clWindowText;
    btn9.Font.Height := -120;
    btn9.Font.Name := 'Arial';
    btn9.Font.Style := [];
    btn9.HeightStyle := ssPercent;
    btn9.HeightPercent := 100.000000000000000000;
    btn9.ParentFont := False;
    btn9.WidthStyle := ssPercent;
    btn9.WidthPercent := 100.000000000000000000;
    SetEvent(btn9, Self, 'OnClick', 'btn1Click');
    btn0.SetParentComponent(panStevilke);
    btn0.Name := 'btn0';
    btn0.Left := 212;
    btn0.Top := 339;
    btn0.Width := 176;
    btn0.Height := 85;
    btn0.Anchors := [];
    btn0.Caption := '0';
    btn0.ChildOrder := 1;
    btn0.ElementClassName := 'btn btn-info fs-1';
    btn0.ElementFont := efCSS;
    btn0.Font.Charset := DEFAULT_CHARSET;
    btn0.Font.Color := clWindowText;
    btn0.Font.Height := -120;
    btn0.Font.Name := 'Arial';
    btn0.Font.Style := [];
    btn0.HeightStyle := ssPercent;
    btn0.HeightPercent := 100.000000000000000000;
    btn0.ParentFont := False;
    btn0.WidthStyle := ssPercent;
    btn0.WidthPercent := 100.000000000000000000;
    SetEvent(btn0, Self, 'OnClick', 'btn1Click');
    btnComma.SetParentComponent(panStevilke);
    btnComma.Name := 'btnComma';
    btnComma.Left := 12;
    btnComma.Top := 339;
    btnComma.Width := 176;
    btnComma.Height := 85;
    btnComma.Anchors := [];
    btnComma.Caption := ',';
    btnComma.ChildOrder := 1;
    btnComma.ElementClassName := 'btn btn-info fs-1';
    btnComma.ElementFont := efCSS;
    btnComma.Font.Charset := DEFAULT_CHARSET;
    btnComma.Font.Color := clWindowText;
    btnComma.Font.Height := -120;
    btnComma.Font.Name := 'Arial';
    btnComma.Font.Style := [];
    btnComma.HeightStyle := ssPercent;
    btnComma.HeightPercent := 100.000000000000000000;
    btnComma.ParentFont := False;
    btnComma.WidthStyle := ssPercent;
    btnComma.WidthPercent := 100.000000000000000000;
    SetEvent(btnComma, Self, 'OnClick', 'btn1Click');
    btnMenjava.SetParentComponent(panStevilke);
    btnMenjava.Name := 'btnMenjava';
    btnMenjava.AlignWithMargins := True;
    btnMenjava.Left := 412;
    btnMenjava.Top := 339;
    btnMenjava.Width := 176;
    btnMenjava.Height := 85;
    btnMenjava.Margins.Left := 10;
    btnMenjava.Margins.Right := 10;
    btnMenjava.Align := alClient;
    btnMenjava.Caption := '<i class="material-icons" style="font-size:40px;">backspace</i>';
    btnMenjava.ChildOrder := 1;
    btnMenjava.ElementClassName := 'btn btn-warning';
    btnMenjava.ElementFont := efCSS;
    btnMenjava.HeightPercent := 100.000000000000000000;
    btnMenjava.WidthPercent := 100.000000000000000000;
    SetEvent(btnMenjava, Self, 'OnClick', 'btnDeleteClick');
    panFooter.SetParentComponent(Self);
    panFooter.Name := 'panFooter';
    panFooter.Left := 0;
    panFooter.Top := 706;
    panFooter.Width := 644;
    panFooter.Height := 120;
    panFooter.Align := alBottom;
    panFooter.BorderStyle := bsNone;
    panFooter.ChildOrder := 3;
    panFooter.Color := clWhite;
    panFooter.ElementFont := efCSS;
    panFooter.Padding.Top := 5;
    panFooter.TabOrder := 1;
    btnOk.SetParentComponent(panFooter);
    btnOk.Name := 'btnOk';
    btnOk.AlignWithMargins := True;
    btnOk.Left := 469;
    btnOk.Top := 10;
    btnOk.Width := 165;
    btnOk.Height := 107;
    btnOk.Margins.Top := 5;
    btnOk.Margins.Right := 10;
    btnOk.Align := alRight;
    btnOk.Caption := '<i class="material-icons" style="font-size:40px;">local_gas_station</i>';
    btnOk.ChildOrder := 1;
    btnOk.ElementClassName := 'btn btn-success';
    btnOk.ElementFont := efCSS;
    btnOk.HeightPercent := 100.000000000000000000;
    btnOk.WidthPercent := 100.000000000000000000;
    SetEvent(btnOk, Self, 'OnClick', 'btnOkClick');
    btnCancel.SetParentComponent(panFooter);
    btnCancel.Name := 'btnCancel';
    btnCancel.AlignWithMargins := True;
    btnCancel.Left := 10;
    btnCancel.Top := 8;
    btnCancel.Width := 111;
    btnCancel.Height := 109;
    btnCancel.Margins.Left := 10;
    btnCancel.Margins.Right := 10;
    btnCancel.Align := alLeft;
    btnCancel.Caption := '<i class="material-icons" style="font-size:40px;">keyboard_backspace</i>';
    btnCancel.ChildOrder := 1;
    btnCancel.ElementClassName := 'btn btn-danger';
    btnCancel.ElementFont := efCSS;
    btnCancel.HeightPercent := 100.000000000000000000;
    btnCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCancel, Self, 'OnClick', 'btnCancelClick');
    tmrStart.SetParentComponent(Self);
    tmrStart.Name := 'tmrStart';
    tmrStart.Enabled := False;
    tmrStart.Interval := 100;
    SetEvent(tmrStart, Self, 'OnTimer', 'tmrStartTimer');
    tmrStart.Left := 36;
    tmrStart.Top := 24;
  finally
    lblError.AfterLoadDFMValues;
    lblL.AfterLoadDFMValues;
    panInput.AfterLoadDFMValues;
    panStevilke.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    btn1.AfterLoadDFMValues;
    btn2.AfterLoadDFMValues;
    btn3.AfterLoadDFMValues;
    btn4.AfterLoadDFMValues;
    btn5.AfterLoadDFMValues;
    btn6.AfterLoadDFMValues;
    btn7.AfterLoadDFMValues;
    btn8.AfterLoadDFMValues;
    btn9.AfterLoadDFMValues;
    btn0.AfterLoadDFMValues;
    btnComma.AfterLoadDFMValues;
    btnMenjava.AfterLoadDFMValues;
    panFooter.AfterLoadDFMValues;
    btnOk.AfterLoadDFMValues;
    btnCancel.AfterLoadDFMValues;
    tmrStart.AfterLoadDFMValues;
  end;
end;

end.
