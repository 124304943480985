unit Odvoz;

interface

uses
  System.SysUtils, System.Variants, System.Classes, TerminalForm,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, WEBLib.DBCtrls, WEBLib.JSON, JS,
  Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.Grids, WEBLib.Controls, WEBLib.ExtCtrls,
  Data.DB, XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.DB, WEBLib.Forms,
  XData.Web.Connection, WEBLib.SignatureCapture, Vcl.Grids, Web, System.DateUtils,
  XData.Web.Client;

type
  TfrmOdvoz = class(TTerminalForm)
    dsLokacijaPosode: TDataSource;
    wdsLokacijaPosode: TXDataWebDataSet;
    dsOdvozPodrobno: TDataSource;
    wdsOdvozPodrobno: TXDataWebDataSet;
    dsOdvoz: TDataSource;
    wdsOdvoz: TXDataWebDataSet;
    panInfo: TPanel;
    WebLabel2: TLabel;
    WebLabel4: TLabel;
    WebDBLabel1: TDBLabel;
    WebDBLabel3: TDBLabel;
    WebDBLabel5: TDBLabel;
    panPosode: TPanel;
    gridPodrobno: TDBGrid;
    panSign: TPanel;
    WebLabel6: TLabel;
    signStranka: TSignatureCapture;
    panNotes: TPanel;
    WebLabel5: TLabel;
    memoNotes: TDBMemo;
    panNotesButtons: TGridPanel;
    btnOpomba1: TButton;
    btnOpomba2: TButton;
    btnOpomba3: TButton;
    btnDelNote: TButton;
    panFooter: TPanel;
    btnSave: TButton;
    btnCancel: TButton;
    btnNotes: TButton;
    btnSign: TButton;
    xwcSifranti: TXDataWebClient;
    wdsOdvozVozilo: TXDataWebEntityField;
    wdsOdvozLokacija: TXDataWebEntityField;
    wdsOdvozLokacijaStranka: TXDataWebEntityField;
    wdsOdvozLokacijaStrankaLokacije: TXDataWebDatasetField;
    wdsOdvozLokacijaStrankaId: TIntegerField;
    wdsOdvozLokacijaStrankaZunanjaOznaka: TIntegerField;
    wdsOdvozLokacijaStrankaAktivna: TBooleanField;
    wdsOdvozLokacijaStrankaObracun: TIntegerField;
    wdsOdvozLokacijaStrankaNaziv: TStringField;
    wdsOdvozLokacijaStrankaNaslov: TStringField;
    wdsOdvozLokacijaStrankaPosta: TStringField;
    wdsOdvozLokacijaStrankaKraj: TStringField;
    wdsOdvozLokacijaStrankaDavSt: TStringField;
    wdsOdvozLokacijaStrankaTel: TStringField;
    wdsOdvozLokacijaStrankaOpombe: TStringField;
    wdsOdvozLokacijaOdvozi: TXDataWebDatasetField;
    wdsOdvozLokacijaPosode: TXDataWebDatasetField;
    wdsOdvozLokacijaId: TIntegerField;
    wdsOdvozLokacijaAktivna: TBooleanField;
    wdsOdvozLokacijaNaziv: TStringField;
    wdsOdvozLokacijaOpombe: TStringField;
    wdsOdvozLokacijaLat: TFloatField;
    wdsOdvozLokacijaLon: TFloatField;
    wdsOdvozLokacijaAktivnaOd: TDateTimeField;
    wdsOdvozLokacijaAktivnaDo: TDateTimeField;
    wdsOdvozVoznik: TXDataWebEntityField;
    wdsOdvozVoznikVloga: TXDataWebEntityField;
    wdsOdvozVoznikVlogaId: TIntegerField;
    wdsOdvozVoznikVlogaOpis: TStringField;
    wdsOdvozVoznikId: TIntegerField;
    wdsOdvozVoznikUporabniskoIme: TStringField;
    wdsOdvozVoznikGeslo: TStringField;
    wdsOdvozVoznikPIN: TStringField;
    wdsOdvozVoznikIme: TStringField;
    wdsOdvozVoznikPriimek: TStringField;
    wdsOdvozVoznikAktiven: TBooleanField;
    wdsOdvozPodrobno2: TXDataWebDatasetField;
    wdsOdvozId: TIntegerField;
    wdsOdvozCasOdvoza: TDateTimeField;
    wdsOdvozPodpisStranke: TBlobField;
    wdsOdvozOpombe: TStringField;
    wdsOdvozid2: TIntegerField;
    wdsOdvozPotId: TIntegerField;
    procedure wdsOdvozPodrobnoAfterOpen(DataSet: TDataSet);
    procedure btnCancelClick(Sender: TObject);
    procedure btnSaveClick(Sender: TObject);
    procedure wdsOdvozAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure wdsOdvozAfterOpen(DataSet: TDataSet);
    procedure FormCreate(Sender: TObject);
    procedure wdsLokacijaPosodeAfterOpen(DataSet: TDataSet);
    procedure ApplyNote(Sender: TObject);
    procedure ClearNotes(Sender: TObject);
    procedure gridPodrobnoGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
    procedure btnNotesClick(Sender: TObject);
    procedure btnSignClick(Sender: TObject);
    procedure gridPodrobnoClickCell(Sender: TObject; ACol, ARow: Integer);
    procedure wdsOdvozPostError(DataSet: TDataSet; E: EDatabaseError; var Action: TDataAction);
    procedure wdsOdvozNewRecord(DataSet: TDataSet);
    procedure WebFormShow(Sender: TObject);
    procedure wdsOdvozPodrobnoNewRecord(DataSet: TDataSet);
  private
    { Private declarations }
    FOdvozId: integer; //Št odvoza, 0 za nove odvoze
    FAllowNegative: boolean;
    FSelRow: integer;
    procedure PripraviOdvoz(OdvozId: integer);
    procedure PlusClick(event: TJSEvent);
    procedure MinusClick(event: TJSEvent);
    procedure ApplyTheme(Dark: boolean); reintroduce;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses Client.Core, Main, Client.Settings;

procedure TfrmOdvoz.FormCreate(Sender: TObject);
begin
  inherited;

  FOdvozId := 0;
  FAllowNegative := true;
  PripraviOdvoz(FOdvozId);

  {$REGION 'Priprava gumbov za opombe'}
  btnOpomba1.Caption := AppSettings.Note1;
  btnOpomba2.Caption := AppSettings.Note2;
  btnOpomba3.Caption := AppSettings.Note3;

  if Length(btnOpomba1.Caption) > 30 then
    btnOpomba1.Caption := Copy(btnOpomba1.Caption, 1, 30)+ '...';

  btnOpomba2.Caption := AppSettings.Note2;
  btnOpomba3.Caption := AppSettings.Note3;

  btnOpomba1.Visible := btnOpomba1.Caption <> '';
  btnOpomba2.Visible := btnOpomba2.Caption <> '';
  btnOpomba3.Visible := btnOpomba3.Caption <> '';
  {$ENDREGION}

  ApplyTheme(AppSettings.DarkMode);
end;

procedure TfrmOdvoz.PripraviOdvoz(OdvozId: integer);
begin
  // 1. Pripravi nov odvoz
  // 2. Pridobi podrobnosti posod za stranko (tabela LokacijaPosode)
  // 2. Pripravi odvozpodrobnosti
  wdsOdvoz.Close;
  wdsOdvoz.QueryString := '$filter=Id eq '+FOdvozId.ToString+' and Lokacija/Aktivna ne 0&$orderby=Id desc&$top=1&$expand=Podrobno/Posoda,Lokacija/Stranka,Vozilo,Vozilo/TipVozila';
  wdsOdvoz.Load;
  // TODO: Dodati upoštevanje datumske omejitve od-do
end;

procedure TfrmOdvoz.wdsOdvozAfterOpen(DataSet: TDataSet);
var cas: TTime;
begin
  if wdsOdvoz.RecordCount>0 then begin
    wdsOdvoz.Edit;
  end else begin
    wdsOdvoz.Append;
    wdsOdvoz.FieldByName('Id').AsInteger := 0;
  end;

  cas := Time;
  wdsOdvoz.FieldByName('CasOdvoza').AsDateTime := DateOf(modMain.IzbranDatum) + cas;
  modMain.Log('Čas odvoza: '+wdsOdvoz.FieldByName('CasOdvoza').AsString);

  wdsOdvoz.FieldByName('PotId').AsInteger := modMain.PotId;

  if modMain.VoziloMenjavaId>0 then begin
    wdsOdvoz.FieldByName('Vozilo').Value := modMain.VoziloMenjava;
  end else begin
    wdsOdvoz.FieldByName('Vozilo').Value := modMain.Vozilo;
//    wdsOdvoz.FieldByName('Vozilo').Value := modMain.wdsVozila.CurrentData;
//    TJSObject(wdsOdvozPodrobno.CurrentData)['Vozilo@xdata.ref'] := Format('Vozilo(%d)', [modMain.VoziloId]);      // Ne deluje.. :(
  end;

  wdsOdvoz.FieldByName('Lokacija').Value := modMain.Lokacija;
  wdsOdvoz.FieldByName('Lokacija.Stranka').Value := modMain.Stranka;
  wdsOdvoz.FieldByName('Voznik').Value := modMain.Voznik;
  wdsOdvoz.Post;

  wdsOdvozPodrobno.Close;
  wdsOdvozPodrobno.SetJsonData(wdsOdvoz.FieldByName('Podrobno').Value);
  wdsOdvozPodrobno.Open;
end;

procedure TfrmOdvoz.wdsOdvozNewRecord(DataSet: TDataSet);
begin
  Dataset.FieldByName('$id').Value := 1;
  Dataset.FieldByName('Podrobno').Value := TJSArray.New;
end;

procedure TfrmOdvoz.wdsOdvozPodrobnoAfterOpen(DataSet: TDataSet);
begin
  wdsLokacijaPosode.Close;
  wdsLokacijaPosode.QueryString := '$expand=posoda&$filter=posoda/frakcija eq '+modMain.FrakcijaId.ToString+'  and lokacija/id eq '+modMain.LokacijaId.ToString+'&$orderby=posoda/idx';
  //TODO: Dodaj aktivna od-do omejitev
  wdsLokacijaPosode.Load;
end;

procedure TfrmOdvoz.wdsOdvozPodrobnoNewRecord(DataSet: TDataSet);
begin
  if wdsOdvoz.FieldByName('Id').AsInteger > 0 then
    TJSObject(wdsOdvozPodrobno.CurrentData)['Odvoz@xdata.ref'] := Format('Odvoz(%d)', [wdsOdvoz.FieldByName('Id').AsInteger])
  else
    TJSObject(wdsOdvozPodrobno.CurrentData)['Odvoz'] := New(['$ref', 1]);
end;

procedure TfrmOdvoz.wdsLokacijaPosodeAfterOpen(DataSet: TDataSet);
begin
  modMain.log('wdsLokacijaPosode.RecordCount: '+wdsLokacijaPosode.RecordCount.ToString);

  while not wdsLokacijaPosode.Eof do begin
//    if wdsOdvozPodrobno.FieldByName('Posoda.Aktivna').AsBoolean then begin
      wdsOdvozPodrobno.Append;
      wdsOdvozPodrobno.FieldByName('Id').AsInteger := 0;
      wdsOdvozPodrobno.FieldByName('Posoda').Value := wdsLokacijaPosode.FieldByName('Posoda').Value;
      wdsOdvozPodrobno.FieldByName('Kolicina').AsInteger := 0;
      wdsOdvozPodrobno.FieldByName('Teza').AsInteger := 0;
      wdsOdvozPodrobno.FieldByName('Volumen').AsInteger := 0;
      wdsOdvozPodrobno.FieldByName('Pranje').AsBoolean := false;
      wdsOdvozPodrobno.Post;
//    end;
    wdsLokacijaPosode.Next;
  end;
  wdsOdvozPodrobno.First;

  btnSave.Enabled := wdsOdvozPodrobno.RecordCount > 0;
end;

procedure TfrmOdvoz.wdsOdvozPostError(DataSet: TDataSet; E: EDatabaseError; var Action: TDataAction);
begin
  modMain.log(E.Message);
end;

procedure TfrmOdvoz.WebFormShow(Sender: TObject);
begin
  inherited;

  gridPodrobno.RowHeights[0] := 70;
  gridPodrobno.ColWidths[0] := Round(gridPodrobno.Width - gridPodrobno.ColWidths[1] - gridPodrobno.ColWidths[2] - gridPodrobno.ColWidths[3])-2;
end;

procedure TfrmOdvoz.gridPodrobnoClickCell(Sender: TObject; ACol, ARow: Integer);
begin
  FSelRow := ACol;

  if ACol = 2 then begin
    FAllowNegative := not FAllowNegative;
  end;
end;

procedure TfrmOdvoz.PlusClick(event: TJSEvent);
begin
  FSelRow := StrToIntDef(TJSHTMLElement(event.target)['data'], FSelRow);

  wdsOdvozPodrobno.RecNo := FSelRow+1;
  wdsOdvozPodrobno.Edit;
  wdsOdvozPodrobno.FieldByName('Kolicina').AsInteger := wdsOdvozPodrobno.FieldByName('Kolicina').AsInteger + 1;
  wdsOdvozPodrobno.Post;
end;

procedure TfrmOdvoz.MinusClick(event: TJSEvent);
begin
  FSelRow := StrToIntDef(TJSHTMLElement(event.target)['data'], FSelRow);

  wdsOdvozPodrobno.RecNo := FSelRow+1;

  if (wdsOdvozPodrobno.FieldByName('Kolicina').AsInteger > 0) or FAllowNegative then begin
    wdsOdvozPodrobno.Edit;
    wdsOdvozPodrobno.FieldByName('Kolicina').AsInteger := wdsOdvozPodrobno.FieldByName('Kolicina').AsInteger - 1;
    wdsOdvozPodrobno.Post;
  end;
end;

procedure TfrmOdvoz.gridPodrobnoGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
var el: TJSHTMLElement;
begin
  if ACol = 0 then
    Exit;

  if (ACol = 1) and (ARow >= 0) then begin
    el := TJSHTMLElement(document.createElement('INPUT'));
    el['type'] := 'BUTTON';
    el['value'] := '-';
    el['data'] := ARow.ToString;
    el.addEventListener('click',@MinusClick);
    el.style.setProperty('width','80px');
    el.style.setProperty('height','80px');
    el.style.setProperty('font-size','xxx-large');
//    el.innerHTML := '<i class="material-icons" style="font-size: 20px">close</i>';
    el.className := 'btn btn-xxl btn-danger';
    AElement.element.appendChild(el);
  end;

  if (ACol = gridPodrobno.Columns.Count-1 ) and (ARow >= 0) then begin
    el := TJSHTMLElement(document.createElement('INPUT'));
    el['type'] := 'BUTTON';
    el['value'] := '+';
    el['data'] := ARow.ToString;
    el.addEventListener('click',@PlusClick);
    el.style.setProperty('width','80px');
    el.style.setProperty('height','80px');
    el.style.setProperty('font-size','xxx-large');
//    el.innerHTML := '<i class="material-icons" style="font-size: 70px">add</i>';
    el.className := 'btn btn-xxl btn-success';
    AElement.element.appendChild(el);
  end;
end;

procedure TfrmOdvoz.ClearNotes(Sender: TObject);
begin
  memoNotes.Clear;
end;

procedure TfrmOdvoz.ApplyNote(Sender: TObject);
begin
  wdsOdvoz.Edit;

  if Sender = btnOpomba1 then
    wdsOdvoz.FieldByName('Opombe').AsString := AppSettings.Note1;

  if Sender = btnOpomba2 then
    wdsOdvoz.FieldByName('Opombe').AsString := AppSettings.Note2;

  if Sender = btnOpomba3 then
    wdsOdvoz.FieldByName('Opombe').AsString := AppSettings.Note3;

  wdsOdvoz.Post;
end;

procedure TfrmOdvoz.ApplyTheme(Dark: boolean);
begin
  inherited;
end;

procedure TfrmOdvoz.btnCancelClick(Sender: TObject);
begin
  frmMain.ShowLokacije;
end;

procedure TfrmOdvoz.btnNotesClick(Sender: TObject);
begin
  panSign.Hide;
  panNotes.Visible := not panNotes.Visible;
end;

procedure TfrmOdvoz.btnSaveClick(Sender: TObject);
var arrayPodrobno: TJSArray;
    itm: TJSObject;
    OpombeSoNujne: boolean;
begin
  // Shrani samo wdsOdvoz, bo detail samodejno
  // https://support.tmssoftware.com/t/how-do-i-use-master-detail-when-a-new-record-is-being-inserted-in-master/19737/8
  if wdsOdvozPodrobno.State in dsEditModes then
    wdsOdvozPodrobno.Post;

  arrayPodrobno := TJSArray.New;

  OpombeSoNujne := false;
  wdsOdvozPodrobno.DisableControls;
  wdsOdvozPodrobno.First;
  while not wdsOdvozPodrobno.Eof do begin
    if wdsOdvozPodrobno.FieldByName('Kolicina').AsInteger<0 then begin
      OpombeSoNujne := true;
    end;

    itm := TJSObject(wdsOdvozPodrobno.CurrentData);
    arrayPodrobno.Push(itm);
    wdsOdvozPodrobno.Next;
  end;
  wdsOdvozPodrobno.EnableControls;

  if OpombeSoNujne and (wdsOdvoz.FieldByName('Opombe').AsString.Trim = '')  then begin
    ShowMessage('Negativno število praznjenih posod, prosim vpiši opombe!');
    exit;
  end;

  wdsOdvoz.Edit;
  wdsOdvoz.FieldByName('Podrobno').Value := arrayPodrobno;
  wdsOdvoz.Post;

  wdsOdvoz.ApplyUpdates;
end;

procedure TfrmOdvoz.btnSignClick(Sender: TObject);
begin
  panNotes.Hide;
  panSign.Visible := not panSign.Visible;
  if not panSign.Visible then begin
    wdsOdvoz.Edit;
    wdsOdvoz.FieldByName('PodpisStranke').AsString := signStranka.GetAsBase64Image;
    wdsOdvoz.Post;
  end;
end;

procedure TfrmOdvoz.wdsOdvozAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin
  // Naslednja lokacija
  modMain.LokacijaLastRow := modMain.LokacijaLastRow + 1;

  frmMain.ShowLokacije;
end;

procedure TfrmOdvoz.LoadDFMValues;
begin
  inherited LoadDFMValues;

  panInfo := TPanel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebDBLabel1 := TDBLabel.Create(Self);
  WebDBLabel3 := TDBLabel.Create(Self);
  WebDBLabel5 := TDBLabel.Create(Self);
  panPosode := TPanel.Create(Self);
  gridPodrobno := TDBGrid.Create(Self);
  panSign := TPanel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  signStranka := TSignatureCapture.Create(Self);
  panNotes := TPanel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  memoNotes := TDBMemo.Create(Self);
  panNotesButtons := TGridPanel.Create(Self);
  btnOpomba1 := TButton.Create(Self);
  btnOpomba2 := TButton.Create(Self);
  btnOpomba3 := TButton.Create(Self);
  btnDelNote := TButton.Create(Self);
  panFooter := TPanel.Create(Self);
  btnSave := TButton.Create(Self);
  btnCancel := TButton.Create(Self);
  btnNotes := TButton.Create(Self);
  btnSign := TButton.Create(Self);
  dsLokacijaPosode := TDataSource.Create(Self);
  wdsLokacijaPosode := TXDataWebDataSet.Create(Self);
  dsOdvozPodrobno := TDataSource.Create(Self);
  wdsOdvozPodrobno := TXDataWebDataSet.Create(Self);
  dsOdvoz := TDataSource.Create(Self);
  wdsOdvoz := TXDataWebDataSet.Create(Self);
  wdsOdvozid2 := TIntegerField.Create(Self);
  wdsOdvozVozilo := TXDataWebEntityField.Create(Self);
  wdsOdvozLokacija := TXDataWebEntityField.Create(Self);
  wdsOdvozLokacijaStranka := TXDataWebEntityField.Create(Self);
  wdsOdvozLokacijaStrankaLokacije := TXDataWebDatasetField.Create(Self);
  wdsOdvozLokacijaStrankaId := TIntegerField.Create(Self);
  wdsOdvozLokacijaStrankaZunanjaOznaka := TIntegerField.Create(Self);
  wdsOdvozLokacijaStrankaAktivna := TBooleanField.Create(Self);
  wdsOdvozLokacijaStrankaObracun := TIntegerField.Create(Self);
  wdsOdvozLokacijaStrankaNaziv := TStringField.Create(Self);
  wdsOdvozLokacijaStrankaNaslov := TStringField.Create(Self);
  wdsOdvozLokacijaStrankaPosta := TStringField.Create(Self);
  wdsOdvozLokacijaStrankaKraj := TStringField.Create(Self);
  wdsOdvozLokacijaStrankaDavSt := TStringField.Create(Self);
  wdsOdvozLokacijaStrankaTel := TStringField.Create(Self);
  wdsOdvozLokacijaStrankaOpombe := TStringField.Create(Self);
  wdsOdvozLokacijaOdvozi := TXDataWebDatasetField.Create(Self);
  wdsOdvozLokacijaPosode := TXDataWebDatasetField.Create(Self);
  wdsOdvozLokacijaId := TIntegerField.Create(Self);
  wdsOdvozLokacijaAktivna := TBooleanField.Create(Self);
  wdsOdvozLokacijaNaziv := TStringField.Create(Self);
  wdsOdvozLokacijaOpombe := TStringField.Create(Self);
  wdsOdvozLokacijaLat := TFloatField.Create(Self);
  wdsOdvozLokacijaLon := TFloatField.Create(Self);
  wdsOdvozLokacijaAktivnaOd := TDateTimeField.Create(Self);
  wdsOdvozLokacijaAktivnaDo := TDateTimeField.Create(Self);
  wdsOdvozVoznik := TXDataWebEntityField.Create(Self);
  wdsOdvozVoznikVloga := TXDataWebEntityField.Create(Self);
  wdsOdvozVoznikVlogaId := TIntegerField.Create(Self);
  wdsOdvozVoznikVlogaOpis := TStringField.Create(Self);
  wdsOdvozVoznikId := TIntegerField.Create(Self);
  wdsOdvozVoznikUporabniskoIme := TStringField.Create(Self);
  wdsOdvozVoznikGeslo := TStringField.Create(Self);
  wdsOdvozVoznikPIN := TStringField.Create(Self);
  wdsOdvozVoznikIme := TStringField.Create(Self);
  wdsOdvozVoznikPriimek := TStringField.Create(Self);
  wdsOdvozVoznikAktiven := TBooleanField.Create(Self);
  wdsOdvozPodrobno2 := TXDataWebDatasetField.Create(Self);
  wdsOdvozId := TIntegerField.Create(Self);
  wdsOdvozCasOdvoza := TDateTimeField.Create(Self);
  wdsOdvozPodpisStranke := TBlobField.Create(Self);
  wdsOdvozOpombe := TStringField.Create(Self);
  wdsOdvozPotId := TIntegerField.Create(Self);
  xwcSifranti := TXDataWebClient.Create(Self);

  panInfo.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebDBLabel1.BeforeLoadDFMValues;
  WebDBLabel3.BeforeLoadDFMValues;
  WebDBLabel5.BeforeLoadDFMValues;
  panPosode.BeforeLoadDFMValues;
  gridPodrobno.BeforeLoadDFMValues;
  panSign.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  signStranka.BeforeLoadDFMValues;
  panNotes.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  memoNotes.BeforeLoadDFMValues;
  panNotesButtons.BeforeLoadDFMValues;
  btnOpomba1.BeforeLoadDFMValues;
  btnOpomba2.BeforeLoadDFMValues;
  btnOpomba3.BeforeLoadDFMValues;
  btnDelNote.BeforeLoadDFMValues;
  panFooter.BeforeLoadDFMValues;
  btnSave.BeforeLoadDFMValues;
  btnCancel.BeforeLoadDFMValues;
  btnNotes.BeforeLoadDFMValues;
  btnSign.BeforeLoadDFMValues;
  dsLokacijaPosode.BeforeLoadDFMValues;
  wdsLokacijaPosode.BeforeLoadDFMValues;
  dsOdvozPodrobno.BeforeLoadDFMValues;
  wdsOdvozPodrobno.BeforeLoadDFMValues;
  dsOdvoz.BeforeLoadDFMValues;
  wdsOdvoz.BeforeLoadDFMValues;
  wdsOdvozid2.BeforeLoadDFMValues;
  wdsOdvozVozilo.BeforeLoadDFMValues;
  wdsOdvozLokacija.BeforeLoadDFMValues;
  wdsOdvozLokacijaStranka.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaLokacije.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaId.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaZunanjaOznaka.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaAktivna.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaObracun.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaNaziv.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaNaslov.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaPosta.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaKraj.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaDavSt.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaTel.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaOpombe.BeforeLoadDFMValues;
  wdsOdvozLokacijaOdvozi.BeforeLoadDFMValues;
  wdsOdvozLokacijaPosode.BeforeLoadDFMValues;
  wdsOdvozLokacijaId.BeforeLoadDFMValues;
  wdsOdvozLokacijaAktivna.BeforeLoadDFMValues;
  wdsOdvozLokacijaNaziv.BeforeLoadDFMValues;
  wdsOdvozLokacijaOpombe.BeforeLoadDFMValues;
  wdsOdvozLokacijaLat.BeforeLoadDFMValues;
  wdsOdvozLokacijaLon.BeforeLoadDFMValues;
  wdsOdvozLokacijaAktivnaOd.BeforeLoadDFMValues;
  wdsOdvozLokacijaAktivnaDo.BeforeLoadDFMValues;
  wdsOdvozVoznik.BeforeLoadDFMValues;
  wdsOdvozVoznikVloga.BeforeLoadDFMValues;
  wdsOdvozVoznikVlogaId.BeforeLoadDFMValues;
  wdsOdvozVoznikVlogaOpis.BeforeLoadDFMValues;
  wdsOdvozVoznikId.BeforeLoadDFMValues;
  wdsOdvozVoznikUporabniskoIme.BeforeLoadDFMValues;
  wdsOdvozVoznikGeslo.BeforeLoadDFMValues;
  wdsOdvozVoznikPIN.BeforeLoadDFMValues;
  wdsOdvozVoznikIme.BeforeLoadDFMValues;
  wdsOdvozVoznikPriimek.BeforeLoadDFMValues;
  wdsOdvozVoznikAktiven.BeforeLoadDFMValues;
  wdsOdvozPodrobno2.BeforeLoadDFMValues;
  wdsOdvozId.BeforeLoadDFMValues;
  wdsOdvozCasOdvoza.BeforeLoadDFMValues;
  wdsOdvozPodpisStranke.BeforeLoadDFMValues;
  wdsOdvozOpombe.BeforeLoadDFMValues;
  wdsOdvozPotId.BeforeLoadDFMValues;
  xwcSifranti.BeforeLoadDFMValues;
  try
    Name := 'frmOdvoz';
    Left := 0;
    Top := 0;
    Color := clBtnFace;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'FormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    panInfo.SetParentComponent(Self);
    panInfo.Name := 'panInfo';
    panInfo.Left := 0;
    panInfo.Top := 0;
    panInfo.Width := 887;
    panInfo.Height := 115;
    panInfo.Align := alTop;
    panInfo.BorderStyle := bsNone;
    panInfo.ChildOrder := 3;
    panInfo.Color := clWhite;
    panInfo.TabOrder := 0;
    WebLabel2.SetParentComponent(panInfo);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 3;
    WebLabel2.Top := 43;
    WebLabel2.Width := 81;
    WebLabel2.Height := 25;
    WebLabel2.Caption := 'Lokacija:';
    WebLabel2.ElementClassName := 'h4';
    WebLabel2.ElementFont := efCSS;
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -21;
    WebLabel2.Font.Name := 'Tahoma';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(panInfo);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 3;
    WebLabel4.Top := 76;
    WebLabel4.Width := 86;
    WebLabel4.Height := 25;
    WebLabel4.Caption := 'Opombe:';
    WebLabel4.ElementClassName := 'h4';
    WebLabel4.ElementFont := efCSS;
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -21;
    WebLabel4.Font.Name := 'Tahoma';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebDBLabel1.SetParentComponent(panInfo);
    WebDBLabel1.Name := 'WebDBLabel1';
    WebDBLabel1.Left := 142;
    WebDBLabel1.Top := 43;
    WebDBLabel1.Width := 129;
    WebDBLabel1.Height := 25;
    WebDBLabel1.Caption := 'WebDBLabel1';
    WebDBLabel1.ElementClassName := 'h4';
    WebDBLabel1.ElementFont := efCSS;
    WebDBLabel1.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel1.Font.Color := clWindowText;
    WebDBLabel1.Font.Height := -21;
    WebDBLabel1.Font.Name := 'Tahoma';
    WebDBLabel1.Font.Style := [];
    WebDBLabel1.HeightPercent := 100.000000000000000000;
    WebDBLabel1.ParentFont := False;
    WebDBLabel1.WidthPercent := 100.000000000000000000;
    WebDBLabel1.DataField := 'Lokacija.Naziv';
    WebDBLabel1.DataSource := dsOdvoz;
    WebDBLabel3.SetParentComponent(panInfo);
    WebDBLabel3.Name := 'WebDBLabel3';
    WebDBLabel3.Left := 142;
    WebDBLabel3.Top := 76;
    WebDBLabel3.Width := 129;
    WebDBLabel3.Height := 25;
    WebDBLabel3.Caption := 'WebDBLabel1';
    WebDBLabel3.ElementClassName := 'h4';
    WebDBLabel3.ElementFont := efCSS;
    WebDBLabel3.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel3.Font.Color := clWindowText;
    WebDBLabel3.Font.Height := -21;
    WebDBLabel3.Font.Name := 'Tahoma';
    WebDBLabel3.Font.Style := [];
    WebDBLabel3.HeightPercent := 100.000000000000000000;
    WebDBLabel3.ParentFont := False;
    WebDBLabel3.WidthPercent := 100.000000000000000000;
    WebDBLabel3.DataField := 'Lokacija.Opombe';
    WebDBLabel3.DataSource := dsOdvoz;
    WebDBLabel5.SetParentComponent(panInfo);
    WebDBLabel5.Name := 'WebDBLabel5';
    WebDBLabel5.Left := 3;
    WebDBLabel5.Top := -3;
    WebDBLabel5.Width := 143;
    WebDBLabel5.Height := 40;
    WebDBLabel5.Caption := 'lblStranka';
    WebDBLabel5.ElementClassName := 'h2';
    WebDBLabel5.ElementFont := efCSS;
    WebDBLabel5.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel5.Font.Color := clWindowText;
    WebDBLabel5.Font.Height := -33;
    WebDBLabel5.Font.Name := 'Tahoma';
    WebDBLabel5.Font.Style := [];
    WebDBLabel5.HeightPercent := 100.000000000000000000;
    WebDBLabel5.ParentFont := False;
    WebDBLabel5.WidthPercent := 100.000000000000000000;
    WebDBLabel5.DataField := 'Lokacija.Stranka.Naziv';
    WebDBLabel5.DataSource := dsOdvoz;
    panPosode.SetParentComponent(Self);
    panPosode.Name := 'panPosode';
    panPosode.Left := 0;
    panPosode.Top := 115;
    panPosode.Width := 887;
    panPosode.Height := 112;
    panPosode.Align := alClient;
    panPosode.BorderStyle := bsNone;
    panPosode.ChildOrder := 3;
    panPosode.Color := clWhite;
    panPosode.TabOrder := 1;
    gridPodrobno.SetParentComponent(panPosode);
    gridPodrobno.Name := 'gridPodrobno';
    gridPodrobno.Left := 0;
    gridPodrobno.Top := 0;
    gridPodrobno.Width := 887;
    gridPodrobno.Height := 112;
    gridPodrobno.Align := alClient;
    gridPodrobno.Color := clWhite;
    gridPodrobno.Columns.Clear;
    with gridPodrobno.Columns.Add do
    begin
      DataField := 'Posoda.Opis';
      Title := 'Posoda';
      Width := 380;
    end;
    with gridPodrobno.Columns.Add do
    begin
      Editor := geNone;
      Width := 80;
    end;
    with gridPodrobno.Columns.Add do
    begin
      Alignment := taCenter;
      ElementClassName := 'text-center';
      DataField := 'Kolicina';
      Editor := geNone;
      Title := 'Kol.';
      Width := 70;
    end;
    with gridPodrobno.Columns.Add do
    begin
      Editor := geNone;
      Width := 80;
    end;
    gridPodrobno.DataSource := dsOdvozPodrobno;
    gridPodrobno.ElementFont := efCSS;
    gridPodrobno.ElementClassName := 'display-1';
    gridPodrobno.FixedFont.Charset := DEFAULT_CHARSET;
    gridPodrobno.FixedFont.Color := clWindowText;
    gridPodrobno.FixedFont.Height := -12;
    gridPodrobno.FixedFont.Name := 'Segoe UI';
    gridPodrobno.FixedFont.Style := [];
    gridPodrobno.FixedCols := 0;
    gridPodrobno.FixedRows := 0;
    gridPodrobno.Font.Charset := DEFAULT_CHARSET;
    gridPodrobno.Font.Color := clWindowText;
    gridPodrobno.Font.Height := -80;
    gridPodrobno.Font.Name := 'Tahoma';
    gridPodrobno.Font.Style := [];
    gridPodrobno.Options := [];
    gridPodrobno.ParentFont := False;
    gridPodrobno.TabOrder := 0;
    gridPodrobno.HeightPercent := 100.000000000000000000;
    gridPodrobno.ShowIndicator := False;
    gridPodrobno.WidthPercent := 100.000000000000000000;
    SetEvent(gridPodrobno, Self, 'OnClickCell', 'gridPodrobnoClickCell');
    SetEvent(gridPodrobno, Self, 'OnGetCellChildren', 'gridPodrobnoGetCellChildren');
    gridPodrobno.ColWidths[0] := 380;
    gridPodrobno.ColWidths[1] := 80;
    gridPodrobno.ColWidths[2] := 70;
    gridPodrobno.ColWidths[3] := 80;
    panSign.SetParentComponent(Self);
    panSign.Name := 'panSign';
    panSign.Left := 0;
    panSign.Top := 526;
    panSign.Width := 887;
    panSign.Height := 299;
    panSign.Align := alBottom;
    panSign.BorderStyle := bsNone;
    panSign.ChildOrder := 8;
    panSign.TabOrder := 2;
    panSign.Visible := False;
    WebLabel6.SetParentComponent(panSign);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 0;
    WebLabel6.Top := 0;
    WebLabel6.Width := 887;
    WebLabel6.Height := 33;
    WebLabel6.Align := alTop;
    WebLabel6.Caption := 'Podpis stranke';
    WebLabel6.Font.Charset := DEFAULT_CHARSET;
    WebLabel6.Font.Color := clWindowText;
    WebLabel6.Font.Height := -27;
    WebLabel6.Font.Name := 'Tahoma';
    WebLabel6.Font.Style := [];
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.ParentFont := False;
    WebLabel6.WidthPercent := 100.000000000000000000;
    signStranka.SetParentComponent(panSign);
    signStranka.Name := 'signStranka';
    signStranka.Left := 0;
    signStranka.Top := 33;
    signStranka.Width := 887;
    signStranka.Height := 266;
    signStranka.Align := alClient;
    signStranka.ChildOrder := 2;
    signStranka.TabOrder := 0;
    signStranka.TextPosition.Line.Color := clGray;
    signStranka.Text := 'Podpis stranke';
    panNotes.SetParentComponent(Self);
    panNotes.Name := 'panNotes';
    panNotes.Left := 0;
    panNotes.Top := 227;
    panNotes.Width := 887;
    panNotes.Height := 299;
    panNotes.Align := alBottom;
    panNotes.Caption := 'panNotes';
    panNotes.ChildOrder := 8;
    panNotes.TabOrder := 3;
    panNotes.Visible := False;
    WebLabel5.SetParentComponent(panNotes);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 0;
    WebLabel5.Top := 0;
    WebLabel5.Width := 887;
    WebLabel5.Height := 33;
    WebLabel5.Align := alTop;
    WebLabel5.Caption := 'Opombe';
    WebLabel5.Font.Charset := DEFAULT_CHARSET;
    WebLabel5.Font.Color := clWindowText;
    WebLabel5.Font.Height := -27;
    WebLabel5.Font.Name := 'Tahoma';
    WebLabel5.Font.Style := [];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    memoNotes.SetParentComponent(panNotes);
    memoNotes.Name := 'memoNotes';
    memoNotes.Left := 0;
    memoNotes.Top := 33;
    memoNotes.Width := 887;
    memoNotes.Height := 166;
    memoNotes.Align := alClient;
    memoNotes.Font.Charset := DEFAULT_CHARSET;
    memoNotes.Font.Color := clWindowText;
    memoNotes.Font.Height := -27;
    memoNotes.Font.Name := 'Tahoma';
    memoNotes.Font.Style := [];
    memoNotes.HeightPercent := 100.000000000000000000;
    memoNotes.Lines.BeginUpdate;
    try
      memoNotes.Lines.Clear;
      memoNotes.Lines.Add('memoNotes');
    finally
      memoNotes.Lines.EndUpdate;
    end;
    memoNotes.ParentFont := False;
    memoNotes.SelLength := 0;
    memoNotes.SelStart := 0;
    memoNotes.WidthPercent := 100.000000000000000000;
    memoNotes.DataField := 'Opombe';
    memoNotes.DataSource := dsOdvoz;
    panNotesButtons.SetParentComponent(panNotes);
    panNotesButtons.Name := 'panNotesButtons';
    panNotesButtons.Left := 0;
    panNotesButtons.Top := 199;
    panNotesButtons.Width := 887;
    panNotesButtons.Height := 100;
    panNotesButtons.Align := alBottom;
    panNotesButtons.ChildOrder := 4;
    panNotesButtons.ColumnCollection.Clear;
    with panNotesButtons.ColumnCollection.Add do
    begin
      MarginLeft := 3;
      MarginRight := 3;
      Value := 33;
    end;
    with panNotesButtons.ColumnCollection.Add do
    begin
      MarginLeft := 3;
      MarginRight := 3;
      Value := 33;
    end;
    with panNotesButtons.ColumnCollection.Add do
    begin
      MarginLeft := 3;
      MarginRight := 3;
      Value := 33;
    end;
    panNotesButtons.ControlCollection.Clear;
    with panNotesButtons.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btnOpomba1;
    end;
    with panNotesButtons.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btnOpomba2;
    end;
    with panNotesButtons.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btnOpomba3;
    end;
    panNotesButtons.Color := clWhite;
    panNotesButtons.GridLineColor := clBlack;
    panNotesButtons.RowCollection.Clear;
    with panNotesButtons.RowCollection.Add do
    begin
      Value := 100;
    end;
    btnOpomba1.SetParentComponent(panNotesButtons);
    btnOpomba1.Name := 'btnOpomba1';
    btnOpomba1.AlignWithMargins := True;
    btnOpomba1.Left := 5;
    btnOpomba1.Top := 2;
    btnOpomba1.Width := 283;
    btnOpomba1.Height := 96;
    btnOpomba1.Align := alClient;
    btnOpomba1.Caption := 'Opomba 1';
    btnOpomba1.ChildOrder := 2;
    btnOpomba1.ElementClassName := 'btn btn-xxl btn-primary shadow d-inline-block text-truncate';
    btnOpomba1.ElementFont := efCSS;
    btnOpomba1.HeightPercent := 100.000000000000000000;
    btnOpomba1.WidthPercent := 100.000000000000000000;
    SetEvent(btnOpomba1, Self, 'OnClick', 'ApplyNote');
    btnOpomba2.SetParentComponent(panNotesButtons);
    btnOpomba2.Name := 'btnOpomba2';
    btnOpomba2.AlignWithMargins := True;
    btnOpomba2.Left := 298;
    btnOpomba2.Top := 2;
    btnOpomba2.Width := 283;
    btnOpomba2.Height := 96;
    btnOpomba2.Align := alClient;
    btnOpomba2.Caption := 'Opomba 2';
    btnOpomba2.ChildOrder := 2;
    btnOpomba2.ElementClassName := 'btn btn-xxl btn-primary shadow';
    btnOpomba2.ElementFont := efCSS;
    btnOpomba2.HeightPercent := 100.000000000000000000;
    btnOpomba2.WidthPercent := 100.000000000000000000;
    SetEvent(btnOpomba2, Self, 'OnClick', 'ApplyNote');
    btnOpomba3.SetParentComponent(panNotesButtons);
    btnOpomba3.Name := 'btnOpomba3';
    btnOpomba3.AlignWithMargins := True;
    btnOpomba3.Left := 591;
    btnOpomba3.Top := 2;
    btnOpomba3.Width := 283;
    btnOpomba3.Height := 96;
    btnOpomba3.Align := alClient;
    btnOpomba3.Caption := 'Opomba 3';
    btnOpomba3.ChildOrder := 2;
    btnOpomba3.ElementClassName := 'btn btn-xxl btn-primary shadow';
    btnOpomba3.ElementFont := efCSS;
    btnOpomba3.HeightPercent := 100.000000000000000000;
    btnOpomba3.WidthPercent := 100.000000000000000000;
    SetEvent(btnOpomba3, Self, 'OnClick', 'ApplyNote');
    btnDelNote.SetParentComponent(panNotes);
    btnDelNote.Name := 'btnDelNote';
    btnDelNote.AlignWithMargins := True;
    btnDelNote.Left := 834;
    btnDelNote.Top := 39;
    btnDelNote.Width := 59;
    btnDelNote.Height := 62;
    btnDelNote.Margins.Right := 10;
    btnDelNote.Anchors := [akTop,akRight];
    btnDelNote.Caption := '<i class="material-icons">backspace</i>';
    btnDelNote.ChildOrder := 2;
    btnDelNote.ElementClassName := 'btn btn-xxl btn-primary shadow';
    btnDelNote.ElementFont := efCSS;
    btnDelNote.HeightPercent := 100.000000000000000000;
    btnDelNote.WidthPercent := 100.000000000000000000;
    SetEvent(btnDelNote, Self, 'OnClick', 'ClearNotes');
    panFooter.SetParentComponent(Self);
    panFooter.Name := 'panFooter';
    panFooter.Left := 0;
    panFooter.Top := 825;
    panFooter.Width := 887;
    panFooter.Height := 120;
    panFooter.Align := alBottom;
    panFooter.BorderColor := clNone;
    panFooter.BorderStyle := bsNone;
    panFooter.ChildOrder := 3;
    panFooter.Color := clWhite;
    panFooter.TabOrder := 4;
    btnSave.SetParentComponent(panFooter);
    btnSave.Name := 'btnSave';
    btnSave.AlignWithMargins := True;
    btnSave.Left := 712;
    btnSave.Top := 3;
    btnSave.Width := 165;
    btnSave.Height := 114;
    btnSave.Margins.Right := 10;
    btnSave.Align := alRight;
    btnSave.Caption := '<i class="material-icons" style="font-size:40px;">done</i>';
    btnSave.ElementClassName := 'btn btn-success';
    btnSave.ElementFont := efCSS;
    btnSave.Enabled := False;
    btnSave.HeightPercent := 100.000000000000000000;
    btnSave.WidthPercent := 100.000000000000000000;
    SetEvent(btnSave, Self, 'OnClick', 'btnSaveClick');
    btnCancel.SetParentComponent(panFooter);
    btnCancel.Name := 'btnCancel';
    btnCancel.AlignWithMargins := True;
    btnCancel.Left := 10;
    btnCancel.Top := 3;
    btnCancel.Width := 100;
    btnCancel.Height := 114;
    btnCancel.Margins.Left := 10;
    btnCancel.Margins.Right := 10;
    btnCancel.Align := alLeft;
    btnCancel.Caption := '<i class="material-icons" style="font-size:40px;">close</i>';
    btnCancel.ChildOrder := 1;
    btnCancel.ElementClassName := 'btn btn-danger';
    btnCancel.ElementFont := efCSS;
    btnCancel.HeightPercent := 100.000000000000000000;
    btnCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCancel, Self, 'OnClick', 'btnCancelClick');
    btnNotes.SetParentComponent(panFooter);
    btnNotes.Name := 'btnNotes';
    btnNotes.AlignWithMargins := True;
    btnNotes.Left := 599;
    btnNotes.Top := 3;
    btnNotes.Width := 100;
    btnNotes.Height := 114;
    btnNotes.Margins.Right := 10;
    btnNotes.Align := alRight;
    btnNotes.Caption := '<i class="material-icons">notes</i>';
    btnNotes.ElementClassName := 'btn btn-primary';
    btnNotes.ElementFont := efCSS;
    btnNotes.HeightPercent := 100.000000000000000000;
    btnNotes.WidthPercent := 100.000000000000000000;
    SetEvent(btnNotes, Self, 'OnClick', 'btnNotesClick');
    btnSign.SetParentComponent(panFooter);
    btnSign.Name := 'btnSign';
    btnSign.AlignWithMargins := True;
    btnSign.Left := 486;
    btnSign.Top := 3;
    btnSign.Width := 100;
    btnSign.Height := 114;
    btnSign.Margins.Right := 10;
    btnSign.Align := alRight;
    btnSign.Caption := '<i class="material-icons">person</i>';
    btnSign.ElementClassName := 'btn btn-primary';
    btnSign.ElementFont := efCSS;
    btnSign.HeightPercent := 100.000000000000000000;
    btnSign.WidthPercent := 100.000000000000000000;
    SetEvent(btnSign, Self, 'OnClick', 'btnSignClick');
    dsLokacijaPosode.SetParentComponent(Self);
    dsLokacijaPosode.Name := 'dsLokacijaPosode';
    dsLokacijaPosode.DataSet := wdsLokacijaPosode;
    dsLokacijaPosode.Left := 712;
    dsLokacijaPosode.Top := 104;
    wdsLokacijaPosode.SetParentComponent(Self);
    wdsLokacijaPosode.Name := 'wdsLokacijaPosode';
    wdsLokacijaPosode.AfterOpen := wdsLokacijaPosodeAfterOpen;
    SetEvent(wdsLokacijaPosode, Self, 'OnEditError', 'wdsOdvozPostError');
    wdsLokacijaPosode.EntitySetName := 'Lokacijaposode';
    wdsLokacijaPosode.Connection := modMain.connServer;
    wdsLokacijaPosode.SubpropsDepth := 2;
    wdsLokacijaPosode.QueryString := 'expand=posoda&$filter=lokacija/lastnik/id%20eq%202';
    wdsLokacijaPosode.Left := 712;
    wdsLokacijaPosode.Top := 48;
    dsOdvozPodrobno.SetParentComponent(Self);
    dsOdvozPodrobno.Name := 'dsOdvozPodrobno';
    dsOdvozPodrobno.DataSet := wdsOdvozPodrobno;
    dsOdvozPodrobno.Left := 592;
    dsOdvozPodrobno.Top := 104;
    wdsOdvozPodrobno.SetParentComponent(Self);
    wdsOdvozPodrobno.Name := 'wdsOdvozPodrobno';
    wdsOdvozPodrobno.AfterOpen := wdsOdvozPodrobnoAfterOpen;
    SetEvent(wdsOdvozPodrobno, Self, 'OnEditError', 'wdsOdvozPostError');
    SetEvent(wdsOdvozPodrobno, Self, 'OnNewRecord', 'wdsOdvozPodrobnoNewRecord');
    SetEvent(wdsOdvozPodrobno, Self, 'OnPostError', 'wdsOdvozPostError');
    wdsOdvozPodrobno.EntitySetName := 'Odvozpodrobno';
    wdsOdvozPodrobno.Connection := modMain.connServer;
    wdsOdvozPodrobno.SubpropsDepth := 1;
    wdsOdvozPodrobno.QueryString := 'filter=Id%20eq%204&$orderby=Id%20desc&$top=1&$expand=Posoda';
    wdsOdvozPodrobno.Left := 592;
    wdsOdvozPodrobno.Top := 48;
    dsOdvoz.SetParentComponent(Self);
    dsOdvoz.Name := 'dsOdvoz';
    dsOdvoz.DataSet := wdsOdvoz;
    dsOdvoz.Left := 496;
    dsOdvoz.Top := 104;
    wdsOdvoz.SetParentComponent(Self);
    wdsOdvoz.Name := 'wdsOdvoz';
    wdsOdvoz.AfterApplyUpdates := wdsOdvozAfterApplyUpdates;
    wdsOdvoz.AfterOpen := wdsOdvozAfterOpen;
    SetEvent(wdsOdvoz, Self, 'OnEditError', 'wdsOdvozPostError');
    SetEvent(wdsOdvoz, Self, 'OnNewRecord', 'wdsOdvozNewRecord');
    SetEvent(wdsOdvoz, Self, 'OnPostError', 'wdsOdvozPostError');
    wdsOdvoz.EntitySetName := 'Odvoz';
    wdsOdvoz.Connection := modMain.connServer;
    wdsOdvoz.SubpropsDepth := 3;
    wdsOdvoz.QueryString := 'orderby=Id desc&$top=1&$expand=Podrobno/Posoda,Lokacija/Stranka,Vozilo/VrstaVozila'#39';';
    wdsOdvoz.QueryTop := 1;
    wdsOdvoz.Left := 496;
    wdsOdvoz.Top := 48;
    wdsOdvozid2.SetParentComponent(wdsOdvoz);
    wdsOdvozid2.Name := 'wdsOdvozid2';
    wdsOdvozid2.FieldName := '$id';
    wdsOdvozVozilo.SetParentComponent(wdsOdvoz);
    wdsOdvozVozilo.Name := 'wdsOdvozVozilo';
    wdsOdvozVozilo.FieldName := 'Vozilo';
    wdsOdvozVozilo.Required := True;
    wdsOdvozLokacija.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacija.Name := 'wdsOdvozLokacija';
    wdsOdvozLokacija.FieldName := 'Lokacija';
    wdsOdvozLokacija.Required := True;
    wdsOdvozLokacijaStranka.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStranka.Name := 'wdsOdvozLokacijaStranka';
    wdsOdvozLokacijaStranka.FieldName := 'Lokacija.Stranka';
    wdsOdvozLokacijaStrankaLokacije.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaLokacije.Name := 'wdsOdvozLokacijaStrankaLokacije';
    wdsOdvozLokacijaStrankaLokacije.FieldName := 'Lokacija.Stranka.Lokacije';
    wdsOdvozLokacijaStrankaLokacije.ReadOnly := True;
    wdsOdvozLokacijaStrankaId.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaId.Name := 'wdsOdvozLokacijaStrankaId';
    wdsOdvozLokacijaStrankaId.FieldName := 'Lokacija.Stranka.Id';
    wdsOdvozLokacijaStrankaZunanjaOznaka.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaZunanjaOznaka.Name := 'wdsOdvozLokacijaStrankaZunanjaOznaka';
    wdsOdvozLokacijaStrankaZunanjaOznaka.FieldName := 'Lokacija.Stranka.ZunanjaOznaka';
    wdsOdvozLokacijaStrankaAktivna.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaAktivna.Name := 'wdsOdvozLokacijaStrankaAktivna';
    wdsOdvozLokacijaStrankaAktivna.FieldName := 'Lokacija.Stranka.Aktivna';
    wdsOdvozLokacijaStrankaObracun.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaObracun.Name := 'wdsOdvozLokacijaStrankaObracun';
    wdsOdvozLokacijaStrankaObracun.FieldName := 'Lokacija.Stranka.Obracun';
    wdsOdvozLokacijaStrankaNaziv.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaNaziv.Name := 'wdsOdvozLokacijaStrankaNaziv';
    wdsOdvozLokacijaStrankaNaziv.FieldName := 'Lokacija.Stranka.Naziv';
    wdsOdvozLokacijaStrankaNaziv.Size := 50;
    wdsOdvozLokacijaStrankaNaslov.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaNaslov.Name := 'wdsOdvozLokacijaStrankaNaslov';
    wdsOdvozLokacijaStrankaNaslov.FieldName := 'Lokacija.Stranka.Naslov';
    wdsOdvozLokacijaStrankaNaslov.Size := 50;
    wdsOdvozLokacijaStrankaPosta.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaPosta.Name := 'wdsOdvozLokacijaStrankaPosta';
    wdsOdvozLokacijaStrankaPosta.FieldName := 'Lokacija.Stranka.Posta';
    wdsOdvozLokacijaStrankaPosta.Size := 15;
    wdsOdvozLokacijaStrankaKraj.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaKraj.Name := 'wdsOdvozLokacijaStrankaKraj';
    wdsOdvozLokacijaStrankaKraj.FieldName := 'Lokacija.Stranka.Kraj';
    wdsOdvozLokacijaStrankaKraj.Size := 50;
    wdsOdvozLokacijaStrankaDavSt.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaDavSt.Name := 'wdsOdvozLokacijaStrankaDavSt';
    wdsOdvozLokacijaStrankaDavSt.FieldName := 'Lokacija.Stranka.DavSt';
    wdsOdvozLokacijaStrankaDavSt.Size := 15;
    wdsOdvozLokacijaStrankaTel.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaTel.Name := 'wdsOdvozLokacijaStrankaTel';
    wdsOdvozLokacijaStrankaTel.FieldName := 'Lokacija.Stranka.Tel';
    wdsOdvozLokacijaStrankaOpombe.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaOpombe.Name := 'wdsOdvozLokacijaStrankaOpombe';
    wdsOdvozLokacijaStrankaOpombe.FieldName := 'Lokacija.Stranka.Opombe';
    wdsOdvozLokacijaStrankaOpombe.Size := 250;
    wdsOdvozLokacijaOdvozi.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaOdvozi.Name := 'wdsOdvozLokacijaOdvozi';
    wdsOdvozLokacijaOdvozi.FieldName := 'Lokacija.Odvozi';
    wdsOdvozLokacijaOdvozi.ReadOnly := True;
    wdsOdvozLokacijaPosode.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaPosode.Name := 'wdsOdvozLokacijaPosode';
    wdsOdvozLokacijaPosode.FieldName := 'Lokacija.Posode';
    wdsOdvozLokacijaPosode.ReadOnly := True;
    wdsOdvozLokacijaId.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaId.Name := 'wdsOdvozLokacijaId';
    wdsOdvozLokacijaId.FieldName := 'Lokacija.Id';
    wdsOdvozLokacijaAktivna.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaAktivna.Name := 'wdsOdvozLokacijaAktivna';
    wdsOdvozLokacijaAktivna.FieldName := 'Lokacija.Aktivna';
    wdsOdvozLokacijaNaziv.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaNaziv.Name := 'wdsOdvozLokacijaNaziv';
    wdsOdvozLokacijaNaziv.FieldName := 'Lokacija.Naziv';
    wdsOdvozLokacijaNaziv.Size := 250;
    wdsOdvozLokacijaOpombe.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaOpombe.Name := 'wdsOdvozLokacijaOpombe';
    wdsOdvozLokacijaOpombe.FieldName := 'Lokacija.Opombe';
    wdsOdvozLokacijaOpombe.Size := 250;
    wdsOdvozLokacijaLat.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaLat.Name := 'wdsOdvozLokacijaLat';
    wdsOdvozLokacijaLat.FieldName := 'Lokacija.Lat';
    wdsOdvozLokacijaLon.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaLon.Name := 'wdsOdvozLokacijaLon';
    wdsOdvozLokacijaLon.FieldName := 'Lokacija.Lon';
    wdsOdvozLokacijaAktivnaOd.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaAktivnaOd.Name := 'wdsOdvozLokacijaAktivnaOd';
    wdsOdvozLokacijaAktivnaOd.FieldName := 'Lokacija.AktivnaOd';
    wdsOdvozLokacijaAktivnaDo.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaAktivnaDo.Name := 'wdsOdvozLokacijaAktivnaDo';
    wdsOdvozLokacijaAktivnaDo.FieldName := 'Lokacija.AktivnaDo';
    wdsOdvozVoznik.SetParentComponent(wdsOdvoz);
    wdsOdvozVoznik.Name := 'wdsOdvozVoznik';
    wdsOdvozVoznik.FieldName := 'Voznik';
    wdsOdvozVoznikVloga.SetParentComponent(wdsOdvoz);
    wdsOdvozVoznikVloga.Name := 'wdsOdvozVoznikVloga';
    wdsOdvozVoznikVloga.FieldName := 'Voznik.Vloga';
    wdsOdvozVoznikVlogaId.SetParentComponent(wdsOdvoz);
    wdsOdvozVoznikVlogaId.Name := 'wdsOdvozVoznikVlogaId';
    wdsOdvozVoznikVlogaId.FieldName := 'Voznik.Vloga.Id';
    wdsOdvozVoznikVlogaOpis.SetParentComponent(wdsOdvoz);
    wdsOdvozVoznikVlogaOpis.Name := 'wdsOdvozVoznikVlogaOpis';
    wdsOdvozVoznikVlogaOpis.FieldName := 'Voznik.Vloga.Opis';
    wdsOdvozVoznikVlogaOpis.Size := 50;
    wdsOdvozVoznikId.SetParentComponent(wdsOdvoz);
    wdsOdvozVoznikId.Name := 'wdsOdvozVoznikId';
    wdsOdvozVoznikId.FieldName := 'Voznik.Id';
    wdsOdvozVoznikUporabniskoIme.SetParentComponent(wdsOdvoz);
    wdsOdvozVoznikUporabniskoIme.Name := 'wdsOdvozVoznikUporabniskoIme';
    wdsOdvozVoznikUporabniskoIme.FieldName := 'Voznik.UporabniskoIme';
    wdsOdvozVoznikUporabniskoIme.Size := 50;
    wdsOdvozVoznikGeslo.SetParentComponent(wdsOdvoz);
    wdsOdvozVoznikGeslo.Name := 'wdsOdvozVoznikGeslo';
    wdsOdvozVoznikGeslo.FieldName := 'Voznik.Geslo';
    wdsOdvozVoznikGeslo.Size := 50;
    wdsOdvozVoznikPIN.SetParentComponent(wdsOdvoz);
    wdsOdvozVoznikPIN.Name := 'wdsOdvozVoznikPIN';
    wdsOdvozVoznikPIN.FieldName := 'Voznik.PIN';
    wdsOdvozVoznikPIN.Size := 10;
    wdsOdvozVoznikIme.SetParentComponent(wdsOdvoz);
    wdsOdvozVoznikIme.Name := 'wdsOdvozVoznikIme';
    wdsOdvozVoznikIme.FieldName := 'Voznik.Ime';
    wdsOdvozVoznikIme.Size := 50;
    wdsOdvozVoznikPriimek.SetParentComponent(wdsOdvoz);
    wdsOdvozVoznikPriimek.Name := 'wdsOdvozVoznikPriimek';
    wdsOdvozVoznikPriimek.FieldName := 'Voznik.Priimek';
    wdsOdvozVoznikPriimek.Size := 50;
    wdsOdvozVoznikAktiven.SetParentComponent(wdsOdvoz);
    wdsOdvozVoznikAktiven.Name := 'wdsOdvozVoznikAktiven';
    wdsOdvozVoznikAktiven.FieldName := 'Voznik.Aktiven';
    wdsOdvozPodrobno2.SetParentComponent(wdsOdvoz);
    wdsOdvozPodrobno2.Name := 'wdsOdvozPodrobno2';
    wdsOdvozPodrobno2.FieldName := 'Podrobno';
    wdsOdvozPodrobno2.ReadOnly := True;
    wdsOdvozId.SetParentComponent(wdsOdvoz);
    wdsOdvozId.Name := 'wdsOdvozId';
    wdsOdvozId.FieldName := 'Id';
    wdsOdvozId.Required := True;
    wdsOdvozCasOdvoza.SetParentComponent(wdsOdvoz);
    wdsOdvozCasOdvoza.Name := 'wdsOdvozCasOdvoza';
    wdsOdvozCasOdvoza.FieldName := 'CasOdvoza';
    wdsOdvozPodpisStranke.SetParentComponent(wdsOdvoz);
    wdsOdvozPodpisStranke.Name := 'wdsOdvozPodpisStranke';
    wdsOdvozPodpisStranke.FieldName := 'PodpisStranke';
    wdsOdvozOpombe.SetParentComponent(wdsOdvoz);
    wdsOdvozOpombe.Name := 'wdsOdvozOpombe';
    wdsOdvozOpombe.FieldName := 'Opombe';
    wdsOdvozOpombe.Size := 50;
    wdsOdvozPotId.SetParentComponent(wdsOdvoz);
    wdsOdvozPotId.Name := 'wdsOdvozPotId';
    wdsOdvozPotId.FieldName := 'PotId';
    xwcSifranti.SetParentComponent(Self);
    xwcSifranti.Name := 'xwcSifranti';
    xwcSifranti.Connection := modMain.connServer;
    xwcSifranti.Left := 816;
    xwcSifranti.Top := 48;
  finally
    panInfo.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebDBLabel1.AfterLoadDFMValues;
    WebDBLabel3.AfterLoadDFMValues;
    WebDBLabel5.AfterLoadDFMValues;
    panPosode.AfterLoadDFMValues;
    gridPodrobno.AfterLoadDFMValues;
    panSign.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    signStranka.AfterLoadDFMValues;
    panNotes.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    memoNotes.AfterLoadDFMValues;
    panNotesButtons.AfterLoadDFMValues;
    btnOpomba1.AfterLoadDFMValues;
    btnOpomba2.AfterLoadDFMValues;
    btnOpomba3.AfterLoadDFMValues;
    btnDelNote.AfterLoadDFMValues;
    panFooter.AfterLoadDFMValues;
    btnSave.AfterLoadDFMValues;
    btnCancel.AfterLoadDFMValues;
    btnNotes.AfterLoadDFMValues;
    btnSign.AfterLoadDFMValues;
    dsLokacijaPosode.AfterLoadDFMValues;
    wdsLokacijaPosode.AfterLoadDFMValues;
    dsOdvozPodrobno.AfterLoadDFMValues;
    wdsOdvozPodrobno.AfterLoadDFMValues;
    dsOdvoz.AfterLoadDFMValues;
    wdsOdvoz.AfterLoadDFMValues;
    wdsOdvozid2.AfterLoadDFMValues;
    wdsOdvozVozilo.AfterLoadDFMValues;
    wdsOdvozLokacija.AfterLoadDFMValues;
    wdsOdvozLokacijaStranka.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaLokacije.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaId.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaZunanjaOznaka.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaAktivna.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaObracun.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaNaziv.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaNaslov.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaPosta.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaKraj.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaDavSt.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaTel.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaOpombe.AfterLoadDFMValues;
    wdsOdvozLokacijaOdvozi.AfterLoadDFMValues;
    wdsOdvozLokacijaPosode.AfterLoadDFMValues;
    wdsOdvozLokacijaId.AfterLoadDFMValues;
    wdsOdvozLokacijaAktivna.AfterLoadDFMValues;
    wdsOdvozLokacijaNaziv.AfterLoadDFMValues;
    wdsOdvozLokacijaOpombe.AfterLoadDFMValues;
    wdsOdvozLokacijaLat.AfterLoadDFMValues;
    wdsOdvozLokacijaLon.AfterLoadDFMValues;
    wdsOdvozLokacijaAktivnaOd.AfterLoadDFMValues;
    wdsOdvozLokacijaAktivnaDo.AfterLoadDFMValues;
    wdsOdvozVoznik.AfterLoadDFMValues;
    wdsOdvozVoznikVloga.AfterLoadDFMValues;
    wdsOdvozVoznikVlogaId.AfterLoadDFMValues;
    wdsOdvozVoznikVlogaOpis.AfterLoadDFMValues;
    wdsOdvozVoznikId.AfterLoadDFMValues;
    wdsOdvozVoznikUporabniskoIme.AfterLoadDFMValues;
    wdsOdvozVoznikGeslo.AfterLoadDFMValues;
    wdsOdvozVoznikPIN.AfterLoadDFMValues;
    wdsOdvozVoznikIme.AfterLoadDFMValues;
    wdsOdvozVoznikPriimek.AfterLoadDFMValues;
    wdsOdvozVoznikAktiven.AfterLoadDFMValues;
    wdsOdvozPodrobno2.AfterLoadDFMValues;
    wdsOdvozId.AfterLoadDFMValues;
    wdsOdvozCasOdvoza.AfterLoadDFMValues;
    wdsOdvozPodpisStranke.AfterLoadDFMValues;
    wdsOdvozOpombe.AfterLoadDFMValues;
    wdsOdvozPotId.AfterLoadDFMValues;
    xwcSifranti.AfterLoadDFMValues;
  end;
end;

end.
