unit Potrditev;

interface

uses
  System.SysUtils, System.Classes, Vcl.Forms, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls, Data.DB, TerminalForm,
  WEBLib.ExtCtrls;

type
  TfrmPotrditev = class(TTerminalForm)
    lblTitle: TLabel;
    lblMsg: TLabel;
    panFooter: TPanel;
    btnOk: TButton;
    btnCancel: TButton;
    WebLabel1: TLabel;
    procedure FormShow(Sender: TObject);
    procedure btnCancelClick(Sender: TObject);
    procedure btnOkClick(Sender: TObject);
  private
    { Private declarations }
    procedure ApplyTheme(Dark: boolean);
    procedure SetText(const Value: string);
    procedure SetTitle(const Value: string);
  public
    { Public declarations }
    property Naslov: string write SetTitle;
    property Tekst: string write SetText;
  protected procedure LoadDFMValues; override; end;

var
  frmPotrditev: TfrmPotrditev;

implementation

{$R *.dfm}

uses Main, Client.Core, Client.Settings;

procedure TfrmPotrditev.ApplyTheme(Dark: boolean);
begin
  inherited;
end;

procedure TfrmPotrditev.btnCancelClick(Sender: TObject);
begin
  inherited;
  frmMain.ShowPrevious;
end;

procedure TfrmPotrditev.btnOkClick(Sender: TObject);
begin
  inherited;
  modMain.TehtajVozilo(modMain.VoziloId, modMain.VoznikUserName);
  frmMain.ShowLokacije;
end;

procedure TfrmPotrditev.FormShow(Sender: TObject);
begin
  inherited;
  ApplyTheme(AppSettings.DarkMode);
end;

procedure TfrmPotrditev.SetText(const Value: string);
begin
  lblMsg.Caption := Value;
end;

procedure TfrmPotrditev.SetTitle(const Value: string);
begin
  lblTitle.Caption := Value;
end;

procedure TfrmPotrditev.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lblTitle := TLabel.Create(Self);
  lblMsg := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  panFooter := TPanel.Create(Self);
  btnOk := TButton.Create(Self);
  btnCancel := TButton.Create(Self);

  lblTitle.BeforeLoadDFMValues;
  lblMsg.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  panFooter.BeforeLoadDFMValues;
  btnOk.BeforeLoadDFMValues;
  btnCancel.BeforeLoadDFMValues;
  try
    Name := 'frmPotrditev';
    Left := 0;
    Top := 0;
    Color := clBtnFace;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -27;
    Font.Name := 'Segoe UI';
    Font.Style := [];
    SetEvent(Self, 'OnShow', 'FormShow');
    lblTitle.SetParentComponent(Self);
    lblTitle.Name := 'lblTitle';
    lblTitle.Left := 0;
    lblTitle.Top := 0;
    lblTitle.Width := 644;
    lblTitle.Height := 37;
    lblTitle.Align := alTop;
    lblTitle.Alignment := taCenter;
    lblTitle.Color := clBtnFace;
    lblTitle.ElementClassName := 'display-3 text-danger';
    lblTitle.ElementFont := efCSS;
    lblTitle.Font.Charset := DEFAULT_CHARSET;
    lblTitle.Font.Color := clRed;
    lblTitle.Font.Height := -27;
    lblTitle.Font.Name := 'Segoe UI';
    lblTitle.Font.Style := [];
    lblTitle.HeightStyle := ssAuto;
    lblTitle.HeightPercent := 100.000000000000000000;
    lblTitle.Layout := tlCenter;
    lblTitle.ParentFont := False;
    lblTitle.WidthPercent := 100.000000000000000000;
    lblMsg.SetParentComponent(Self);
    lblMsg.Name := 'lblMsg';
    lblMsg.Left := 0;
    lblMsg.Top := 74;
    lblMsg.Width := 644;
    lblMsg.Height := 582;
    lblMsg.Align := alClient;
    lblMsg.Alignment := taCenter;
    lblMsg.Color := clBtnFace;
    lblMsg.ElementClassName := 'display-3';
    lblMsg.ElementFont := efCSS;
    lblMsg.Font.Charset := DEFAULT_CHARSET;
    lblMsg.Font.Color := clBlack;
    lblMsg.Font.Height := -133;
    lblMsg.Font.Name := 'Arial';
    lblMsg.Font.Style := [];
    lblMsg.HeightStyle := ssAuto;
    lblMsg.HeightPercent := 100.000000000000000000;
    lblMsg.ParentFont := False;
    lblMsg.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 0;
    WebLabel1.Top := 37;
    WebLabel1.Width := 644;
    WebLabel1.Height := 37;
    WebLabel1.Align := alTop;
    WebLabel1.Alignment := taCenter;
    WebLabel1.AutoSize := False;
    WebLabel1.Color := clBtnFace;
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clRed;
    WebLabel1.Font.Height := -27;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightStyle := ssAuto;
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.Layout := tlCenter;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    panFooter.SetParentComponent(Self);
    panFooter.Name := 'panFooter';
    panFooter.Left := 0;
    panFooter.Top := 656;
    panFooter.Width := 644;
    panFooter.Height := 120;
    panFooter.Align := alBottom;
    panFooter.BorderStyle := bsNone;
    panFooter.ChildOrder := 3;
    panFooter.Color := clWhite;
    panFooter.ElementFont := efCSS;
    panFooter.Padding.Top := 5;
    panFooter.TabOrder := 0;
    btnOk.SetParentComponent(panFooter);
    btnOk.Name := 'btnOk';
    btnOk.AlignWithMargins := True;
    btnOk.Left := 469;
    btnOk.Top := 10;
    btnOk.Width := 165;
    btnOk.Height := 107;
    btnOk.Margins.Top := 5;
    btnOk.Margins.Right := 10;
    btnOk.Align := alRight;
    btnOk.Caption := '<i class="material-icons" style="font-size:40px;">done</i>';
    btnOk.ChildOrder := 1;
    btnOk.ElementClassName := 'btn btn-success';
    btnOk.ElementFont := efCSS;
    btnOk.HeightPercent := 100.000000000000000000;
    btnOk.WidthPercent := 100.000000000000000000;
    SetEvent(btnOk, Self, 'OnClick', 'btnOkClick');
    btnCancel.SetParentComponent(panFooter);
    btnCancel.Name := 'btnCancel';
    btnCancel.AlignWithMargins := True;
    btnCancel.Left := 10;
    btnCancel.Top := 8;
    btnCancel.Width := 165;
    btnCancel.Height := 109;
    btnCancel.Margins.Left := 10;
    btnCancel.Margins.Right := 10;
    btnCancel.Align := alLeft;
    btnCancel.Caption := '<i class="material-icons" style="font-size:40px;">keyboard_backspace</i>';
    btnCancel.ChildOrder := 1;
    btnCancel.ElementClassName := 'btn btn-danger';
    btnCancel.ElementFont := efCSS;
    btnCancel.HeightPercent := 100.000000000000000000;
    btnCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCancel, Self, 'OnClick', 'btnCancelClick');
  finally
    lblTitle.AfterLoadDFMValues;
    lblMsg.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    panFooter.AfterLoadDFMValues;
    btnOk.AfterLoadDFMValues;
    btnCancel.AfterLoadDFMValues;
  end;
end;

end.
