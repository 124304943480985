unit Zgodovina;

interface

uses
  System.SysUtils, System.Variants, System.Classes, Web, System.Dateutils, Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.ExtCtrls, Data.DB, WEBLib.DB, XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.Controls, WEBLib.Grids, WEBLib.DBCtrls, WEBLib.Forms,
  XData.Web.Connection, Vcl.Grids, WEBLib.Buttons, WEBLib.WebCtrls, TerminalForm;

type
  TfrmZgodovina = class(TTerminalForm)
    tmrFind: TTimer;
    dsOdvoz: TDataSource;
    panHeader: TPanel;
    edtFilter: TEdit;
    panFooter: TPanel;
    btnSelect: TButton;
    gridView: TDBGrid;
    btnLocate2: TButton;
    lblIsci: TLabel;
    wdsOdvoz: TXDataWebDataSet;
    btnDan: TButton;
    btnDelNote: TButton;
    lblTitle: TLabel;
    procedure tmrFindTimer(Sender: TObject);
    procedure edtFilterChange(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure wdsPotPodrobnoAfterOpen(DataSet: TDataSet);
    procedure WebFormShow(Sender: TObject);
    procedure btnDelNoteClick(Sender: TObject);
    procedure wdsPotPodrobnoAfterScroll(DataSet: TDataSet);
    procedure mapMainMarkerClick(Sender: TObject; AIndex: Integer; AMarker: TJSObjectRecord);
    procedure ProcesirajLokacijo;
    procedure GetOdvozInfo(Sender: TObject);
    procedure btnLocate2Click(Sender: TObject);
    procedure btnDanClick(Sender: TObject);  // Klic pride iz frmMain
  private
    { Private declarations }
    Starting: boolean;
  //  Fmt, FmtDisp: TFormatSettings;
    datToday: string;
    datTomorrow: string;

    procedure ToggleButtons;
    procedure VrniLokacije(Flt, Srt: string);
    procedure ApplyTheme(Dark: boolean); reintroduce;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses Client.Core, Main, Client.Settings, Client.Constants;

procedure TfrmZgodovina.ApplyTheme(Dark: boolean);
begin
  inherited;

end;

procedure TfrmZgodovina.btnDanClick(Sender: TObject);
begin
  frmMain.ShowKoledar(knZgodovina, 'Izberi dan za kontrolo');
end;

procedure TfrmZgodovina.btnDelNoteClick(Sender: TObject);
begin
  edtFilter.Clear;
  edtFilter.SetFocus;
  tmrFindTimer(Sender)
end;

procedure TfrmZgodovina.ToggleButtons;
begin
  btnSelect.Enabled := wdsOdvoz.RecordCount > 0;
end;

procedure TfrmZgodovina.btnLocate2Click(Sender: TObject);
begin
  frmMain.ShowLokacije;
end;

procedure TfrmZgodovina.edtFilterChange(Sender: TObject);
begin
  tmrFind.Enabled := false;
  tmrFind.Enabled := true;
end;

procedure TfrmZgodovina.GetOdvozInfo(Sender: TObject);
begin
  if wdsOdvoz.RecordCount<1 then
    exit;

  // Vrni zadnji odvoz za lokacijo in pot
  modMain.NastaviLokacijo(wdsOdvoz.FieldByName('Lokacija.Id').AsInteger);
  modMain.NastaviPot(wdsOdvoz.FieldByName('PotId').AsInteger);
  modMain.OdvozId := wdsOdvoz.FieldByName('Id').AsInteger;

  modMain.OdvozLastRow := wdsOdvoz.RecNo;

  frmMain.ShowOdvozInfo;
end;

procedure TfrmZgodovina.tmrFindTimer(Sender: TObject);
var Flt, Srt: string;
    F: TStringList;

  function Filter(FL: TStringList): string;
  var I: integer;
  begin
    Result := '';
    for I := 0 to FL.Count-1 do begin
      if I > 0 then
        Result := Result + ' and ';

      Result := Result + FL[I];
    end;
  end;

begin
  tmrFind.Enabled := false;

  Flt := '';
  Srt := 'CasOdvoza desc';

  F := TStringList.Create;
  F.Add('lokacija/aktivna eq 1');

  if edtFilter.Text<>'' then begin
    F.Add('(contains(lokacija/stranka/naziv,%27'+edtFilter.Text+'%27) or contains(lokacija/naziv,%27'+edtFilter.Text+'%27) or contains(lokacija/opombe,%27'+edtFilter.Text+'%27))');
//    Srt := 'lokacija/stranka/naziv,lokacija/naziv';
  end;
  Flt := Filter(F);
  F.Free;

  modMain.Log(Flt);

  VrniLokacije(Flt, Srt);
  ToggleButtons;
end;

// Klic pride iz frmMain
procedure TfrmZgodovina.ProcesirajLokacijo;
begin
  if not AppSettings.LocationEnabled then
    exit;

  modMain.Log('TfrmZgodovina.Executing ProcesirajLokacijo');

  tmrFindTimer(nil);
end;

procedure TfrmZgodovina.mapMainMarkerClick(Sender: TObject; AIndex: Integer;
  AMarker: TJSObjectRecord);
begin
  if (AIndex>0) and (AIndex < wdsOdvoz.RecordCount) then begin
    modMain.Log('Settings RecNo...');
    wdsOdvoz.RecNo := AIndex;
  end;
end;

procedure TfrmZgodovina.VrniLokacije(Flt, Srt: string);
var Qry: string;
begin
  if Flt<>'' then
    Flt := Flt + ' and ';

  Flt := Flt + 'vozilo/id eq '+modMain.VoziloId.ToString+' and CasOdvoza gt '+datToday+' and CasOdvoza lt '+datTomorrow+'&$orderby=CasOdvoza desc&$expand=lokacija,lokacija/stranka';

//  http://localhost:2001/kamijoncin/Odvoz?$filter=lokacija/aktivna%20eq%201%20and%20lokacija/aktivna%20eq%201%20and%20vozilo/id%20eq%205%20and%20CasOdvoza%20gt%202024-01-01%20and%20Voznik/Id%20eq%209&$orderby=CasOdvoza%20desc&$expand=lokacija,lokacija/stranka
  Qry := '$filter='+Flt+'&$orderby='+Srt+'&$expand=lokacija,lokacija/stranka';

  modMain.Log('Qry='+Qry);

  wdsOdvoz.Close;
  wdsOdvoz.QueryString := Qry;
  wdsOdvoz.Load;
end;

procedure TfrmZgodovina.wdsPotPodrobnoAfterOpen(DataSet: TDataSet);
begin
  btnSelect.Enabled := wdsOdvoz.RecordCount > 0;
  Starting := false;
end;

procedure TfrmZgodovina.wdsPotPodrobnoAfterScroll(DataSet: TDataSet);
begin
  modMain.SetVar('Odvoz.Selected', wdsOdvoz.FieldByName('Id').AsString);
end;

procedure TfrmZgodovina.WebFormCreate(Sender: TObject);
begin
  inherited;

  Starting := true;
//  Fmt := TFormatSettings.Create('en-us');
//  Fmt.ShortDateFormat := 'yyyy-mm-dd';

//  FmtDisp := TFormatSettings.Create('en-us');
//  FmtDisp.ShortDateFormat := 'dd.mm.yyyy';

  datToday := DateToStr(modMain.ZgodovinaDatum, Fmt);
  datTomorrow := DateToStr(IncDay(modMain.ZgodovinaDatum, 1), Fmt);
  modMain.Log(datToday);

  lblTitle.Caption := 'Odvozi za '+DateToStr(modMain.ZgodovinaDatum, FmtDisp);
  tmrFindTimer(nil);

  modMain.Log('Fmt='+Fmt.ShortDateFormat);
  ApplyTheme(AppSettings.DarkMode);
end;

procedure TfrmZgodovina.WebFormShow(Sender: TObject);
begin
  inherited;

  gridView.ColWidths[0] := Round(gridView.Width * 0.5)-2;
  gridView.ColWidths[1] := Round(gridView.Width * 0.5)-2;
end;

procedure TfrmZgodovina.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lblTitle := TLabel.Create(Self);
  panHeader := TPanel.Create(Self);
  lblIsci := TLabel.Create(Self);
  edtFilter := TEdit.Create(Self);
  btnDelNote := TButton.Create(Self);
  panFooter := TPanel.Create(Self);
  btnSelect := TButton.Create(Self);
  btnLocate2 := TButton.Create(Self);
  btnDan := TButton.Create(Self);
  gridView := TDBGrid.Create(Self);
  tmrFind := TTimer.Create(Self);
  dsOdvoz := TDataSource.Create(Self);
  wdsOdvoz := TXDataWebDataSet.Create(Self);

  lblTitle.BeforeLoadDFMValues;
  panHeader.BeforeLoadDFMValues;
  lblIsci.BeforeLoadDFMValues;
  edtFilter.BeforeLoadDFMValues;
  btnDelNote.BeforeLoadDFMValues;
  panFooter.BeforeLoadDFMValues;
  btnSelect.BeforeLoadDFMValues;
  btnLocate2.BeforeLoadDFMValues;
  btnDan.BeforeLoadDFMValues;
  gridView.BeforeLoadDFMValues;
  tmrFind.BeforeLoadDFMValues;
  dsOdvoz.BeforeLoadDFMValues;
  wdsOdvoz.BeforeLoadDFMValues;
  try
    Name := 'frmZgodovina';
    Left := 0;
    Top := 0;
    Align := alClient;
    Color := clBtnFace;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    lblTitle.SetParentComponent(Self);
    lblTitle.Name := 'lblTitle';
    lblTitle.Left := 0;
    lblTitle.Top := 0;
    lblTitle.Width := 831;
    lblTitle.Height := 79;
    lblTitle.Align := alTop;
    lblTitle.AutoSize := False;
    lblTitle.Caption := 'Odvozi za dan';
    lblTitle.Color := clBtnFace;
    lblTitle.ElementClassName := 'display-2';
    lblTitle.ElementFont := efCSS;
    lblTitle.Font.Charset := DEFAULT_CHARSET;
    lblTitle.Font.Color := clWindowText;
    lblTitle.Font.Height := -21;
    lblTitle.Font.Name := 'Tahoma';
    lblTitle.Font.Style := [];
    lblTitle.HeightStyle := ssAuto;
    lblTitle.HeightPercent := 100.000000000000000000;
    lblTitle.Layout := tlCenter;
    lblTitle.ParentFont := False;
    lblTitle.WidthPercent := 100.000000000000000000;
    panHeader.SetParentComponent(Self);
    panHeader.Name := 'panHeader';
    panHeader.Left := 0;
    panHeader.Top := 79;
    panHeader.Width := 831;
    panHeader.Height := 91;
    panHeader.Align := alTop;
    panHeader.BorderStyle := bsNone;
    panHeader.ChildOrder := 3;
    panHeader.Color := clWhite;
    panHeader.ElementBodyClassName := 'text';
    panHeader.Padding.Left := 5;
    panHeader.Padding.Top := 5;
    panHeader.Padding.Right := 5;
    panHeader.Padding.Bottom := 5;
    panHeader.TabOrder := 1;
    lblIsci.SetParentComponent(panHeader);
    lblIsci.Name := 'lblIsci';
    lblIsci.Left := 5;
    lblIsci.Top := 5;
    lblIsci.Width := 60;
    lblIsci.Height := 81;
    lblIsci.Align := alLeft;
    lblIsci.AutoSize := False;
    lblIsci.Caption := 'I'#353#269'i';
    lblIsci.ElementClassName := 'fs-1';
    lblIsci.ElementFont := efCSS;
    lblIsci.Font.Charset := DEFAULT_CHARSET;
    lblIsci.Font.Color := clWindowText;
    lblIsci.Font.Height := -21;
    lblIsci.Font.Name := 'Tahoma';
    lblIsci.Font.Style := [];
    lblIsci.HeightPercent := 100.000000000000000000;
    lblIsci.Layout := tlCenter;
    lblIsci.ParentFont := False;
    lblIsci.WidthPercent := 100.000000000000000000;
    edtFilter.SetParentComponent(panHeader);
    edtFilter.Name := 'edtFilter';
    edtFilter.AlignWithMargins := True;
    edtFilter.Left := 68;
    edtFilter.Top := 10;
    edtFilter.Width := 662;
    edtFilter.Height := 71;
    edtFilter.Margins.Top := 5;
    edtFilter.Margins.Bottom := 5;
    edtFilter.Align := alClient;
    edtFilter.CharCase := wecUpperCase;
    edtFilter.ChildOrder := 3;
    edtFilter.ElementClassName := 'fs-1';
    edtFilter.ElementFont := efCSS;
    edtFilter.HeightPercent := 100.000000000000000000;
    edtFilter.RequiredText := 'I'#353#269'i...';
    edtFilter.WidthPercent := 100.000000000000000000;
    SetEvent(edtFilter, Self, 'OnChange', 'edtFilterChange');
    btnDelNote.SetParentComponent(panHeader);
    btnDelNote.Name := 'btnDelNote';
    btnDelNote.AlignWithMargins := True;
    btnDelNote.Left := 736;
    btnDelNote.Top := 8;
    btnDelNote.Width := 80;
    btnDelNote.Height := 75;
    btnDelNote.Margins.Right := 10;
    btnDelNote.Align := alRight;
    btnDelNote.Caption := '<i class="material-icons">backspace</i>';
    btnDelNote.ChildOrder := 2;
    btnDelNote.ElementClassName := 'btn btn-xxl btn-primary';
    btnDelNote.ElementFont := efCSS;
    btnDelNote.HeightPercent := 100.000000000000000000;
    btnDelNote.WidthPercent := 100.000000000000000000;
    SetEvent(btnDelNote, Self, 'OnClick', 'btnDelNoteClick');
    panFooter.SetParentComponent(Self);
    panFooter.Name := 'panFooter';
    panFooter.Left := 0;
    panFooter.Top := 706;
    panFooter.Width := 831;
    panFooter.Height := 120;
    panFooter.Align := alBottom;
    panFooter.BorderStyle := bsNone;
    panFooter.ChildOrder := 3;
    panFooter.Color := clWhite;
    panFooter.Padding.Top := 5;
    panFooter.TabOrder := 2;
    btnSelect.SetParentComponent(panFooter);
    btnSelect.Name := 'btnSelect';
    btnSelect.AlignWithMargins := True;
    btnSelect.Left := 656;
    btnSelect.Top := 10;
    btnSelect.Width := 165;
    btnSelect.Height := 107;
    btnSelect.Margins.Top := 5;
    btnSelect.Margins.Right := 10;
    btnSelect.Align := alRight;
    btnSelect.Caption := '<i class="material-icons" style="font-size:40px;">done</i>';
    btnSelect.ChildOrder := 1;
    btnSelect.ElementClassName := 'btn btn-xxl btn-success';
    btnSelect.ElementFont := efCSS;
    btnSelect.Enabled := False;
    btnSelect.HeightPercent := 100.000000000000000000;
    btnSelect.WidthPercent := 100.000000000000000000;
    SetEvent(btnSelect, Self, 'OnClick', 'GetOdvozInfo');
    btnLocate2.SetParentComponent(panFooter);
    btnLocate2.Name := 'btnLocate2';
    btnLocate2.AlignWithMargins := True;
    btnLocate2.Left := 10;
    btnLocate2.Top := 8;
    btnLocate2.Width := 102;
    btnLocate2.Height := 109;
    btnLocate2.Margins.Left := 10;
    btnLocate2.Margins.Right := 10;
    btnLocate2.Align := alLeft;
    btnLocate2.Caption := '<i class="material-icons" style="font-size:40px;">keyboard_backspace</i>';
    btnLocate2.ChildOrder := 1;
    btnLocate2.ElementClassName := 'btn btn-danger';
    btnLocate2.ElementFont := efCSS;
    btnLocate2.HeightPercent := 100.000000000000000000;
    btnLocate2.WidthPercent := 100.000000000000000000;
    SetEvent(btnLocate2, Self, 'OnClick', 'btnLocate2Click');
    btnDan.SetParentComponent(panFooter);
    btnDan.Name := 'btnDan';
    btnDan.AlignWithMargins := True;
    btnDan.Left := 132;
    btnDan.Top := 8;
    btnDan.Width := 111;
    btnDan.Height := 109;
    btnDan.Margins.Left := 10;
    btnDan.Margins.Right := 10;
    btnDan.Align := alLeft;
    btnDan.Caption := '<i class="material-icons" style="font-size:40px;">calendar_month</i>';
    btnDan.ChildOrder := 1;
    btnDan.ElementClassName := 'btn btn-warning';
    btnDan.ElementFont := efCSS;
    btnDan.HeightPercent := 100.000000000000000000;
    btnDan.WidthPercent := 100.000000000000000000;
    SetEvent(btnDan, Self, 'OnClick', 'btnDanClick');
    gridView.SetParentComponent(Self);
    gridView.Name := 'gridView';
    gridView.Left := 0;
    gridView.Top := 170;
    gridView.Width := 831;
    gridView.Height := 536;
    gridView.Align := alClient;
    gridView.Color := clWhite;
    gridView.Columns.Clear;
    with gridView.Columns.Add do
    begin
      ElementClassName := 'text-wrap';
      DataField := 'Lokacija.Stranka.Naziv';
      Title := 'Stranka';
      Width := 340;
    end;
    with gridView.Columns.Add do
    begin
      ElementClassName := 'text-wrap';
      DataField := 'Lokacija.Naziv';
      Title := 'Lokacija';
      Width := 340;
    end;
    gridView.DataSource := dsOdvoz;
    gridView.ElementFont := efCSS;
    gridView.ElementClassName := 'fs-2';
    gridView.FixedFont.Charset := DEFAULT_CHARSET;
    gridView.FixedFont.Color := clWindowText;
    gridView.FixedFont.Height := -12;
    gridView.FixedFont.Name := 'Segoe UI';
    gridView.FixedFont.Style := [];
    gridView.FixedCols := 0;
    gridView.FixedRows := 0;
    gridView.Font.Charset := DEFAULT_CHARSET;
    gridView.Font.Color := clWindowText;
    gridView.Font.Height := -48;
    gridView.Font.Name := 'Tahoma';
    gridView.Font.Style := [];
    gridView.Options := [goHorzLine,goRowSelect];
    gridView.ParentFont := False;
    gridView.TabOrder := 0;
    gridView.HeightPercent := 100.000000000000000000;
    gridView.ShowIndicator := False;
    gridView.WidthPercent := 100.000000000000000000;
    SetEvent(gridView, Self, 'OnDblClick', 'GetOdvozInfo');
    gridView.ColWidths[0] := 340;
    gridView.ColWidths[1] := 340;
    tmrFind.SetParentComponent(Self);
    tmrFind.Name := 'tmrFind';
    tmrFind.Enabled := False;
    tmrFind.Interval := 500;
    SetEvent(tmrFind, Self, 'OnTimer', 'tmrFindTimer');
    tmrFind.Left := 92;
    tmrFind.Top := 320;
    dsOdvoz.SetParentComponent(Self);
    dsOdvoz.Name := 'dsOdvoz';
    dsOdvoz.DataSet := wdsOdvoz;
    dsOdvoz.Left := 96;
    dsOdvoz.Top := 264;
    wdsOdvoz.SetParentComponent(Self);
    wdsOdvoz.Name := 'wdsOdvoz';
    wdsOdvoz.AfterOpen := wdsPotPodrobnoAfterOpen;
    wdsOdvoz.AfterScroll := wdsPotPodrobnoAfterScroll;
    wdsOdvoz.EntitySetName := 'Odvoz';
    wdsOdvoz.Connection := modMain.connServer;
    wdsOdvoz.SubpropsDepth := 2;
    wdsOdvoz.Left := 96;
    wdsOdvoz.Top := 208;
  finally
    lblTitle.AfterLoadDFMValues;
    panHeader.AfterLoadDFMValues;
    lblIsci.AfterLoadDFMValues;
    edtFilter.AfterLoadDFMValues;
    btnDelNote.AfterLoadDFMValues;
    panFooter.AfterLoadDFMValues;
    btnSelect.AfterLoadDFMValues;
    btnLocate2.AfterLoadDFMValues;
    btnDan.AfterLoadDFMValues;
    gridView.AfterLoadDFMValues;
    tmrFind.AfterLoadDFMValues;
    dsOdvoz.AfterLoadDFMValues;
    wdsOdvoz.AfterLoadDFMValues;
  end;
end;

end.