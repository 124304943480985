unit Main;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Data.DB, XData.Web.JsonDataset, TerminalForm,
  XData.Web.Dataset, XData.Web.Connection, WEBLib.DB, Vcl.Controls,
  Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.DBCtrls, WEBLib.EditAutocomplete,
  WEBLib.Grids, WEBLib.ExtCtrls, WEBLib.JQCtrls, WEBLib.JQGrid, Vcl.Forms,
  Vcl.Imaging.pngimage, XData.Web.Client, Client.Constants;

type
  TfrmMain = class(TForm)
    panHeader: TPanel;
    panFooter: TPanel;
    btnLogout: TButton;
    lblTitle: TLabel;
    panMain: TPanel;
    imgLogo: TImageControl;
    lblVoznik: TLabel;
    lblVozilo: TLabel;
    lblFrakcija: TLabel;
    lblDan: TLabel;
    procedure WebFormCreate(Sender: TObject);
    procedure btnLogoutClick(Sender: TObject);
    procedure imgLogoDblClick(Sender: TObject);
    procedure lblDanClick(Sender: TObject);
    procedure lblTitleClick(Sender: TObject);
  private
    { Private declarations }
    procedure ClearForm;
  public
    { Public declarations }
    frm: TTerminalForm;
    FormPrevious: string;
    FormCurrent: string;

    procedure ApplyTheme(Dark: boolean);

    procedure ShowPrevious;

    procedure ShowLogin;
    procedure ShowLokacije;
    procedure ShowZemljevid;
    procedure ShowOdvoz;
    procedure ShowOdvozInfo;
    procedure ShowFrakcija;
    procedure ShowVozila;
    procedure ShowMenjava;
    procedure ShowKoledar(Nacin: TKoledarNacin; Naslov: string);
    procedure ShowSettings;
    procedure ShowZgodovina;
    procedure ShowKilometri(Namen: TNamen);
    procedure ShowGorivo;
    procedure ShowPotrditev(Naslov, Tekst: string);

    procedure ToggleLogoutBtn(Enabled: boolean);
    procedure SetVoznikLabel(Txt: string);
    procedure SetVoziloLabel(Txt: string);
    procedure SetFrakcijaLabel(Txt: string);
    procedure SetDanLabel(DanIme: string; Datum: TDate);

    procedure PrikazBaterije;
    procedure ProcessLokacija;
  protected procedure LoadDFMValues; override; end;

var
  frmMain: TfrmMain;

implementation

{$R *.dfm}

uses Login, Client.Core, Client.Settings, Lokacija, Odvoz, Frakcija, Vozilo, VoziloMenjava,
     Settings, Zemljevid, Auth.Service, OdvozInfo, Zgodovina, Datum, Kilometri, Gorivo, Potrditev;

procedure TfrmMain.ApplyTheme(Dark: boolean);
begin
  modMain.Log('ApplyTheme main');
  if Dark then begin
    self.SetColorScheme(csDark);
    panMain.Color := $00191919;
    panMain.Font.Color := clWhite;
    panHeader.Color := $002C2C2C;
  end else begin
    self.SetColorScheme(csLight);
    panMain.Color := clWhite;
    panMain.Font.Color := clBlack;
    panHeader.Color := clBlack;
  end;
  panFooter.Color := panHeader.Color;
end;

procedure TfrmMain.btnLogoutClick(Sender: TObject);
begin
  ShowKilometri(kmOdjava);
//    ShowLogin;
end;

procedure TfrmMain.ClearForm;
begin
  if Assigned(frm) then begin
//    modMain.SetVar('Form.Previous', modMain.GetVar('Form.Current'));
//    modMain.SetVar('Form.Current', frm.ClassName);
//    FormPrevious := FormCurrent;
    FormPrevious := frm.ClassName;

    modMain.Log('FormPrevious='+FormPrevious);
    frm.Close;
    frm := nil;
  end;
end;

procedure TfrmMain.imgLogoDblClick(Sender: TObject);
begin
  if frm is TfrmLokacija then
    ShowSettings;
end;

procedure TfrmMain.lblDanClick(Sender: TObject);
begin
//  if AppSettings.DevMode then
//    frmMain.ShowZemljevid;
end;

procedure TfrmMain.PrikazBaterije;
//var level: string;
begin
(*  asm
    navigator.getBattery().then(function(battery) {
      battery.addEventListener('levelchange', function() {
        // Do stuff when the level changes, you can get it
        // from battery.level
        document.write((battery.level*100)+"%");
      })
//      document.write((battery.level*100)+"%");
      (@level) = (battery.level*100)+"%";
    });
  end; *)
//  showmessage(level);
end;

procedure TfrmMain.ProcessLokacija;
begin
//  Log('Executing ProcessLokacija');

  try
    if frm is TfrmLokacija then begin
      TfrmLokacija(frm).ProcesirajLokacijo;
    end;
  except
    on E:Exception do begin
      modMain.Log('(ProcessLokacija) '+E.Message);
    end;
  end;
end;

procedure TfrmMain.SetVoznikLabel(Txt: string);
begin
  lblVoznik.Caption := Txt;
end;

procedure TfrmMain.SetVoziloLabel(Txt: string);
begin
  lblVozilo.Caption := Txt;
end;

procedure TfrmMain.SetDanLabel(DanIme: string; Datum: TDate);
begin
  lblDan.Caption := DanIme+', '+FormatDateTime('dd.mm.yyyy', Datum);
end;

procedure TfrmMain.SetFrakcijaLabel(Txt: string);
begin
  lblFrakcija.Caption := Txt+' ';
end;

procedure TfrmMain.ShowLogin;
begin
  ClearForm;
  SetVoziloLabel('');
  SetVoznikLabel('');
  SetFrakcijaLabel('');
  modMain.UstaviSledenje;
  ToggleLogoutBtn(false);
  frm :=  TfrmLogin.CreateNew(panMain.ElementID, nil);
end;

procedure TfrmMain.ShowMenjava;
begin
  ClearForm;
  frm :=  TfrmMenjava.CreateNew(panMain.ElementID, nil);
end;

procedure TfrmMain.ShowLokacije;
begin
  ClearForm;
  ToggleLogoutBtn(true);
  frm :=  TfrmLokacija.CreateNew(panMain.ElementID, nil);
end;

procedure TfrmMain.ToggleLogoutBtn(Enabled: boolean);
begin
  btnLogout.Visible := Enabled;
end;

procedure TfrmMain.ShowOdvoz;
begin
  ClearForm;
  frm :=  TfrmOdvoz.CreateNew(panMain.ElementID, nil);
end;

procedure TfrmMain.ShowOdvozInfo;
begin
  ClearForm;
  frm :=  TfrmOdvozInfo.CreateNew(panMain.ElementID, nil);
end;

procedure TfrmMain.ShowPrevious;
begin
  //if FormPrevious = TfrmDatum.ClassName then begin
//    ShowKoledar;
  //end else
  if FormPrevious=TfrmFrakcija.ClassName then begin
    ShowFrakcija;
  end else if FormPrevious=TfrmLokacija.ClassName then begin
    ShowLokacije;
  end else if FormPrevious=TfrmVozilo.ClassName then begin
    ShowVozila
  end else if FormPrevious=TfrmMenjava.ClassName then begin
    ShowMenjava;
  end else if FormPrevious=TfrmSettings.ClassName then begin
    ShowSettings;
  end else if FormPrevious=TfrmOdvoz.ClassName then begin
    ShowOdvoz;
  end else if FormPrevious=TfrmOdvozInfo.ClassName then begin
    ShowOdvozInfo;
  end else if FormPrevious=TfrmZemljevid.ClassName then begin
    ShowZemljevid;
  end else if FormPrevious=TfrmZgodovina.ClassName then begin
    ShowZgodovina;
  end else begin
    ShowLogin;
  end;
end;

procedure TfrmMain.ShowSettings;
begin
  ClearForm;
  frm :=  TfrmSettings.CreateNew(panMain.ElementID, nil);
end;

procedure TfrmMain.ShowFrakcija;
begin
  ClearForm;
  frm :=  TfrmFrakcija.CreateNew(panMain.ElementID, nil);
end;

procedure TfrmMain.ShowKoledar(Nacin: TKoledarNacin; Naslov: string);
  procedure AfterCreate(AForm: TObject);
  begin
    (frm as TfrmDatum).Naslov := Naslov;
  end;
begin
  ClearForm;
  frm := TfrmDatum.CreateNew(panMain.ElementID, @AfterCreate);
  TfrmDatum(frm).Nacin := Nacin;
end;

procedure TfrmMain.ShowVozila;
begin
  ClearForm;
  frm :=  TfrmVozilo.CreateNew(panMain.ElementID, nil);
end;

procedure TfrmMain.ShowZemljevid;
begin
  ClearForm;
  frm :=  TfrmZemljevid.CreateNew(panMain.ElementID, nil);
end;

procedure TfrmMain.ShowZgodovina;
begin
  ClearForm;
  frm :=  TfrmZgodovina.CreateNew(panMain.ElementID, nil);
end;

procedure TfrmMain.ShowKilometri(Namen: TNamen);
  procedure AfterCreate(AForm: TObject);
  begin
    (frm as TfrmKilometri).FNamen := Namen;
  end;
begin
  ClearForm;
  ToggleLogoutBtn(true);
  frm :=  TfrmKilometri.CreateNew(panMain.ElementID, @AfterCreate);
end;

procedure TfrmMain.ShowGorivo;
  procedure AfterCreate(AForm: TObject);
  begin
  end;
begin
  ClearForm;
  ToggleLogoutBtn(true);
  frm :=  TfrmGorivo.CreateNew(panMain.ElementID, @AfterCreate);
end;

procedure TfrmMain.ShowPotrditev(Naslov, Tekst: string);
  procedure AfterCreate(AForm: TObject);
  begin
    TfrmPotrditev(AForm).Naslov := Naslov;
    TfrmPotrditev(AForm).Tekst := Tekst;
  end;

begin
  ClearForm;
  ToggleLogoutBtn(false);
  frm := TfrmPotrditev.CreateNew(panMain.ElementID, @AfterCreate);
end;

procedure TfrmMain.WebFormCreate(Sender: TObject);

  procedure LoginSuccess;
  begin
    //FLoginProc;
  end;

  procedure LoginError(AMsg: string);
  begin
    //ShowNotification('Login Error: ' + AMsg);
  end;

begin
  Application.ErrorType := aeSilent;

  ApplyTheme(AppSettings.DarkMode);

  Frm := nil;
  ShowLogin;
end;

procedure TfrmMain.lblTitleClick(Sender: TObject);
begin
//  PrikazBaterije;
end;

procedure TfrmMain.LoadDFMValues;
begin
  inherited LoadDFMValues;

  panHeader := TPanel.Create(Self);
  lblTitle := TLabel.Create(Self);
  imgLogo := TImageControl.Create('imgLogo');
  lblDan := TLabel.Create(Self);
  btnLogout := TButton.Create(Self);
  panFooter := TPanel.Create(Self);
  lblVoznik := TLabel.Create(Self);
  lblVozilo := TLabel.Create(Self);
  lblFrakcija := TLabel.Create(Self);
  panMain := TPanel.Create(Self);

  panHeader.BeforeLoadDFMValues;
  lblTitle.BeforeLoadDFMValues;
  imgLogo.BeforeLoadDFMValues;
  lblDan.BeforeLoadDFMValues;
  btnLogout.BeforeLoadDFMValues;
  panFooter.BeforeLoadDFMValues;
  lblVoznik.BeforeLoadDFMValues;
  lblVozilo.BeforeLoadDFMValues;
  lblFrakcija.BeforeLoadDFMValues;
  panMain.BeforeLoadDFMValues;
  try
    Name := 'frmMain';
    Width := 668;
    Height := 761;
    Align := alClient;
    Caption := 'Kamijon'#269'in';
    CSSLibrary := cssBootstrap;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    panHeader.SetParentComponent(Self);
    panHeader.Name := 'panHeader';
    panHeader.Left := 0;
    panHeader.Top := 0;
    panHeader.Width := 668;
    panHeader.Height := 70;
    panHeader.Align := alTop;
    panHeader.BorderStyle := bsNone;
    panHeader.ChildOrder := 3;
    panHeader.Color := clBlack;
    panHeader.Padding.Left := 1;
    panHeader.Padding.Top := 1;
    panHeader.Padding.Right := 1;
    panHeader.Padding.Bottom := 1;
    panHeader.TabOrder := 0;
    lblTitle.SetParentComponent(panHeader);
    lblTitle.Name := 'lblTitle';
    lblTitle.AlignWithMargins := True;
    lblTitle.Left := 66;
    lblTitle.Top := 4;
    lblTitle.Width := 132;
    lblTitle.Height := 62;
    lblTitle.Margins.Left := 10;
    lblTitle.Align := alLeft;
    lblTitle.Anchors := [akLeft,akTop,akRight,akBottom];
    lblTitle.Caption := 'Kamijon'#269'in';
    lblTitle.ElementClassName := 'h2 text-light';
    lblTitle.ElementFont := efCSS;
    lblTitle.Font.Charset := DEFAULT_CHARSET;
    lblTitle.Font.Color := clWhite;
    lblTitle.Font.Height := -27;
    lblTitle.Font.Name := 'Arial';
    lblTitle.Font.Style := [];
    lblTitle.HeightPercent := 100.000000000000000000;
    lblTitle.Layout := tlCenter;
    lblTitle.ParentFont := False;
    lblTitle.WidthPercent := 100.000000000000000000;
    SetEvent(lblTitle, Self, 'OnClick', 'lblTitleClick');
    imgLogo.SetParentComponent(panHeader);
    imgLogo.Name := 'imgLogo';
    imgLogo.AlignWithMargins := True;
    imgLogo.Left := 4;
    imgLogo.Top := 4;
    imgLogo.Width := 49;
    imgLogo.Height := 62;
    imgLogo.ElementClassName := 'transparent';
    imgLogo.HeightPercent := 100.000000000000000000;
    imgLogo.WidthPercent := 100.000000000000000000;
    imgLogo.Align := alLeft;
    imgLogo.ChildOrder := 5;
    SetEvent(imgLogo, Self, 'OnDblClick', 'imgLogoDblClick');
    imgLogo.Picture.LoadFromFile('Main.panHeader.imgLogo.Picture.png');
    lblDan.SetParentComponent(panHeader);
    lblDan.Name := 'lblDan';
    lblDan.AlignWithMargins := True;
    lblDan.Left := 211;
    lblDan.Top := 4;
    lblDan.Width := 390;
    lblDan.Height := 62;
    lblDan.Margins.Left := 10;
    lblDan.Align := alClient;
    lblDan.Alignment := taRightJustify;
    lblDan.AutoSize := False;
    lblDan.ElementClassName := 'h3 text-light';
    lblDan.ElementFont := efCSS;
    lblDan.Font.Charset := DEFAULT_CHARSET;
    lblDan.Font.Color := clWhite;
    lblDan.Font.Height := -21;
    lblDan.Font.Name := 'Tahoma';
    lblDan.Font.Style := [];
    lblDan.HeightPercent := 100.000000000000000000;
    lblDan.Layout := tlCenter;
    lblDan.ParentFont := False;
    lblDan.WidthPercent := 100.000000000000000000;
    SetEvent(lblDan, Self, 'OnClick', 'lblDanClick');
    btnLogout.SetParentComponent(panHeader);
    btnLogout.Name := 'btnLogout';
    btnLogout.Left := 604;
    btnLogout.Top := 1;
    btnLogout.Width := 63;
    btnLogout.Height := 68;
    btnLogout.Align := alRight;
    btnLogout.Caption := '<i class="material-icons">logout</i>';
    btnLogout.ElementClassName := 'btn btn-danger';
    btnLogout.ElementFont := efCSS;
    btnLogout.Font.Charset := DEFAULT_CHARSET;
    btnLogout.Font.Color := clWindowText;
    btnLogout.Font.Height := -21;
    btnLogout.Font.Name := 'Tahoma';
    btnLogout.Font.Style := [];
    btnLogout.HeightPercent := 100.000000000000000000;
    btnLogout.ParentFont := False;
    btnLogout.Visible := False;
    btnLogout.WidthPercent := 100.000000000000000000;
    SetEvent(btnLogout, Self, 'OnClick', 'btnLogoutClick');
    panFooter.SetParentComponent(Self);
    panFooter.Name := 'panFooter';
    panFooter.Left := 0;
    panFooter.Top := 716;
    panFooter.Width := 668;
    panFooter.Height := 45;
    panFooter.Align := alBottom;
    panFooter.BorderColor := clBlack;
    panFooter.BorderStyle := bsNone;
    panFooter.ChildOrder := 3;
    panFooter.Color := clBlack;
    panFooter.ElementFont := efCSS;
    panFooter.Padding.Left := 1;
    panFooter.Padding.Top := 1;
    panFooter.Padding.Right := 1;
    panFooter.Padding.Bottom := 1;
    panFooter.ShowCaption := False;
    panFooter.TabOrder := 1;
    lblVoznik.SetParentComponent(panFooter);
    lblVoznik.Name := 'lblVoznik';
    lblVoznik.AlignWithMargins := True;
    lblVoznik.Left := 129;
    lblVoznik.Top := 4;
    lblVoznik.Width := 342;
    lblVoznik.Height := 37;
    lblVoznik.Margins.Left := 10;
    lblVoznik.Margins.Right := 10;
    lblVoznik.Align := alClient;
    lblVoznik.Alignment := taCenter;
    lblVoznik.Caption := 'NEZNANI VOZNIK';
    lblVoznik.ElementClassName := 'h3 text-light';
    lblVoznik.ElementFont := efCSS;
    lblVoznik.Font.Charset := DEFAULT_CHARSET;
    lblVoznik.Font.Color := clWhite;
    lblVoznik.Font.Height := -21;
    lblVoznik.Font.Name := 'Tahoma';
    lblVoznik.Font.Style := [];
    lblVoznik.HeightPercent := 100.000000000000000000;
    lblVoznik.Layout := tlCenter;
    lblVoznik.ParentFont := False;
    lblVoznik.WidthPercent := 100.000000000000000000;
    lblVozilo.SetParentComponent(panFooter);
    lblVozilo.Name := 'lblVozilo';
    lblVozilo.AlignWithMargins := True;
    lblVozilo.Left := 11;
    lblVozilo.Top := 4;
    lblVozilo.Width := 98;
    lblVozilo.Height := 37;
    lblVozilo.Margins.Left := 10;
    lblVozilo.Margins.Right := 10;
    lblVozilo.Align := alLeft;
    lblVozilo.Caption := 'KP AA-000';
    lblVozilo.ElementClassName := 'h3 text-light';
    lblVozilo.ElementFont := efCSS;
    lblVozilo.Font.Charset := DEFAULT_CHARSET;
    lblVozilo.Font.Color := clWhite;
    lblVozilo.Font.Height := -21;
    lblVozilo.Font.Name := 'Tahoma';
    lblVozilo.Font.Style := [];
    lblVozilo.HeightPercent := 100.000000000000000000;
    lblVozilo.Layout := tlCenter;
    lblVozilo.ParentFont := False;
    lblVozilo.WidthPercent := 100.000000000000000000;
    lblFrakcija.SetParentComponent(panFooter);
    lblFrakcija.Name := 'lblFrakcija';
    lblFrakcija.AlignWithMargins := True;
    lblFrakcija.Left := 484;
    lblFrakcija.Top := 4;
    lblFrakcija.Width := 173;
    lblFrakcija.Height := 37;
    lblFrakcija.Margins.Right := 10;
    lblFrakcija.Align := alRight;
    lblFrakcija.Alignment := taRightJustify;
    lblFrakcija.Caption := 'PAPIR IN KARTON';
    lblFrakcija.ElementClassName := 'h3 text-light';
    lblFrakcija.ElementFont := efCSS;
    lblFrakcija.Font.Charset := DEFAULT_CHARSET;
    lblFrakcija.Font.Color := clWhite;
    lblFrakcija.Font.Height := -21;
    lblFrakcija.Font.Name := 'Tahoma';
    lblFrakcija.Font.Style := [];
    lblFrakcija.HeightPercent := 100.000000000000000000;
    lblFrakcija.Layout := tlCenter;
    lblFrakcija.ParentFont := False;
    lblFrakcija.WidthPercent := 100.000000000000000000;
    panMain.SetParentComponent(Self);
    panMain.Name := 'panMain';
    panMain.Left := 0;
    panMain.Top := 70;
    panMain.Width := 668;
    panMain.Height := 646;
    panMain.Align := alClient;
    panMain.BorderColor := clWhite;
    panMain.BorderStyle := bsNone;
    panMain.ChildOrder := 2;
    panMain.Color := clWhite;
    panMain.ShowCaption := False;
    panMain.TabOrder := 2;
  finally
    panHeader.AfterLoadDFMValues;
    lblTitle.AfterLoadDFMValues;
    imgLogo.AfterLoadDFMValues;
    lblDan.AfterLoadDFMValues;
    btnLogout.AfterLoadDFMValues;
    panFooter.AfterLoadDFMValues;
    lblVoznik.AfterLoadDFMValues;
    lblVozilo.AfterLoadDFMValues;
    lblFrakcija.AfterLoadDFMValues;
    panMain.AfterLoadDFMValues;
  end;
end;

end.
