unit Lokacija;

interface

uses
  System.SysUtils, System.Variants, System.Classes, Web, System.Dateutils,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.StdCtrls, TerminalForm,
  WEBLib.StdCtrls, WEBLib.ExtCtrls, Data.DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.Controls, WEBLib.Grids, WEBLib.DBCtrls, WEBLib.Forms,
  XData.Web.Connection, Vcl.Grids, WEBLib.Buttons, WEBLib.WebCtrls;

type
  TfrmLokacija = class(TTerminalForm)
    tmrFind: TTimer;
    wdsPotPodrobno: TXDataWebDataSet;
    dsPotPodrobno: TDataSource;
    panHeader: TPanel;
    edtFilter: TEdit;
    panFooter: TPanel;
    btnSelect: TButton;
    gridView: TDBGrid;
    btnDelNote: TButton;
    lblIsci: TLabel;
    mapMain: TGoogleMaps;
    btnLocate: TButton;
    btnMap: TButton;
    splitMap: TSplitter;
    wdsOdvoz: TXDataWebDataSet;
    btnTehtaj: TButton;
    btnZgodovina: TButton;
    btnDan: TButton;
    btnFuel: TButton;
    procedure tmrFindTimer(Sender: TObject);
    procedure edtFilterChange(Sender: TObject);
    procedure tblStrankeDblClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure wdsPotPodrobnoAfterOpen(DataSet: TDataSet);
    procedure WebFormShow(Sender: TObject);
    procedure btnDelNoteClick(Sender: TObject);
    procedure btnLocate2Click(Sender: TObject);
    procedure ToggleLocate(Value, Silent: boolean);
    procedure wdsPotPodrobnoAfterScroll(DataSet: TDataSet);
    procedure gridViewGetCellClass(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string; var AClassName: string);
    procedure btnMapClick(Sender: TObject);
    procedure mapMainMapLoaded(Sender: TObject);
    procedure mapMainMapZoom(Sender: TObject; ZoomLevel: Integer);
    procedure mapMainMarkerClick(Sender: TObject; AIndex: Integer; AMarker: TJSObjectRecord);
    procedure ProcesirajLokacijo;
    procedure GetOdvozInfo(Sender: TObject);
    procedure btnZgodovinaClick(Sender: TObject);
    procedure btnDanClick(Sender: TObject);
    procedure btnFuelClick(Sender: TObject);
    procedure btnTehtajClick(Sender: TObject);  // Klic pride iz frmMain
  private
    { Private declarations }
    Starting: boolean;
//    Fmt: TFormatSettings;
    datToday: string;
//    FLat, FLon: double;
    CurLatD, CurLatL, CurLonL, CurLonD: double;
    sCurLatD, sCurLatL, sCurLonL, sCurLonD: string;

    procedure VrniLokacije(Flt, Srt: string);
    function VrniPozicijo(LatLon: double; PremikM: integer): double;
    procedure ToggleButtons;
    procedure CustomizeGui;
    procedure ApplyTheme(Dark: boolean); reintroduce;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses Client.Core, Main, Client.Settings, Client.Constants;

procedure TfrmLokacija.ApplyTheme(Dark: boolean);
begin
  inherited;
end;

procedure TfrmLokacija.btnDanClick(Sender: TObject);
begin
  frmMain.ShowKoledar(knPrivzeto, 'Izberi dan za delo');
end;

procedure TfrmLokacija.btnDelNoteClick(Sender: TObject);
begin
  edtFilter.Clear;
  edtFilter.SetFocus;
  tmrFindTimer(Sender)
end;

procedure TfrmLokacija.btnFuelClick(Sender: TObject);
begin
  inherited;
  frmMain.ShowKilometri(kmGorivo);
end;

procedure TfrmLokacija.btnLocate2Click(Sender: TObject);
begin
  AppSettings.LocationEnabled := not AppSettings.LocationEnabled;
  ToggleLocate(AppSettings.LocationEnabled, false);
  tmrFindTimer(Sender);
end;

procedure TfrmLokacija.ToggleButtons;
begin
  btnSelect.Enabled := wdsPotPodrobno.RecordCount > 0;
end;

procedure TfrmLokacija.ToggleLocate(Value, Silent: boolean);
begin
  if Value then begin
    btnLocate.ElementClassName := 'btn btn-xxl btn-warning';
  end else begin
    btnLocate.ElementClassName := 'btn btn-xxl btn-primary';
  end;
  btnMap.Enabled := Value;

  // Skije mapo, če ni GPS
  if not Value and mapMain.Visible then
    mapMain.Visible := false;
end;

procedure TfrmLokacija.edtFilterChange(Sender: TObject);
begin
  tmrFind.Enabled := false;
  tmrFind.Enabled := true;
end;

procedure TfrmLokacija.GetOdvozInfo(Sender: TObject);
begin
  if wdsPotPodrobno.RecordCount<1 then
    exit;

  // Vrni zadnji odvoz za lokacijo in pot
  modMain.NastaviLokacijo(wdsPotPodrobno.FieldByName('Lokacija.Id').AsInteger);
  modMain.NastaviPot(wdsPotPodrobno.FieldByName('Pot.Id').AsInteger);

  modMain.LokacijaLastRow := wdsPotPodrobno.RecNo;

  frmMain.ShowOdvozInfo;
end;

procedure TfrmLokacija.tblStrankeDblClick(Sender: TObject);
begin
  if wdsPotPodrobno.RecordCount<1 then
    exit;

  // Vrni zadnji odvoz za lokacijo in pot
  modMain.NastaviLokacijo(wdsPotPodrobno.FieldByName('Lokacija.Id').AsInteger);
  modMain.NastaviPot(wdsPotPodrobno.FieldByName('Pot.Id').AsInteger);

  modMain.LokacijaLastRow := wdsPotPodrobno.RecNo;

  frmMain.ShowOdvoz;
end;

procedure TfrmLokacija.tmrFindTimer(Sender: TObject);
var Flt, Srt: string;
    F: TStringList;

  function Filter(FL: TStringList): string;
  var I: integer;
  begin
    Result := '';
    for I := 0 to FL.Count-1 do begin
      if I > 0 then
        Result := Result + ' and ';

      Result := Result + FL[I];
    end;
  end;

begin
  tmrFind.Enabled := false;

  Flt := '';
  Srt := 'idx';

  F := TStringList.Create;
  F.Add('lokacija/aktivna eq 1');
  if AppSettings.LocationEnabled and (CurLatL<>0) then begin
    modMain.Log('Using location...');
    F.Add('lokacija/lat gt '+sCurLatL+' and lokacija/lat lt '+sCurLatD+' and lokacija/lon gt '+sCurLonL+' and lokacija/lon lt '+sCurLonD+'');
  end;

  if edtFilter.Text<>'' then begin
    F.Add('(contains(lokacija/stranka/naziv,%27'+edtFilter.Text+'%27) or contains(lokacija/naziv,%27'+edtFilter.Text+'%27) or contains(lokacija/opombe,%27'+edtFilter.Text+'%27))');
    Srt := 'lokacija/stranka/naziv,lokacija/naziv';
  end;
  Flt := Filter(F);
  F.Free;

  modMain.Log(Flt);

  VrniLokacije(Flt, Srt);
  ToggleButtons;
end;

// Klic pride iz frmMain
procedure TfrmLokacija.ProcesirajLokacijo;
begin
  if not AppSettings.LocationEnabled then
    exit;

  modMain.Log('TfrmLokacija.Executing ProcesirajLokacijo');

  // Vrne kote kvadrata +- 100m od točke
  CurLatL := VrniPozicijo(modMain.LokacijaLat, 0-AppSettings.LocationLength);
  CurLatD := VrniPozicijo(modMain.LokacijaLat, AppSettings.LocationLength);

  CurLonL := VrniPozicijo(modMain.LokacijaLon, 0-AppSettings.LocationLength) ;
  CurLonD := VrniPozicijo(modMain.LokacijaLon, AppSettings.LocationLength);

  sCurLatL := CurLatL.ToString.Replace(',', '.');
  sCurLatD := CurLatD.ToString.Replace(',', '.');
  sCurLonL := CurLonL.ToString.Replace(',', '.');
  sCurLonD := CurLonD.ToString.Replace(',', '.');

  tmrFindTimer(nil);
end;

procedure TfrmLokacija.gridViewGetCellClass(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string; var AClassName: string);
var
  datRow: string;
begin

  datRow   := gridView.Cells[2,ARow];  // npr. 2024-04-16

  if datRow = datToday then begin
    AClassName := 'done';
    modMain.Log(datRow+' = '+datToday);
  end;
end;

procedure TfrmLokacija.mapMainMapLoaded(Sender: TObject);
begin
  CustomizeGui;
  mapMain.SetZoom(AppSettings.DefaultZoom);
  if modMain.LokacijaLat=0 then
    mapMain.SetCenter(AppSettings.DefaultLat, AppSettings.DefaultLon)
  else
    mapMain.SetCenter(modMain.LokacijaLat, modMain.LokacijaLon);
end;

procedure TfrmLokacija.mapMainMapZoom(Sender: TObject; ZoomLevel: Integer);
begin
  AppSettings.DefaultZoom := ZoomLevel;
end;

procedure TfrmLokacija.mapMainMarkerClick(Sender: TObject; AIndex: Integer;
  AMarker: TJSObjectRecord);
begin
  if (AIndex>0) and (AIndex < wdsPotPodrobno.RecordCount) then begin
    modMain.Log('Settings RecNo...');
    wdsPotPodrobno.RecNo := AIndex;
  end;
end;

procedure TfrmLokacija.VrniLokacije(Flt, Srt: string);
var Qry: string;
begin
  if Flt<>'' then
    Flt := Flt + ' and ';

  Flt := Flt + 'lokacija/aktivna eq 1 and pot/frakcija eq '+modMain.FrakcijaId.ToString+' and pot/vozilo/id eq '+modMain.VoziloId.ToString+'and pot/danvtednu eq '+modMain.IzbranDan.ToString;
  Qry := '$filter='+Flt+'&$orderby='+Srt+'&$expand=lokacija,lokacija/stranka,pot';

  modMain.Log('Qry='+Qry);

  wdsPotPodrobno.Close;
  wdsPotPodrobno.QueryString := Qry;
  wdsPotPodrobno.Load;
end;

function TfrmLokacija.VrniPozicijo(LatLon: double; PremikM: integer): double;
var earth: double;
    pi: double;
    m: double;
begin
  earth := 6378.137;  // radij zemlje v km

  asm
    pi = Math.PI;
  end;
  m := (1 / ((2 * pi / 360) * earth)) / 1000;  // 1 meter v stopinjah

  Result := LatLon + (PremikM * m);
end;

procedure TfrmLokacija.wdsPotPodrobnoAfterOpen(DataSet: TDataSet);
var poz: integer;
    vozilo, title: string;
    RowSelected: integer;
begin
  ToggleButtons;

  // Nastavi naslednjo lokacijo
  if Starting then begin
    if (wdsPotPodrobno.RecordCount >= modMain.LokacijaLastRow) and (modMain.LokacijaLastRow > 0) then begin
      wdsPotPodrobno.RecNo := modMain.LokacijaLastRow;
    end else
      wdsPotPodrobno.Last;
  end;

  // Po osvežitvi daj na isto vrstico
  if AppSettings.LocationEnabled then begin
    modMain.log('Locate...');
    RowSelected := StrToIntDef(modMain.GetVar('Pot.Selected'), 1);
    wdsPotPodrobno.Locate('Id', RowSelected, []);
  end;

  {$REGION 'Risanje zemljevida'}
  if mapMain.Visible then begin
    modMain.log('Updating map...');
    mapMain.BeginUpdate;
    wdsPotPodrobno.DisableControls;
    poz := wdsPotPodrobno.RecNo;
    wdsPotPodrobno.First;

    mapMain.ClearRectangles;
    mapMain.AddRectangle(CurLatL, CurLonL, CurLatD, CurLonD, clInfoBk, 0, 2, 0.3);
    vozilo := 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAANZJREFUSEvtlNENAiEQRIdOtBOtRK1ErUQ70U60E71JmAs5ISMEfoz7d8cyj90dCBgcYbA+fgewAXABsGpsWbETWrgBIKQ1LOAVlWtnYvdJ0CYWSrP7egFS/gnAWT9GAJ4ADtNM74T0AkjnOImyAkLWIwC0Oe1OR15ZSe8KeGhCHnJlL0Dp/oQcIO0jy5wdkVGRTb8GtN7kasA2OkG9dOBS/twiCehNoo9pNX7TzxTIhc1fvj37aLNUjDDOIhc2fwmgxejhXbSbG7LNr3093Sw+1v8A27I3Nx01GW789uAAAAAASUVORK5CYII=';

    mapMain.ClearMarkers;
    // Vozilo
    mapMain.AddMarker(modMain.LokacijaLat, modMain.LokacijaLon, vozilo, 'To si ti', 0, 0);
    // Lokacije
    while not wdsPotPodrobno.Eof do begin
      title := wdsPotPodrobno.FieldByName('Lokacija.Stranka.Naziv').AsString+', '+wdsPotPodrobno.FieldByName('Lokacija.Naziv').AsString;
      mapMain.AddMarker(wdsPotPodrobno.FieldByName('Lokacija.Lat').Asfloat, wdsPotPodrobno.FieldByName('Lokacija.Lon').Asfloat, Title);
      wdsPotPodrobno.Next;
    end;

    if poz>0 then begin
//      modMain.log('poz');
      wdsPotPodrobno.RecNo := poz;
    end;
    wdsPotPodrobno.EnableControls;
    mapMain.EndUpdate;
    mapMain.SetCenter(modMain.LokacijaLat, modMain.LokacijaLon);
  end;

  {$ENDREGION}

  Starting := false;
end;

procedure TfrmLokacija.wdsPotPodrobnoAfterScroll(DataSet: TDataSet);
begin
  modMain.SetVar('Pot.Selected', wdsPotPodrobno.FieldByName('Id').AsString);
end;

procedure TfrmLokacija.btnMapClick(Sender: TObject);
begin
  mapMain.Visible := not mapMain.Visible;
end;

procedure TfrmLokacija.btnTehtajClick(Sender: TObject);
begin
  inherited;
  frmMain.ShowPotrditev('Tehtanje vozila','Želiš tehtati vozilo?');
end;

procedure TfrmLokacija.btnZgodovinaClick(Sender: TObject);
begin
  frmMain.ShowZgodovina;
end;

procedure TfrmLokacija.WebFormCreate(Sender: TObject);
begin
  inherited;

  Starting := true;
//  Fmt := TFormatSettings.Create('en-us');
//  Fmt.ShortDateFormat := 'yyyy-mm-dd';

  datToday := DateToStr(modMain.IzbranDatum, Fmt);
  modMain.Log(datToday);

  ToggleLocate(AppSettings.LocationEnabled, true);

  if AppSettings.LocationEnabled then
    modMain.AzurirajPozicijo
  else
    tmrFindTimer(nil);

  modMain.Log('Fmt='+Fmt.ShortDateFormat);

  ApplyTheme(AppSettings.DarkMode);
end;

procedure TfrmLokacija.WebFormShow(Sender: TObject);
begin
  inherited;

  gridView.ColWidths[0] := Round(gridView.Width * 0.5)-2;
  gridView.ColWidths[1] := Round(gridView.Width * 0.5)-2;
end;

procedure TfrmLokacija.CustomizeGui;
var map: TJSHTMLElement;
begin
//  map := mapMain.GetMap;
  map := mapMain.MapElement;
  asm
    map.setOptions({disableDefaultUI:true});
  end;
end;

procedure TfrmLokacija.LoadDFMValues;
begin
  inherited LoadDFMValues;

  panHeader := TPanel.Create(Self);
  lblIsci := TLabel.Create(Self);
  edtFilter := TEdit.Create(Self);
  btnDelNote := TButton.Create(Self);
  btnLocate := TButton.Create(Self);
  btnMap := TButton.Create(Self);
  panFooter := TPanel.Create(Self);
  btnSelect := TButton.Create(Self);
  btnTehtaj := TButton.Create(Self);
  btnZgodovina := TButton.Create(Self);
  btnDan := TButton.Create(Self);
  btnFuel := TButton.Create(Self);
  gridView := TDBGrid.Create(Self);
  mapMain := TGoogleMaps.Create(Self);
  splitMap := TSplitter.Create(Self);
  tmrFind := TTimer.Create(Self);
  wdsPotPodrobno := TXDataWebDataSet.Create(Self);
  dsPotPodrobno := TDataSource.Create(Self);
  wdsOdvoz := TXDataWebDataSet.Create(Self);

  panHeader.BeforeLoadDFMValues;
  lblIsci.BeforeLoadDFMValues;
  edtFilter.BeforeLoadDFMValues;
  btnDelNote.BeforeLoadDFMValues;
  btnLocate.BeforeLoadDFMValues;
  btnMap.BeforeLoadDFMValues;
  panFooter.BeforeLoadDFMValues;
  btnSelect.BeforeLoadDFMValues;
  btnTehtaj.BeforeLoadDFMValues;
  btnZgodovina.BeforeLoadDFMValues;
  btnDan.BeforeLoadDFMValues;
  btnFuel.BeforeLoadDFMValues;
  gridView.BeforeLoadDFMValues;
  mapMain.BeforeLoadDFMValues;
  splitMap.BeforeLoadDFMValues;
  tmrFind.BeforeLoadDFMValues;
  wdsPotPodrobno.BeforeLoadDFMValues;
  dsPotPodrobno.BeforeLoadDFMValues;
  wdsOdvoz.BeforeLoadDFMValues;
  try
    Name := 'frmLokacija';
    Left := 0;
    Top := 0;
    Align := alClient;
    Color := clBtnFace;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    panHeader.SetParentComponent(Self);
    panHeader.Name := 'panHeader';
    panHeader.Left := 0;
    panHeader.Top := 0;
    panHeader.Width := 831;
    panHeader.Height := 91;
    panHeader.Align := alTop;
    panHeader.BorderStyle := bsNone;
    panHeader.ChildOrder := 3;
    panHeader.Color := clWhite;
    panHeader.ElementBodyClassName := 'text';
    panHeader.Padding.Left := 5;
    panHeader.Padding.Top := 5;
    panHeader.Padding.Right := 5;
    panHeader.Padding.Bottom := 5;
    panHeader.TabOrder := 1;
    lblIsci.SetParentComponent(panHeader);
    lblIsci.Name := 'lblIsci';
    lblIsci.Left := 5;
    lblIsci.Top := 5;
    lblIsci.Width := 60;
    lblIsci.Height := 81;
    lblIsci.Align := alLeft;
    lblIsci.AutoSize := False;
    lblIsci.Caption := 'I'#353#269'i';
    lblIsci.ElementClassName := 'fs-1';
    lblIsci.ElementFont := efCSS;
    lblIsci.Font.Charset := DEFAULT_CHARSET;
    lblIsci.Font.Color := clWindowText;
    lblIsci.Font.Height := -21;
    lblIsci.Font.Name := 'Tahoma';
    lblIsci.Font.Style := [];
    lblIsci.HeightPercent := 100.000000000000000000;
    lblIsci.Layout := tlCenter;
    lblIsci.ParentFont := False;
    lblIsci.WidthPercent := 100.000000000000000000;
    edtFilter.SetParentComponent(panHeader);
    edtFilter.Name := 'edtFilter';
    edtFilter.AlignWithMargins := True;
    edtFilter.Left := 68;
    edtFilter.Top := 10;
    edtFilter.Width := 476;
    edtFilter.Height := 71;
    edtFilter.Margins.Top := 5;
    edtFilter.Margins.Bottom := 5;
    edtFilter.Align := alClient;
    edtFilter.CharCase := wecUpperCase;
    edtFilter.ChildOrder := 3;
    edtFilter.ElementClassName := 'fs-1';
    edtFilter.ElementFont := efCSS;
    edtFilter.HeightPercent := 100.000000000000000000;
    edtFilter.RequiredText := 'I'#353#269'i...';
    edtFilter.WidthPercent := 100.000000000000000000;
    SetEvent(edtFilter, Self, 'OnChange', 'edtFilterChange');
    btnDelNote.SetParentComponent(panHeader);
    btnDelNote.Name := 'btnDelNote';
    btnDelNote.AlignWithMargins := True;
    btnDelNote.Left := 550;
    btnDelNote.Top := 8;
    btnDelNote.Width := 80;
    btnDelNote.Height := 75;
    btnDelNote.Margins.Right := 10;
    btnDelNote.Align := alRight;
    btnDelNote.Caption := '<i class="material-icons">backspace</i>';
    btnDelNote.ChildOrder := 2;
    btnDelNote.ElementClassName := 'btn btn-xxl btn-primary';
    btnDelNote.ElementFont := efCSS;
    btnDelNote.HeightPercent := 100.000000000000000000;
    btnDelNote.WidthPercent := 100.000000000000000000;
    SetEvent(btnDelNote, Self, 'OnClick', 'btnDelNoteClick');
    btnLocate.SetParentComponent(panHeader);
    btnLocate.Name := 'btnLocate';
    btnLocate.AlignWithMargins := True;
    btnLocate.Left := 643;
    btnLocate.Top := 8;
    btnLocate.Width := 80;
    btnLocate.Height := 75;
    btnLocate.Margins.Right := 10;
    btnLocate.Align := alRight;
    btnLocate.Caption := '<i class="material-icons">my_location</i>';
    btnLocate.ChildOrder := 2;
    btnLocate.ElementClassName := 'btn btn-primary';
    btnLocate.ElementFont := efCSS;
    btnLocate.HeightPercent := 100.000000000000000000;
    btnLocate.WidthPercent := 100.000000000000000000;
    SetEvent(btnLocate, Self, 'OnClick', 'btnLocate2Click');
    btnMap.SetParentComponent(panHeader);
    btnMap.Name := 'btnMap';
    btnMap.AlignWithMargins := True;
    btnMap.Left := 736;
    btnMap.Top := 8;
    btnMap.Width := 80;
    btnMap.Height := 75;
    btnMap.Margins.Right := 10;
    btnMap.Align := alRight;
    btnMap.Caption := '<i class="material-icons">map</i>';
    btnMap.ChildOrder := 2;
    btnMap.ElementClassName := 'btn btn-primary';
    btnMap.ElementFont := efCSS;
    btnMap.HeightPercent := 100.000000000000000000;
    btnMap.WidthPercent := 100.000000000000000000;
    SetEvent(btnMap, Self, 'OnClick', 'btnMapClick');
    panFooter.SetParentComponent(Self);
    panFooter.Name := 'panFooter';
    panFooter.Left := 0;
    panFooter.Top := 706;
    panFooter.Width := 831;
    panFooter.Height := 120;
    panFooter.Align := alBottom;
    panFooter.BorderStyle := bsNone;
    panFooter.ChildOrder := 3;
    panFooter.Color := clWhite;
    panFooter.Padding.Top := 4;
    panFooter.TabOrder := 2;
    btnSelect.SetParentComponent(panFooter);
    btnSelect.Name := 'btnSelect';
    btnSelect.AlignWithMargins := True;
    btnSelect.Left := 716;
    btnSelect.Top := 9;
    btnSelect.Width := 105;
    btnSelect.Height := 108;
    btnSelect.Margins.Top := 5;
    btnSelect.Margins.Right := 10;
    btnSelect.Align := alRight;
    btnSelect.Caption := '<i class="material-icons" style="font-size:40px;">done</i>';
    btnSelect.ChildOrder := 1;
    btnSelect.ElementClassName := 'btn btn-xxl btn-success';
    btnSelect.ElementFont := efCSS;
    btnSelect.Enabled := False;
    btnSelect.HeightPercent := 100.000000000000000000;
    btnSelect.WidthPercent := 100.000000000000000000;
    SetEvent(btnSelect, Self, 'OnClick', 'tblStrankeDblClick');
    btnTehtaj.SetParentComponent(panFooter);
    btnTehtaj.Name := 'btnTehtaj';
    btnTehtaj.AlignWithMargins := True;
    btnTehtaj.Left := 357;
    btnTehtaj.Top := 9;
    btnTehtaj.Width := 105;
    btnTehtaj.Height := 108;
    btnTehtaj.Margins.Top := 5;
    btnTehtaj.Margins.Right := 10;
    btnTehtaj.Align := alLeft;
    btnTehtaj.Caption := '<i class="material-icons" style="font-size:40px;">scale</i>';
    btnTehtaj.ChildOrder := 1;
    btnTehtaj.ElementClassName := 'btn btn-primary';
    btnTehtaj.ElementFont := efCSS;
    btnTehtaj.HeightPercent := 100.000000000000000000;
    btnTehtaj.WidthPercent := 100.000000000000000000;
    SetEvent(btnTehtaj, Self, 'OnClick', 'btnTehtajClick');
    btnZgodovina.SetParentComponent(panFooter);
    btnZgodovina.Name := 'btnZgodovina';
    btnZgodovina.AlignWithMargins := True;
    btnZgodovina.Left := 3;
    btnZgodovina.Top := 9;
    btnZgodovina.Width := 105;
    btnZgodovina.Height := 108;
    btnZgodovina.Margins.Top := 5;
    btnZgodovina.Margins.Right := 10;
    btnZgodovina.Align := alLeft;
    btnZgodovina.Caption := '<i class="material-icons" style="font-size:40px;">history</i>';
    btnZgodovina.ChildOrder := 1;
    btnZgodovina.ElementClassName := 'btn btn-primary';
    btnZgodovina.ElementFont := efCSS;
    btnZgodovina.HeightPercent := 100.000000000000000000;
    btnZgodovina.WidthPercent := 100.000000000000000000;
    SetEvent(btnZgodovina, Self, 'OnClick', 'btnZgodovinaClick');
    btnDan.SetParentComponent(panFooter);
    btnDan.Name := 'btnDan';
    btnDan.AlignWithMargins := True;
    btnDan.Left := 121;
    btnDan.Top := 9;
    btnDan.Width := 105;
    btnDan.Height := 108;
    btnDan.Margins.Top := 5;
    btnDan.Margins.Right := 10;
    btnDan.Align := alLeft;
    btnDan.Caption := '<i class="material-icons" style="font-size:40px;">calendar_month</i>';
    btnDan.ChildOrder := 1;
    btnDan.ElementClassName := 'btn btn-primary';
    btnDan.ElementFont := efCSS;
    btnDan.HeightPercent := 100.000000000000000000;
    btnDan.WidthPercent := 100.000000000000000000;
    SetEvent(btnDan, Self, 'OnClick', 'btnDanClick');
    btnFuel.SetParentComponent(panFooter);
    btnFuel.Name := 'btnFuel';
    btnFuel.AlignWithMargins := True;
    btnFuel.Left := 239;
    btnFuel.Top := 9;
    btnFuel.Width := 105;
    btnFuel.Height := 108;
    btnFuel.Margins.Top := 5;
    btnFuel.Margins.Right := 10;
    btnFuel.Align := alLeft;
    btnFuel.Caption := '<i class="material-icons" style="font-size:40px;">local_gas_station</i>';
    btnFuel.ChildOrder := 1;
    btnFuel.ElementClassName := 'btn btn-warning';
    btnFuel.ElementFont := efCSS;
    btnFuel.HeightPercent := 100.000000000000000000;
    btnFuel.WidthPercent := 100.000000000000000000;
    SetEvent(btnFuel, Self, 'OnClick', 'btnFuelClick');
    gridView.SetParentComponent(Self);
    gridView.Name := 'gridView';
    gridView.Left := 0;
    gridView.Top := 91;
    gridView.Width := 831;
    gridView.Height := 361;
    gridView.Align := alClient;
    gridView.Color := clWhite;
    gridView.Columns.Clear;
    with gridView.Columns.Add do
    begin
      ElementClassName := 'text-wrap';
      DataField := 'Lokacija.Stranka.Naziv';
      Title := 'Stranka';
      Width := 340;
    end;
    with gridView.Columns.Add do
    begin
      ElementClassName := 'text-wrap';
      DataField := 'Lokacija.Naziv';
      Title := 'Lokacija';
      Width := 340;
    end;
    with gridView.Columns.Add do
    begin
      DataField := 'ZadnjiOdvoz';
      Width := 0;
    end;
    gridView.DataSource := dsPotPodrobno;
    gridView.ElementFont := efCSS;
    gridView.ElementClassName := 'fs-2';
    gridView.FixedFont.Charset := DEFAULT_CHARSET;
    gridView.FixedFont.Color := clWindowText;
    gridView.FixedFont.Height := -12;
    gridView.FixedFont.Name := 'Segoe UI';
    gridView.FixedFont.Style := [];
    gridView.FixedCols := 0;
    gridView.FixedRows := 0;
    gridView.Font.Charset := DEFAULT_CHARSET;
    gridView.Font.Color := clWindowText;
    gridView.Font.Height := -48;
    gridView.Font.Name := 'Tahoma';
    gridView.Font.Style := [];
    gridView.Options := [goHorzLine,goRowSelect];
    gridView.ParentFont := False;
    gridView.TabOrder := 0;
    gridView.HeightPercent := 100.000000000000000000;
    gridView.ShowIndicator := False;
    gridView.WidthPercent := 100.000000000000000000;
    SetEvent(gridView, Self, 'OnDblClick', 'GetOdvozInfo');
    SetEvent(gridView, Self, 'OnGetCellClass', 'gridViewGetCellClass');
    gridView.ColWidths[0] := 340;
    gridView.ColWidths[1] := 340;
    gridView.ColWidths[2] := 0;
    mapMain.SetParentComponent(Self);
    mapMain.Name := 'mapMain';
    mapMain.Left := 0;
    mapMain.Top := 457;
    mapMain.Width := 831;
    mapMain.Height := 249;
    mapMain.Align := alBottom;
    mapMain.APIKey := 'AIzaSyAvcd34ShYOvU1IFBq4JAJ2an032gl3MFo';
    mapMain.ChildOrder := 4;
    mapMain.Options.DefaultLatitude := -34.397000000000000000;
    mapMain.Options.DefaultLongitude := 150.644000000000000000;
    mapMain.Visible := False;
    SetEvent(mapMain, Self, 'OnMapLoaded', 'mapMainMapLoaded');
    SetEvent(mapMain, Self, 'OnMapZoom', 'mapMainMapZoom');
    SetEvent(mapMain, Self, 'OnMarkerClick', 'mapMainMarkerClick');
    splitMap.SetParentComponent(Self);
    splitMap.Name := 'splitMap';
    splitMap.Left := 0;
    splitMap.Top := 452;
    splitMap.Width := 831;
    splitMap.Height := 5;
    splitMap.Align := alBottom;
    splitMap.ChildOrder := 4;
    splitMap.Color := clBtnFace;
    tmrFind.SetParentComponent(Self);
    tmrFind.Name := 'tmrFind';
    tmrFind.Enabled := False;
    tmrFind.Interval := 500;
    SetEvent(tmrFind, Self, 'OnTimer', 'tmrFindTimer');
    tmrFind.Left := 108;
    tmrFind.Top := 328;
    wdsPotPodrobno.SetParentComponent(Self);
    wdsPotPodrobno.Name := 'wdsPotPodrobno';
    wdsPotPodrobno.AfterOpen := wdsPotPodrobnoAfterOpen;
    wdsPotPodrobno.AfterScroll := wdsPotPodrobnoAfterScroll;
    wdsPotPodrobno.EntitySetName := 'PotPodrobno';
    wdsPotPodrobno.Connection := modMain.connServer;
    wdsPotPodrobno.SubpropsDepth := 2;
    wdsPotPodrobno.QueryString := 'filter=Aktivna%20ne%200&$orderby=Stranka/Naziv,PrevzemnoMesto&$expand=stranka';
    wdsPotPodrobno.Left := 104;
    wdsPotPodrobno.Top := 216;
    dsPotPodrobno.SetParentComponent(Self);
    dsPotPodrobno.Name := 'dsPotPodrobno';
    dsPotPodrobno.DataSet := wdsPotPodrobno;
    dsPotPodrobno.Left := 104;
    dsPotPodrobno.Top := 272;
    wdsOdvoz.SetParentComponent(Self);
    wdsOdvoz.Name := 'wdsOdvoz';
    wdsOdvoz.AfterOpen := wdsPotPodrobnoAfterOpen;
    wdsOdvoz.AfterScroll := wdsPotPodrobnoAfterScroll;
    wdsOdvoz.EntitySetName := 'Odvoz';
    wdsOdvoz.Connection := modMain.connServer;
    wdsOdvoz.SubpropsDepth := 2;
    wdsOdvoz.Left := 304;
    wdsOdvoz.Top := 208;
  finally
    panHeader.AfterLoadDFMValues;
    lblIsci.AfterLoadDFMValues;
    edtFilter.AfterLoadDFMValues;
    btnDelNote.AfterLoadDFMValues;
    btnLocate.AfterLoadDFMValues;
    btnMap.AfterLoadDFMValues;
    panFooter.AfterLoadDFMValues;
    btnSelect.AfterLoadDFMValues;
    btnTehtaj.AfterLoadDFMValues;
    btnZgodovina.AfterLoadDFMValues;
    btnDan.AfterLoadDFMValues;
    btnFuel.AfterLoadDFMValues;
    gridView.AfterLoadDFMValues;
    mapMain.AfterLoadDFMValues;
    splitMap.AfterLoadDFMValues;
    tmrFind.AfterLoadDFMValues;
    wdsPotPodrobno.AfterLoadDFMValues;
    dsPotPodrobno.AfterLoadDFMValues;
    wdsOdvoz.AfterLoadDFMValues;
  end;
end;

end.