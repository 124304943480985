unit OdvozInfo;

interface

uses
  System.SysUtils, System.Variants, System.Classes, TerminalForm,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, WEBLib.DBCtrls, WEBLib.JSON, JS,
  Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.Grids, WEBLib.Controls, WEBLib.ExtCtrls,
  Data.DB, XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.DB, WEBLib.Forms,
  XData.Web.Connection, WEBLib.SignatureCapture, Vcl.Grids, Web, System.DateUtils,
  XData.Web.Client;

type
  TfrmOdvozInfo = class(TTerminalForm)
    dsLokacijaPosode: TDataSource;
    wdsLokacijaPosode: TXDataWebDataSet;
    dsOdvozPodrobno: TDataSource;
    wdsOdvozPodrobno: TXDataWebDataSet;
    dsOdvoz: TDataSource;
    wdsOdvoz: TXDataWebDataSet;
    panInfo: TPanel;
    WebLabel2: TLabel;
    WebLabel4: TLabel;
    WebDBLabel1: TDBLabel;
    WebDBLabel3: TDBLabel;
    WebDBLabel5: TDBLabel;
    panPosode: TPanel;
    gridPodrobno: TDBGrid;
    panFooter: TPanel;
    btnCancel: TButton;
    xwcSifranti: TXDataWebClient;
    wdsOdvozVozilo: TXDataWebEntityField;
    wdsOdvozVoziloTipVozila: TXDataWebEntityField;
    wdsOdvozVoziloTipVozilaId: TIntegerField;
    wdsOdvozVoziloTipVozilaOpis: TStringField;
    wdsOdvozVoziloVrstaOdvoza: TXDataWebEntityField;
    wdsOdvozVoziloVrstaOdvozaId: TIntegerField;
    wdsOdvozVoziloVrstaOdvozaOpis: TStringField;
    wdsOdvozVoziloVrstaOdvozaParams: TBlobField;
    wdsOdvozVoziloVrstaOdvozaKolicina: TIntegerField;
    wdsOdvozVoziloVrstaOdvozaVolumen: TIntegerField;
    wdsOdvozVoziloVrstaOdvozaTeza: TIntegerField;
    wdsOdvozVoziloOdvozi: TXDataWebDatasetField;
    wdsOdvozVoziloId: TIntegerField;
    wdsOdvozVoziloRegistrskaSt: TStringField;
    wdsOdvozVoziloAktivno: TBooleanField;
    wdsOdvozVoziloLetnik: TIntegerField;
    wdsOdvozVoziloPrvaRegistracija: TDateTimeField;
    wdsOdvozVoziloZnamka: TStringField;
    wdsOdvozVoziloTip: TStringField;
    wdsOdvozVoziloMocKW: TIntegerField;
    wdsOdvozVoziloProstornina: TIntegerField;
    wdsOdvozVoziloStSasije: TStringField;
    wdsOdvozVoziloMasaVozila: TIntegerField;
    wdsOdvozVoziloMasaNajvecja: TIntegerField;
    wdsOdvozVoziloPrtljaznikVolM3: TIntegerField;
    wdsOdvozLokacija: TXDataWebEntityField;
    wdsOdvozLokacijaStranka: TXDataWebEntityField;
    wdsOdvozLokacijaStrankaLokacije: TXDataWebDatasetField;
    wdsOdvozLokacijaStrankaId: TIntegerField;
    wdsOdvozLokacijaStrankaZunanjaOznaka: TIntegerField;
    wdsOdvozLokacijaStrankaAktivna: TBooleanField;
    wdsOdvozLokacijaStrankaObracun: TIntegerField;
    wdsOdvozLokacijaStrankaNaziv: TStringField;
    wdsOdvozLokacijaStrankaNaslov: TStringField;
    wdsOdvozLokacijaStrankaPosta: TStringField;
    wdsOdvozLokacijaStrankaKraj: TStringField;
    wdsOdvozLokacijaStrankaDavSt: TStringField;
    wdsOdvozLokacijaStrankaTel: TStringField;
    wdsOdvozLokacijaStrankaOpombe: TStringField;
    wdsOdvozLokacijaOdvozi: TXDataWebDatasetField;
    wdsOdvozLokacijaPosode: TXDataWebDatasetField;
    wdsOdvozLokacijaId: TIntegerField;
    wdsOdvozLokacijaAktivna: TBooleanField;
    wdsOdvozLokacijaNaziv: TStringField;
    wdsOdvozLokacijaOpombe: TStringField;
    wdsOdvozLokacijaLat: TFloatField;
    wdsOdvozLokacijaLon: TFloatField;
    wdsOdvozLokacijaAktivnaOd: TDateTimeField;
    wdsOdvozLokacijaAktivnaDo: TDateTimeField;
    wdsOdvozVoznik: TXDataWebEntityField;
    wdsOdvozVoznikVloga: TXDataWebEntityField;
    wdsOdvozVoznikVlogaId: TIntegerField;
    wdsOdvozVoznikVlogaOpis: TStringField;
    wdsOdvozVoznikId: TIntegerField;
    wdsOdvozVoznikUporabniskoIme: TStringField;
    wdsOdvozVoznikGeslo: TStringField;
    wdsOdvozVoznikPIN: TStringField;
    wdsOdvozVoznikIme: TStringField;
    wdsOdvozVoznikPriimek: TStringField;
    wdsOdvozVoznikAktiven: TBooleanField;
    wdsOdvozPodrobno2: TXDataWebDatasetField;
    wdsOdvozId: TIntegerField;
    wdsOdvozCasOdvoza: TDateTimeField;
    wdsOdvozPodpisStranke: TBlobField;
    wdsOdvozOpombe: TStringField;
    wdsOdvozid2: TIntegerField;
    wdsOdvozPotId: TIntegerField;
    WebLabel3: TLabel;
    WebDBLabel2: TDBLabel;
    procedure wdsOdvozPodrobnoAfterOpen(DataSet: TDataSet);
    procedure btnCancelClick(Sender: TObject);
    procedure wdsOdvozAfterOpen(DataSet: TDataSet);
    procedure FormCreate(Sender: TObject);
    procedure wdsLokacijaPosodeAfterOpen(DataSet: TDataSet);
    procedure gridPodrobnoClickCell(Sender: TObject; ACol, ARow: Integer);
    procedure wdsOdvozPostError(DataSet: TDataSet; E: EDatabaseError; var Action: TDataAction);
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
    //FOdvozId: integer; //Št odvoza, 0 za nove odvoze
    FAllowNegative: boolean;
    FSelRow: integer;
    procedure PripraviOdvoz(OdvozId: integer);
    procedure ApplyTheme(Dark: boolean); reintroduce;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses Client.Core, Main, Client.Settings;

procedure TfrmOdvozInfo.FormCreate(Sender: TObject);
begin
  inherited;

  PripraviOdvoz(modMain.OdvozId);
  gridPodrobno.SelectionColor := gridPodrobno.Color;
  gridPodrobno.SelectionTextColor := gridPodrobno.Font.Color;

  ApplyTheme(AppSettings.DarkMode);
end;

procedure TfrmOdvozInfo.PripraviOdvoz(OdvozId: integer);
begin
  // 1. Najdi zadnji odvoz
  // 2. Pridobi podrobnosti posod za stranko (tabela LokacijaPosode)
  // 2. Pripravi odvozpodrobnosti
  wdsOdvoz.Close;
//  wdsOdvoz.QueryString := '$filter=Lokacija/Id eq '+modMain.LokacijaId.ToString+' and Lokacija/Aktivna ne 0&$orderby=Id desc&$top=1&$expand=Podrobno/Posoda,Lokacija/Stranka,Vozilo/TipVozila';
  wdsOdvoz.QueryString := '$filter=Id eq '+modMain.OdvozId.ToString+' and Lokacija/Aktivna ne 0&$orderby=Id desc&$top=1&$expand=Podrobno/Posoda,Lokacija/Stranka,Vozilo/TipVozila';
  wdsOdvoz.Load;
  // TODO: Dodati upoštevanje datumske omejitve od-do
end;

procedure TfrmOdvozInfo.wdsOdvozAfterOpen(DataSet: TDataSet);
begin
  wdsOdvozPodrobno.Close;
  wdsOdvozPodrobno.SetJsonData(wdsOdvoz.FieldByName('Podrobno').Value);
  wdsOdvozPodrobno.Open;
end;

procedure TfrmOdvozInfo.wdsOdvozPodrobnoAfterOpen(DataSet: TDataSet);
begin
  wdsLokacijaPosode.Close;
  wdsLokacijaPosode.QueryString := '$expand=posoda&$filter=posoda/frakcija eq '+modMain.FrakcijaId.ToString+'  and lokacija/id eq '+modMain.LokacijaId.ToString+'&$orderby=posoda/idx';
  //TODO: Dodaj aktivna od-do omejitev
  wdsLokacijaPosode.Load;
end;

procedure TfrmOdvozInfo.wdsLokacijaPosodeAfterOpen(DataSet: TDataSet);
begin
  modMain.log('wdsLokacijaPosode.RecordCount: '+wdsLokacijaPosode.RecordCount.ToString);
  wdsOdvozPodrobno.First;
end;

procedure TfrmOdvozInfo.wdsOdvozPostError(DataSet: TDataSet; E: EDatabaseError; var Action: TDataAction);
begin
  modMain.log(E.Message);
end;

procedure TfrmOdvozInfo.WebFormShow(Sender: TObject);
begin
  inherited;

  gridPodrobno.RowHeights[0] := 70;
  gridPodrobno.ColWidths[0] := Round(gridPodrobno.Width - gridPodrobno.ColWidths[1] - gridPodrobno.ColWidths[2] - gridPodrobno.ColWidths[3])-2;
end;

procedure TfrmOdvozInfo.gridPodrobnoClickCell(Sender: TObject; ACol, ARow: Integer);
begin
  FSelRow := ACol;

  if ACol = 2 then begin
    FAllowNegative := not FAllowNegative;
  end;
end;

procedure TfrmOdvozInfo.ApplyTheme(Dark: boolean);
begin
  inherited;

end;

procedure TfrmOdvozInfo.btnCancelClick(Sender: TObject);
begin
  frmMain.ShowZgodovina;
end;

procedure TfrmOdvozInfo.LoadDFMValues;
begin
  inherited LoadDFMValues;

  panInfo := TPanel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebDBLabel1 := TDBLabel.Create(Self);
  WebDBLabel3 := TDBLabel.Create(Self);
  WebDBLabel5 := TDBLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebDBLabel2 := TDBLabel.Create(Self);
  panPosode := TPanel.Create(Self);
  gridPodrobno := TDBGrid.Create(Self);
  panFooter := TPanel.Create(Self);
  btnCancel := TButton.Create(Self);
  dsLokacijaPosode := TDataSource.Create(Self);
  wdsLokacijaPosode := TXDataWebDataSet.Create(Self);
  dsOdvozPodrobno := TDataSource.Create(Self);
  wdsOdvozPodrobno := TXDataWebDataSet.Create(Self);
  dsOdvoz := TDataSource.Create(Self);
  wdsOdvoz := TXDataWebDataSet.Create(Self);
  wdsOdvozid2 := TIntegerField.Create(Self);
  wdsOdvozVozilo := TXDataWebEntityField.Create(Self);
  wdsOdvozVoziloTipVozila := TXDataWebEntityField.Create(Self);
  wdsOdvozVoziloTipVozilaId := TIntegerField.Create(Self);
  wdsOdvozVoziloTipVozilaOpis := TStringField.Create(Self);
  wdsOdvozVoziloVrstaOdvoza := TXDataWebEntityField.Create(Self);
  wdsOdvozVoziloVrstaOdvozaId := TIntegerField.Create(Self);
  wdsOdvozVoziloVrstaOdvozaOpis := TStringField.Create(Self);
  wdsOdvozVoziloVrstaOdvozaParams := TBlobField.Create(Self);
  wdsOdvozVoziloVrstaOdvozaKolicina := TIntegerField.Create(Self);
  wdsOdvozVoziloVrstaOdvozaVolumen := TIntegerField.Create(Self);
  wdsOdvozVoziloVrstaOdvozaTeza := TIntegerField.Create(Self);
  wdsOdvozVoziloOdvozi := TXDataWebDatasetField.Create(Self);
  wdsOdvozVoziloId := TIntegerField.Create(Self);
  wdsOdvozVoziloRegistrskaSt := TStringField.Create(Self);
  wdsOdvozVoziloAktivno := TBooleanField.Create(Self);
  wdsOdvozVoziloLetnik := TIntegerField.Create(Self);
  wdsOdvozVoziloPrvaRegistracija := TDateTimeField.Create(Self);
  wdsOdvozVoziloZnamka := TStringField.Create(Self);
  wdsOdvozVoziloTip := TStringField.Create(Self);
  wdsOdvozVoziloMocKW := TIntegerField.Create(Self);
  wdsOdvozVoziloProstornina := TIntegerField.Create(Self);
  wdsOdvozVoziloStSasije := TStringField.Create(Self);
  wdsOdvozVoziloMasaVozila := TIntegerField.Create(Self);
  wdsOdvozVoziloMasaNajvecja := TIntegerField.Create(Self);
  wdsOdvozVoziloPrtljaznikVolM3 := TIntegerField.Create(Self);
  wdsOdvozLokacija := TXDataWebEntityField.Create(Self);
  wdsOdvozLokacijaStranka := TXDataWebEntityField.Create(Self);
  wdsOdvozLokacijaStrankaLokacije := TXDataWebDatasetField.Create(Self);
  wdsOdvozLokacijaStrankaId := TIntegerField.Create(Self);
  wdsOdvozLokacijaStrankaZunanjaOznaka := TIntegerField.Create(Self);
  wdsOdvozLokacijaStrankaAktivna := TBooleanField.Create(Self);
  wdsOdvozLokacijaStrankaObracun := TIntegerField.Create(Self);
  wdsOdvozLokacijaStrankaNaziv := TStringField.Create(Self);
  wdsOdvozLokacijaStrankaNaslov := TStringField.Create(Self);
  wdsOdvozLokacijaStrankaPosta := TStringField.Create(Self);
  wdsOdvozLokacijaStrankaKraj := TStringField.Create(Self);
  wdsOdvozLokacijaStrankaDavSt := TStringField.Create(Self);
  wdsOdvozLokacijaStrankaTel := TStringField.Create(Self);
  wdsOdvozLokacijaStrankaOpombe := TStringField.Create(Self);
  wdsOdvozLokacijaOdvozi := TXDataWebDatasetField.Create(Self);
  wdsOdvozLokacijaPosode := TXDataWebDatasetField.Create(Self);
  wdsOdvozLokacijaId := TIntegerField.Create(Self);
  wdsOdvozLokacijaAktivna := TBooleanField.Create(Self);
  wdsOdvozLokacijaNaziv := TStringField.Create(Self);
  wdsOdvozLokacijaOpombe := TStringField.Create(Self);
  wdsOdvozLokacijaLat := TFloatField.Create(Self);
  wdsOdvozLokacijaLon := TFloatField.Create(Self);
  wdsOdvozLokacijaAktivnaOd := TDateTimeField.Create(Self);
  wdsOdvozLokacijaAktivnaDo := TDateTimeField.Create(Self);
  wdsOdvozVoznik := TXDataWebEntityField.Create(Self);
  wdsOdvozVoznikVloga := TXDataWebEntityField.Create(Self);
  wdsOdvozVoznikVlogaId := TIntegerField.Create(Self);
  wdsOdvozVoznikVlogaOpis := TStringField.Create(Self);
  wdsOdvozVoznikId := TIntegerField.Create(Self);
  wdsOdvozVoznikUporabniskoIme := TStringField.Create(Self);
  wdsOdvozVoznikGeslo := TStringField.Create(Self);
  wdsOdvozVoznikPIN := TStringField.Create(Self);
  wdsOdvozVoznikIme := TStringField.Create(Self);
  wdsOdvozVoznikPriimek := TStringField.Create(Self);
  wdsOdvozVoznikAktiven := TBooleanField.Create(Self);
  wdsOdvozPodrobno2 := TXDataWebDatasetField.Create(Self);
  wdsOdvozId := TIntegerField.Create(Self);
  wdsOdvozCasOdvoza := TDateTimeField.Create(Self);
  wdsOdvozPodpisStranke := TBlobField.Create(Self);
  wdsOdvozOpombe := TStringField.Create(Self);
  wdsOdvozPotId := TIntegerField.Create(Self);
  xwcSifranti := TXDataWebClient.Create(Self);

  panInfo.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebDBLabel1.BeforeLoadDFMValues;
  WebDBLabel3.BeforeLoadDFMValues;
  WebDBLabel5.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebDBLabel2.BeforeLoadDFMValues;
  panPosode.BeforeLoadDFMValues;
  gridPodrobno.BeforeLoadDFMValues;
  panFooter.BeforeLoadDFMValues;
  btnCancel.BeforeLoadDFMValues;
  dsLokacijaPosode.BeforeLoadDFMValues;
  wdsLokacijaPosode.BeforeLoadDFMValues;
  dsOdvozPodrobno.BeforeLoadDFMValues;
  wdsOdvozPodrobno.BeforeLoadDFMValues;
  dsOdvoz.BeforeLoadDFMValues;
  wdsOdvoz.BeforeLoadDFMValues;
  wdsOdvozid2.BeforeLoadDFMValues;
  wdsOdvozVozilo.BeforeLoadDFMValues;
  wdsOdvozVoziloTipVozila.BeforeLoadDFMValues;
  wdsOdvozVoziloTipVozilaId.BeforeLoadDFMValues;
  wdsOdvozVoziloTipVozilaOpis.BeforeLoadDFMValues;
  wdsOdvozVoziloVrstaOdvoza.BeforeLoadDFMValues;
  wdsOdvozVoziloVrstaOdvozaId.BeforeLoadDFMValues;
  wdsOdvozVoziloVrstaOdvozaOpis.BeforeLoadDFMValues;
  wdsOdvozVoziloVrstaOdvozaParams.BeforeLoadDFMValues;
  wdsOdvozVoziloVrstaOdvozaKolicina.BeforeLoadDFMValues;
  wdsOdvozVoziloVrstaOdvozaVolumen.BeforeLoadDFMValues;
  wdsOdvozVoziloVrstaOdvozaTeza.BeforeLoadDFMValues;
  wdsOdvozVoziloOdvozi.BeforeLoadDFMValues;
  wdsOdvozVoziloId.BeforeLoadDFMValues;
  wdsOdvozVoziloRegistrskaSt.BeforeLoadDFMValues;
  wdsOdvozVoziloAktivno.BeforeLoadDFMValues;
  wdsOdvozVoziloLetnik.BeforeLoadDFMValues;
  wdsOdvozVoziloPrvaRegistracija.BeforeLoadDFMValues;
  wdsOdvozVoziloZnamka.BeforeLoadDFMValues;
  wdsOdvozVoziloTip.BeforeLoadDFMValues;
  wdsOdvozVoziloMocKW.BeforeLoadDFMValues;
  wdsOdvozVoziloProstornina.BeforeLoadDFMValues;
  wdsOdvozVoziloStSasije.BeforeLoadDFMValues;
  wdsOdvozVoziloMasaVozila.BeforeLoadDFMValues;
  wdsOdvozVoziloMasaNajvecja.BeforeLoadDFMValues;
  wdsOdvozVoziloPrtljaznikVolM3.BeforeLoadDFMValues;
  wdsOdvozLokacija.BeforeLoadDFMValues;
  wdsOdvozLokacijaStranka.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaLokacije.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaId.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaZunanjaOznaka.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaAktivna.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaObracun.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaNaziv.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaNaslov.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaPosta.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaKraj.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaDavSt.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaTel.BeforeLoadDFMValues;
  wdsOdvozLokacijaStrankaOpombe.BeforeLoadDFMValues;
  wdsOdvozLokacijaOdvozi.BeforeLoadDFMValues;
  wdsOdvozLokacijaPosode.BeforeLoadDFMValues;
  wdsOdvozLokacijaId.BeforeLoadDFMValues;
  wdsOdvozLokacijaAktivna.BeforeLoadDFMValues;
  wdsOdvozLokacijaNaziv.BeforeLoadDFMValues;
  wdsOdvozLokacijaOpombe.BeforeLoadDFMValues;
  wdsOdvozLokacijaLat.BeforeLoadDFMValues;
  wdsOdvozLokacijaLon.BeforeLoadDFMValues;
  wdsOdvozLokacijaAktivnaOd.BeforeLoadDFMValues;
  wdsOdvozLokacijaAktivnaDo.BeforeLoadDFMValues;
  wdsOdvozVoznik.BeforeLoadDFMValues;
  wdsOdvozVoznikVloga.BeforeLoadDFMValues;
  wdsOdvozVoznikVlogaId.BeforeLoadDFMValues;
  wdsOdvozVoznikVlogaOpis.BeforeLoadDFMValues;
  wdsOdvozVoznikId.BeforeLoadDFMValues;
  wdsOdvozVoznikUporabniskoIme.BeforeLoadDFMValues;
  wdsOdvozVoznikGeslo.BeforeLoadDFMValues;
  wdsOdvozVoznikPIN.BeforeLoadDFMValues;
  wdsOdvozVoznikIme.BeforeLoadDFMValues;
  wdsOdvozVoznikPriimek.BeforeLoadDFMValues;
  wdsOdvozVoznikAktiven.BeforeLoadDFMValues;
  wdsOdvozPodrobno2.BeforeLoadDFMValues;
  wdsOdvozId.BeforeLoadDFMValues;
  wdsOdvozCasOdvoza.BeforeLoadDFMValues;
  wdsOdvozPodpisStranke.BeforeLoadDFMValues;
  wdsOdvozOpombe.BeforeLoadDFMValues;
  wdsOdvozPotId.BeforeLoadDFMValues;
  xwcSifranti.BeforeLoadDFMValues;
  try
    Name := 'frmOdvozInfo';
    Left := 0;
    Top := 0;
    Color := clBtnFace;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'FormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    panInfo.SetParentComponent(Self);
    panInfo.Name := 'panInfo';
    panInfo.Left := 0;
    panInfo.Top := 0;
    panInfo.Width := 887;
    panInfo.Height := 193;
    panInfo.Align := alTop;
    panInfo.BorderStyle := bsNone;
    panInfo.ChildOrder := 3;
    panInfo.Color := clWhite;
    panInfo.TabOrder := 0;
    WebLabel2.SetParentComponent(panInfo);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 3;
    WebLabel2.Top := 78;
    WebLabel2.Width := 81;
    WebLabel2.Height := 25;
    WebLabel2.Caption := 'Lokacija:';
    WebLabel2.ElementClassName := 'h4';
    WebLabel2.ElementFont := efCSS;
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -21;
    WebLabel2.Font.Name := 'Tahoma';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(panInfo);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 3;
    WebLabel4.Top := 148;
    WebLabel4.Width := 86;
    WebLabel4.Height := 25;
    WebLabel4.Caption := 'Opombe:';
    WebLabel4.ElementClassName := 'h4';
    WebLabel4.ElementFont := efCSS;
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -21;
    WebLabel4.Font.Name := 'Tahoma';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebDBLabel1.SetParentComponent(panInfo);
    WebDBLabel1.Name := 'WebDBLabel1';
    WebDBLabel1.Left := 166;
    WebDBLabel1.Top := 78;
    WebDBLabel1.Width := 129;
    WebDBLabel1.Height := 25;
    WebDBLabel1.Caption := 'WebDBLabel1';
    WebDBLabel1.ElementClassName := 'h4';
    WebDBLabel1.ElementFont := efCSS;
    WebDBLabel1.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel1.Font.Color := clWindowText;
    WebDBLabel1.Font.Height := -21;
    WebDBLabel1.Font.Name := 'Tahoma';
    WebDBLabel1.Font.Style := [];
    WebDBLabel1.HeightPercent := 100.000000000000000000;
    WebDBLabel1.ParentFont := False;
    WebDBLabel1.WidthPercent := 100.000000000000000000;
    WebDBLabel1.DataField := 'Lokacija.Naziv';
    WebDBLabel1.DataSource := dsOdvoz;
    WebDBLabel3.SetParentComponent(panInfo);
    WebDBLabel3.Name := 'WebDBLabel3';
    WebDBLabel3.Left := 166;
    WebDBLabel3.Top := 148;
    WebDBLabel3.Width := 129;
    WebDBLabel3.Height := 25;
    WebDBLabel3.Caption := 'WebDBLabel1';
    WebDBLabel3.ElementClassName := 'h4';
    WebDBLabel3.ElementFont := efCSS;
    WebDBLabel3.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel3.Font.Color := clWindowText;
    WebDBLabel3.Font.Height := -21;
    WebDBLabel3.Font.Name := 'Tahoma';
    WebDBLabel3.Font.Style := [];
    WebDBLabel3.HeightPercent := 100.000000000000000000;
    WebDBLabel3.ParentFont := False;
    WebDBLabel3.WidthPercent := 100.000000000000000000;
    WebDBLabel3.DataField := 'Opombe';
    WebDBLabel3.DataSource := dsOdvoz;
    WebDBLabel5.SetParentComponent(panInfo);
    WebDBLabel5.Name := 'WebDBLabel5';
    WebDBLabel5.Left := 3;
    WebDBLabel5.Top := 3;
    WebDBLabel5.Width := 143;
    WebDBLabel5.Height := 40;
    WebDBLabel5.Caption := 'lblStranka';
    WebDBLabel5.ElementClassName := 'display-3';
    WebDBLabel5.ElementFont := efCSS;
    WebDBLabel5.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel5.Font.Color := clWindowText;
    WebDBLabel5.Font.Height := -33;
    WebDBLabel5.Font.Name := 'Tahoma';
    WebDBLabel5.Font.Style := [];
    WebDBLabel5.HeightPercent := 100.000000000000000000;
    WebDBLabel5.ParentFont := False;
    WebDBLabel5.WidthPercent := 100.000000000000000000;
    WebDBLabel5.DataField := 'Lokacija.Stranka.Naziv';
    WebDBLabel5.DataSource := dsOdvoz;
    WebLabel3.SetParentComponent(panInfo);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 3;
    WebLabel3.Top := 109;
    WebLabel3.Width := 140;
    WebLabel3.Height := 25;
    WebLabel3.Caption := 'Datum odvoza:';
    WebLabel3.ElementClassName := 'h4';
    WebLabel3.ElementFont := efCSS;
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -21;
    WebLabel3.Font.Name := 'Tahoma';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightStyle := ssAuto;
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebDBLabel2.SetParentComponent(panInfo);
    WebDBLabel2.Name := 'WebDBLabel2';
    WebDBLabel2.Left := 166;
    WebDBLabel2.Top := 109;
    WebDBLabel2.Width := 129;
    WebDBLabel2.Height := 25;
    WebDBLabel2.Caption := 'WebDBLabel1';
    WebDBLabel2.ElementClassName := 'h4';
    WebDBLabel2.ElementFont := efCSS;
    WebDBLabel2.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel2.Font.Color := clWindowText;
    WebDBLabel2.Font.Height := -21;
    WebDBLabel2.Font.Name := 'Tahoma';
    WebDBLabel2.Font.Style := [];
    WebDBLabel2.HeightStyle := ssAuto;
    WebDBLabel2.HeightPercent := 100.000000000000000000;
    WebDBLabel2.ParentFont := False;
    WebDBLabel2.WidthPercent := 100.000000000000000000;
    WebDBLabel2.DataField := 'CasOdvoza';
    WebDBLabel2.DataSource := dsOdvoz;
    panPosode.SetParentComponent(Self);
    panPosode.Name := 'panPosode';
    panPosode.Left := 0;
    panPosode.Top := 193;
    panPosode.Width := 887;
    panPosode.Height := 632;
    panPosode.Align := alClient;
    panPosode.BorderStyle := bsNone;
    panPosode.ChildOrder := 3;
    panPosode.Color := clWhite;
    panPosode.TabOrder := 1;
    gridPodrobno.SetParentComponent(panPosode);
    gridPodrobno.Name := 'gridPodrobno';
    gridPodrobno.Left := 0;
    gridPodrobno.Top := 0;
    gridPodrobno.Width := 887;
    gridPodrobno.Height := 632;
    gridPodrobno.Align := alClient;
    gridPodrobno.Color := clWhite;
    gridPodrobno.Columns.Clear;
    with gridPodrobno.Columns.Add do
    begin
      DataField := 'Posoda.Opis';
      Title := 'Posoda';
      Width := 380;
    end;
    with gridPodrobno.Columns.Add do
    begin
      Editor := geNone;
      Width := 120;
    end;
    with gridPodrobno.Columns.Add do
    begin
      Alignment := taCenter;
      ElementClassName := 'text-center';
      DataField := 'Kolicina';
      Editor := geNone;
      Title := 'Kol.';
      Width := 70;
    end;
    with gridPodrobno.Columns.Add do
    begin
      Editor := geNone;
      Width := 40;
    end;
    gridPodrobno.DataSource := dsOdvozPodrobno;
    gridPodrobno.ElementFont := efCSS;
    gridPodrobno.ElementClassName := 'display-1';
    gridPodrobno.FixedFont.Charset := DEFAULT_CHARSET;
    gridPodrobno.FixedFont.Color := clWindowText;
    gridPodrobno.FixedFont.Height := -12;
    gridPodrobno.FixedFont.Name := 'Segoe UI';
    gridPodrobno.FixedFont.Style := [];
    gridPodrobno.FixedCols := 0;
    gridPodrobno.FixedRows := 0;
    gridPodrobno.Font.Charset := DEFAULT_CHARSET;
    gridPodrobno.Font.Color := clWindowText;
    gridPodrobno.Font.Height := -80;
    gridPodrobno.Font.Name := 'Tahoma';
    gridPodrobno.Font.Style := [];
    gridPodrobno.Options := [goRowSelect];
    gridPodrobno.ParentFont := False;
    gridPodrobno.TabOrder := 0;
    gridPodrobno.HeightPercent := 100.000000000000000000;
    gridPodrobno.ShowIndicator := False;
    gridPodrobno.WidthPercent := 100.000000000000000000;
    SetEvent(gridPodrobno, Self, 'OnClickCell', 'gridPodrobnoClickCell');
    gridPodrobno.ColWidths[0] := 380;
    gridPodrobno.ColWidths[1] := 120;
    gridPodrobno.ColWidths[2] := 70;
    gridPodrobno.ColWidths[3] := 40;
    panFooter.SetParentComponent(Self);
    panFooter.Name := 'panFooter';
    panFooter.Left := 0;
    panFooter.Top := 825;
    panFooter.Width := 887;
    panFooter.Height := 120;
    panFooter.Align := alBottom;
    panFooter.BorderColor := clNone;
    panFooter.BorderStyle := bsNone;
    panFooter.ChildOrder := 3;
    panFooter.Color := clWhite;
    panFooter.TabOrder := 2;
    btnCancel.SetParentComponent(panFooter);
    btnCancel.Name := 'btnCancel';
    btnCancel.AlignWithMargins := True;
    btnCancel.Left := 10;
    btnCancel.Top := 3;
    btnCancel.Width := 100;
    btnCancel.Height := 114;
    btnCancel.Margins.Left := 10;
    btnCancel.Margins.Right := 10;
    btnCancel.Align := alLeft;
    btnCancel.Caption := '<i class="material-icons" style="font-size:40px;">close</i>';
    btnCancel.ChildOrder := 1;
    btnCancel.ElementClassName := 'btn btn-danger';
    btnCancel.ElementFont := efCSS;
    btnCancel.HeightPercent := 100.000000000000000000;
    btnCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCancel, Self, 'OnClick', 'btnCancelClick');
    dsLokacijaPosode.SetParentComponent(Self);
    dsLokacijaPosode.Name := 'dsLokacijaPosode';
    dsLokacijaPosode.DataSet := wdsLokacijaPosode;
    dsLokacijaPosode.Left := 712;
    dsLokacijaPosode.Top := 104;
    wdsLokacijaPosode.SetParentComponent(Self);
    wdsLokacijaPosode.Name := 'wdsLokacijaPosode';
    wdsLokacijaPosode.AfterOpen := wdsLokacijaPosodeAfterOpen;
    SetEvent(wdsLokacijaPosode, Self, 'OnEditError', 'wdsOdvozPostError');
    wdsLokacijaPosode.EntitySetName := 'Lokacijaposode';
    wdsLokacijaPosode.Connection := modMain.connServer;
    wdsLokacijaPosode.SubpropsDepth := 2;
    wdsLokacijaPosode.QueryString := 'expand=posoda&$filter=lokacija/lastnik/id%20eq%202';
    wdsLokacijaPosode.Left := 712;
    wdsLokacijaPosode.Top := 48;
    dsOdvozPodrobno.SetParentComponent(Self);
    dsOdvozPodrobno.Name := 'dsOdvozPodrobno';
    dsOdvozPodrobno.DataSet := wdsOdvozPodrobno;
    dsOdvozPodrobno.Left := 592;
    dsOdvozPodrobno.Top := 104;
    wdsOdvozPodrobno.SetParentComponent(Self);
    wdsOdvozPodrobno.Name := 'wdsOdvozPodrobno';
    wdsOdvozPodrobno.AfterOpen := wdsOdvozPodrobnoAfterOpen;
    SetEvent(wdsOdvozPodrobno, Self, 'OnEditError', 'wdsOdvozPostError');
    SetEvent(wdsOdvozPodrobno, Self, 'OnPostError', 'wdsOdvozPostError');
    wdsOdvozPodrobno.EntitySetName := 'Odvozpodrobno';
    wdsOdvozPodrobno.Connection := modMain.connServer;
    wdsOdvozPodrobno.SubpropsDepth := 1;
    wdsOdvozPodrobno.QueryString := 'filter=Id%20eq%204&$orderby=Id%20desc&$top=1&$expand=Posoda';
    wdsOdvozPodrobno.Left := 592;
    wdsOdvozPodrobno.Top := 48;
    dsOdvoz.SetParentComponent(Self);
    dsOdvoz.Name := 'dsOdvoz';
    dsOdvoz.DataSet := wdsOdvoz;
    dsOdvoz.Left := 496;
    dsOdvoz.Top := 104;
    wdsOdvoz.SetParentComponent(Self);
    wdsOdvoz.Name := 'wdsOdvoz';
    wdsOdvoz.AfterOpen := wdsOdvozAfterOpen;
    SetEvent(wdsOdvoz, Self, 'OnEditError', 'wdsOdvozPostError');
    SetEvent(wdsOdvoz, Self, 'OnPostError', 'wdsOdvozPostError');
    wdsOdvoz.EntitySetName := 'Odvoz';
    wdsOdvoz.Connection := modMain.connServer;
    wdsOdvoz.SubpropsDepth := 3;
    wdsOdvoz.QueryString := 'orderby=Id desc&$top=1&$expand=Podrobno/Posoda,Lokacija/Stranka,Vozilo/VrstaVozila'#39';';
    wdsOdvoz.QueryTop := 1;
    wdsOdvoz.Left := 496;
    wdsOdvoz.Top := 48;
    wdsOdvozid2.SetParentComponent(wdsOdvoz);
    wdsOdvozid2.Name := 'wdsOdvozid2';
    wdsOdvozid2.FieldName := '$id';
    wdsOdvozVozilo.SetParentComponent(wdsOdvoz);
    wdsOdvozVozilo.Name := 'wdsOdvozVozilo';
    wdsOdvozVozilo.FieldName := 'Vozilo';
    wdsOdvozVozilo.Required := True;
    wdsOdvozVoziloTipVozila.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloTipVozila.Name := 'wdsOdvozVoziloTipVozila';
    wdsOdvozVoziloTipVozila.FieldName := 'Vozilo.TipVozila';
    wdsOdvozVoziloTipVozilaId.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloTipVozilaId.Name := 'wdsOdvozVoziloTipVozilaId';
    wdsOdvozVoziloTipVozilaId.FieldName := 'Vozilo.TipVozila.Id';
    wdsOdvozVoziloTipVozilaOpis.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloTipVozilaOpis.Name := 'wdsOdvozVoziloTipVozilaOpis';
    wdsOdvozVoziloTipVozilaOpis.FieldName := 'Vozilo.TipVozila.Opis';
    wdsOdvozVoziloTipVozilaOpis.Size := 50;
    wdsOdvozVoziloVrstaOdvoza.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloVrstaOdvoza.Name := 'wdsOdvozVoziloVrstaOdvoza';
    wdsOdvozVoziloVrstaOdvoza.FieldName := 'Vozilo.VrstaOdvoza';
    wdsOdvozVoziloVrstaOdvozaId.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloVrstaOdvozaId.Name := 'wdsOdvozVoziloVrstaOdvozaId';
    wdsOdvozVoziloVrstaOdvozaId.FieldName := 'Vozilo.VrstaOdvoza.Id';
    wdsOdvozVoziloVrstaOdvozaOpis.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloVrstaOdvozaOpis.Name := 'wdsOdvozVoziloVrstaOdvozaOpis';
    wdsOdvozVoziloVrstaOdvozaOpis.FieldName := 'Vozilo.VrstaOdvoza.Opis';
    wdsOdvozVoziloVrstaOdvozaOpis.Size := 50;
    wdsOdvozVoziloVrstaOdvozaParams.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloVrstaOdvozaParams.Name := 'wdsOdvozVoziloVrstaOdvozaParams';
    wdsOdvozVoziloVrstaOdvozaParams.FieldName := 'Vozilo.VrstaOdvoza.Params';
    wdsOdvozVoziloVrstaOdvozaParams.Size := 250;
    wdsOdvozVoziloVrstaOdvozaKolicina.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloVrstaOdvozaKolicina.Name := 'wdsOdvozVoziloVrstaOdvozaKolicina';
    wdsOdvozVoziloVrstaOdvozaKolicina.FieldName := 'Vozilo.VrstaOdvoza.Kolicina';
    wdsOdvozVoziloVrstaOdvozaVolumen.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloVrstaOdvozaVolumen.Name := 'wdsOdvozVoziloVrstaOdvozaVolumen';
    wdsOdvozVoziloVrstaOdvozaVolumen.FieldName := 'Vozilo.VrstaOdvoza.Volumen';
    wdsOdvozVoziloVrstaOdvozaTeza.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloVrstaOdvozaTeza.Name := 'wdsOdvozVoziloVrstaOdvozaTeza';
    wdsOdvozVoziloVrstaOdvozaTeza.FieldName := 'Vozilo.VrstaOdvoza.Teza';
    wdsOdvozVoziloOdvozi.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloOdvozi.Name := 'wdsOdvozVoziloOdvozi';
    wdsOdvozVoziloOdvozi.FieldName := 'Vozilo.Odvozi';
    wdsOdvozVoziloOdvozi.ReadOnly := True;
    wdsOdvozVoziloId.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloId.Name := 'wdsOdvozVoziloId';
    wdsOdvozVoziloId.FieldName := 'Vozilo.Id';
    wdsOdvozVoziloRegistrskaSt.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloRegistrskaSt.Name := 'wdsOdvozVoziloRegistrskaSt';
    wdsOdvozVoziloRegistrskaSt.FieldName := 'Vozilo.RegistrskaSt';
    wdsOdvozVoziloRegistrskaSt.Size := 50;
    wdsOdvozVoziloAktivno.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloAktivno.Name := 'wdsOdvozVoziloAktivno';
    wdsOdvozVoziloAktivno.FieldName := 'Vozilo.Aktivno';
    wdsOdvozVoziloLetnik.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloLetnik.Name := 'wdsOdvozVoziloLetnik';
    wdsOdvozVoziloLetnik.FieldName := 'Vozilo.Letnik';
    wdsOdvozVoziloPrvaRegistracija.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloPrvaRegistracija.Name := 'wdsOdvozVoziloPrvaRegistracija';
    wdsOdvozVoziloPrvaRegistracija.FieldName := 'Vozilo.PrvaRegistracija';
    wdsOdvozVoziloZnamka.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloZnamka.Name := 'wdsOdvozVoziloZnamka';
    wdsOdvozVoziloZnamka.FieldName := 'Vozilo.Znamka';
    wdsOdvozVoziloZnamka.Size := 50;
    wdsOdvozVoziloTip.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloTip.Name := 'wdsOdvozVoziloTip';
    wdsOdvozVoziloTip.FieldName := 'Vozilo.Tip';
    wdsOdvozVoziloTip.Size := 50;
    wdsOdvozVoziloMocKW.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloMocKW.Name := 'wdsOdvozVoziloMocKW';
    wdsOdvozVoziloMocKW.FieldName := 'Vozilo.MocKW';
    wdsOdvozVoziloProstornina.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloProstornina.Name := 'wdsOdvozVoziloProstornina';
    wdsOdvozVoziloProstornina.FieldName := 'Vozilo.Prostornina';
    wdsOdvozVoziloStSasije.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloStSasije.Name := 'wdsOdvozVoziloStSasije';
    wdsOdvozVoziloStSasije.FieldName := 'Vozilo.StSasije';
    wdsOdvozVoziloStSasije.Size := 50;
    wdsOdvozVoziloMasaVozila.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloMasaVozila.Name := 'wdsOdvozVoziloMasaVozila';
    wdsOdvozVoziloMasaVozila.FieldName := 'Vozilo.MasaVozila';
    wdsOdvozVoziloMasaNajvecja.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloMasaNajvecja.Name := 'wdsOdvozVoziloMasaNajvecja';
    wdsOdvozVoziloMasaNajvecja.FieldName := 'Vozilo.MasaNajvecja';
    wdsOdvozVoziloPrtljaznikVolM3.SetParentComponent(wdsOdvoz);
    wdsOdvozVoziloPrtljaznikVolM3.Name := 'wdsOdvozVoziloPrtljaznikVolM3';
    wdsOdvozVoziloPrtljaznikVolM3.FieldName := 'Vozilo.PrtljaznikVolM3';
    wdsOdvozLokacija.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacija.Name := 'wdsOdvozLokacija';
    wdsOdvozLokacija.FieldName := 'Lokacija';
    wdsOdvozLokacija.Required := True;
    wdsOdvozLokacijaStranka.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStranka.Name := 'wdsOdvozLokacijaStranka';
    wdsOdvozLokacijaStranka.FieldName := 'Lokacija.Stranka';
    wdsOdvozLokacijaStrankaLokacije.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaLokacije.Name := 'wdsOdvozLokacijaStrankaLokacije';
    wdsOdvozLokacijaStrankaLokacije.FieldName := 'Lokacija.Stranka.Lokacije';
    wdsOdvozLokacijaStrankaLokacije.ReadOnly := True;
    wdsOdvozLokacijaStrankaId.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaId.Name := 'wdsOdvozLokacijaStrankaId';
    wdsOdvozLokacijaStrankaId.FieldName := 'Lokacija.Stranka.Id';
    wdsOdvozLokacijaStrankaZunanjaOznaka.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaZunanjaOznaka.Name := 'wdsOdvozLokacijaStrankaZunanjaOznaka';
    wdsOdvozLokacijaStrankaZunanjaOznaka.FieldName := 'Lokacija.Stranka.ZunanjaOznaka';
    wdsOdvozLokacijaStrankaAktivna.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaAktivna.Name := 'wdsOdvozLokacijaStrankaAktivna';
    wdsOdvozLokacijaStrankaAktivna.FieldName := 'Lokacija.Stranka.Aktivna';
    wdsOdvozLokacijaStrankaObracun.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaObracun.Name := 'wdsOdvozLokacijaStrankaObracun';
    wdsOdvozLokacijaStrankaObracun.FieldName := 'Lokacija.Stranka.Obracun';
    wdsOdvozLokacijaStrankaNaziv.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaNaziv.Name := 'wdsOdvozLokacijaStrankaNaziv';
    wdsOdvozLokacijaStrankaNaziv.FieldName := 'Lokacija.Stranka.Naziv';
    wdsOdvozLokacijaStrankaNaziv.Size := 50;
    wdsOdvozLokacijaStrankaNaslov.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaNaslov.Name := 'wdsOdvozLokacijaStrankaNaslov';
    wdsOdvozLokacijaStrankaNaslov.FieldName := 'Lokacija.Stranka.Naslov';
    wdsOdvozLokacijaStrankaNaslov.Size := 50;
    wdsOdvozLokacijaStrankaPosta.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaPosta.Name := 'wdsOdvozLokacijaStrankaPosta';
    wdsOdvozLokacijaStrankaPosta.FieldName := 'Lokacija.Stranka.Posta';
    wdsOdvozLokacijaStrankaPosta.Size := 15;
    wdsOdvozLokacijaStrankaKraj.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaKraj.Name := 'wdsOdvozLokacijaStrankaKraj';
    wdsOdvozLokacijaStrankaKraj.FieldName := 'Lokacija.Stranka.Kraj';
    wdsOdvozLokacijaStrankaKraj.Size := 50;
    wdsOdvozLokacijaStrankaDavSt.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaDavSt.Name := 'wdsOdvozLokacijaStrankaDavSt';
    wdsOdvozLokacijaStrankaDavSt.FieldName := 'Lokacija.Stranka.DavSt';
    wdsOdvozLokacijaStrankaDavSt.Size := 15;
    wdsOdvozLokacijaStrankaTel.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaTel.Name := 'wdsOdvozLokacijaStrankaTel';
    wdsOdvozLokacijaStrankaTel.FieldName := 'Lokacija.Stranka.Tel';
    wdsOdvozLokacijaStrankaOpombe.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaStrankaOpombe.Name := 'wdsOdvozLokacijaStrankaOpombe';
    wdsOdvozLokacijaStrankaOpombe.FieldName := 'Lokacija.Stranka.Opombe';
    wdsOdvozLokacijaStrankaOpombe.Size := 250;
    wdsOdvozLokacijaOdvozi.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaOdvozi.Name := 'wdsOdvozLokacijaOdvozi';
    wdsOdvozLokacijaOdvozi.FieldName := 'Lokacija.Odvozi';
    wdsOdvozLokacijaOdvozi.ReadOnly := True;
    wdsOdvozLokacijaPosode.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaPosode.Name := 'wdsOdvozLokacijaPosode';
    wdsOdvozLokacijaPosode.FieldName := 'Lokacija.Posode';
    wdsOdvozLokacijaPosode.ReadOnly := True;
    wdsOdvozLokacijaId.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaId.Name := 'wdsOdvozLokacijaId';
    wdsOdvozLokacijaId.FieldName := 'Lokacija.Id';
    wdsOdvozLokacijaAktivna.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaAktivna.Name := 'wdsOdvozLokacijaAktivna';
    wdsOdvozLokacijaAktivna.FieldName := 'Lokacija.Aktivna';
    wdsOdvozLokacijaNaziv.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaNaziv.Name := 'wdsOdvozLokacijaNaziv';
    wdsOdvozLokacijaNaziv.FieldName := 'Lokacija.Naziv';
    wdsOdvozLokacijaNaziv.Size := 250;
    wdsOdvozLokacijaOpombe.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaOpombe.Name := 'wdsOdvozLokacijaOpombe';
    wdsOdvozLokacijaOpombe.FieldName := 'Lokacija.Opombe';
    wdsOdvozLokacijaOpombe.Size := 250;
    wdsOdvozLokacijaLat.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaLat.Name := 'wdsOdvozLokacijaLat';
    wdsOdvozLokacijaLat.FieldName := 'Lokacija.Lat';
    wdsOdvozLokacijaLon.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaLon.Name := 'wdsOdvozLokacijaLon';
    wdsOdvozLokacijaLon.FieldName := 'Lokacija.Lon';
    wdsOdvozLokacijaAktivnaOd.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaAktivnaOd.Name := 'wdsOdvozLokacijaAktivnaOd';
    wdsOdvozLokacijaAktivnaOd.FieldName := 'Lokacija.AktivnaOd';
    wdsOdvozLokacijaAktivnaDo.SetParentComponent(wdsOdvoz);
    wdsOdvozLokacijaAktivnaDo.Name := 'wdsOdvozLokacijaAktivnaDo';
    wdsOdvozLokacijaAktivnaDo.FieldName := 'Lokacija.AktivnaDo';
    wdsOdvozVoznik.SetParentComponent(wdsOdvoz);
    wdsOdvozVoznik.Name := 'wdsOdvozVoznik';
    wdsOdvozVoznik.FieldName := 'Voznik';
    wdsOdvozVoznikVloga.SetParentComponent(wdsOdvoz);
    wdsOdvozVoznikVloga.Name := 'wdsOdvozVoznikVloga';
    wdsOdvozVoznikVloga.FieldName := 'Voznik.Vloga';
    wdsOdvozVoznikVlogaId.SetParentComponent(wdsOdvoz);
    wdsOdvozVoznikVlogaId.Name := 'wdsOdvozVoznikVlogaId';
    wdsOdvozVoznikVlogaId.FieldName := 'Voznik.Vloga.Id';
    wdsOdvozVoznikVlogaOpis.SetParentComponent(wdsOdvoz);
    wdsOdvozVoznikVlogaOpis.Name := 'wdsOdvozVoznikVlogaOpis';
    wdsOdvozVoznikVlogaOpis.FieldName := 'Voznik.Vloga.Opis';
    wdsOdvozVoznikVlogaOpis.Size := 50;
    wdsOdvozVoznikId.SetParentComponent(wdsOdvoz);
    wdsOdvozVoznikId.Name := 'wdsOdvozVoznikId';
    wdsOdvozVoznikId.FieldName := 'Voznik.Id';
    wdsOdvozVoznikUporabniskoIme.SetParentComponent(wdsOdvoz);
    wdsOdvozVoznikUporabniskoIme.Name := 'wdsOdvozVoznikUporabniskoIme';
    wdsOdvozVoznikUporabniskoIme.FieldName := 'Voznik.UporabniskoIme';
    wdsOdvozVoznikUporabniskoIme.Size := 50;
    wdsOdvozVoznikGeslo.SetParentComponent(wdsOdvoz);
    wdsOdvozVoznikGeslo.Name := 'wdsOdvozVoznikGeslo';
    wdsOdvozVoznikGeslo.FieldName := 'Voznik.Geslo';
    wdsOdvozVoznikGeslo.Size := 50;
    wdsOdvozVoznikPIN.SetParentComponent(wdsOdvoz);
    wdsOdvozVoznikPIN.Name := 'wdsOdvozVoznikPIN';
    wdsOdvozVoznikPIN.FieldName := 'Voznik.PIN';
    wdsOdvozVoznikPIN.Size := 10;
    wdsOdvozVoznikIme.SetParentComponent(wdsOdvoz);
    wdsOdvozVoznikIme.Name := 'wdsOdvozVoznikIme';
    wdsOdvozVoznikIme.FieldName := 'Voznik.Ime';
    wdsOdvozVoznikIme.Size := 50;
    wdsOdvozVoznikPriimek.SetParentComponent(wdsOdvoz);
    wdsOdvozVoznikPriimek.Name := 'wdsOdvozVoznikPriimek';
    wdsOdvozVoznikPriimek.FieldName := 'Voznik.Priimek';
    wdsOdvozVoznikPriimek.Size := 50;
    wdsOdvozVoznikAktiven.SetParentComponent(wdsOdvoz);
    wdsOdvozVoznikAktiven.Name := 'wdsOdvozVoznikAktiven';
    wdsOdvozVoznikAktiven.FieldName := 'Voznik.Aktiven';
    wdsOdvozPodrobno2.SetParentComponent(wdsOdvoz);
    wdsOdvozPodrobno2.Name := 'wdsOdvozPodrobno2';
    wdsOdvozPodrobno2.FieldName := 'Podrobno';
    wdsOdvozPodrobno2.ReadOnly := True;
    wdsOdvozId.SetParentComponent(wdsOdvoz);
    wdsOdvozId.Name := 'wdsOdvozId';
    wdsOdvozId.FieldName := 'Id';
    wdsOdvozId.Required := True;
    wdsOdvozCasOdvoza.SetParentComponent(wdsOdvoz);
    wdsOdvozCasOdvoza.Name := 'wdsOdvozCasOdvoza';
    wdsOdvozCasOdvoza.FieldName := 'CasOdvoza';
    wdsOdvozCasOdvoza.DisplayFormat := 'dd.mm.yyyy hh:nn';
    wdsOdvozPodpisStranke.SetParentComponent(wdsOdvoz);
    wdsOdvozPodpisStranke.Name := 'wdsOdvozPodpisStranke';
    wdsOdvozPodpisStranke.FieldName := 'PodpisStranke';
    wdsOdvozOpombe.SetParentComponent(wdsOdvoz);
    wdsOdvozOpombe.Name := 'wdsOdvozOpombe';
    wdsOdvozOpombe.FieldName := 'Opombe';
    wdsOdvozOpombe.Size := 50;
    wdsOdvozPotId.SetParentComponent(wdsOdvoz);
    wdsOdvozPotId.Name := 'wdsOdvozPotId';
    wdsOdvozPotId.FieldName := 'PotId';
    xwcSifranti.SetParentComponent(Self);
    xwcSifranti.Name := 'xwcSifranti';
    xwcSifranti.Connection := modMain.connServer;
    xwcSifranti.Left := 816;
    xwcSifranti.Top := 48;
  finally
    panInfo.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebDBLabel1.AfterLoadDFMValues;
    WebDBLabel3.AfterLoadDFMValues;
    WebDBLabel5.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebDBLabel2.AfterLoadDFMValues;
    panPosode.AfterLoadDFMValues;
    gridPodrobno.AfterLoadDFMValues;
    panFooter.AfterLoadDFMValues;
    btnCancel.AfterLoadDFMValues;
    dsLokacijaPosode.AfterLoadDFMValues;
    wdsLokacijaPosode.AfterLoadDFMValues;
    dsOdvozPodrobno.AfterLoadDFMValues;
    wdsOdvozPodrobno.AfterLoadDFMValues;
    dsOdvoz.AfterLoadDFMValues;
    wdsOdvoz.AfterLoadDFMValues;
    wdsOdvozid2.AfterLoadDFMValues;
    wdsOdvozVozilo.AfterLoadDFMValues;
    wdsOdvozVoziloTipVozila.AfterLoadDFMValues;
    wdsOdvozVoziloTipVozilaId.AfterLoadDFMValues;
    wdsOdvozVoziloTipVozilaOpis.AfterLoadDFMValues;
    wdsOdvozVoziloVrstaOdvoza.AfterLoadDFMValues;
    wdsOdvozVoziloVrstaOdvozaId.AfterLoadDFMValues;
    wdsOdvozVoziloVrstaOdvozaOpis.AfterLoadDFMValues;
    wdsOdvozVoziloVrstaOdvozaParams.AfterLoadDFMValues;
    wdsOdvozVoziloVrstaOdvozaKolicina.AfterLoadDFMValues;
    wdsOdvozVoziloVrstaOdvozaVolumen.AfterLoadDFMValues;
    wdsOdvozVoziloVrstaOdvozaTeza.AfterLoadDFMValues;
    wdsOdvozVoziloOdvozi.AfterLoadDFMValues;
    wdsOdvozVoziloId.AfterLoadDFMValues;
    wdsOdvozVoziloRegistrskaSt.AfterLoadDFMValues;
    wdsOdvozVoziloAktivno.AfterLoadDFMValues;
    wdsOdvozVoziloLetnik.AfterLoadDFMValues;
    wdsOdvozVoziloPrvaRegistracija.AfterLoadDFMValues;
    wdsOdvozVoziloZnamka.AfterLoadDFMValues;
    wdsOdvozVoziloTip.AfterLoadDFMValues;
    wdsOdvozVoziloMocKW.AfterLoadDFMValues;
    wdsOdvozVoziloProstornina.AfterLoadDFMValues;
    wdsOdvozVoziloStSasije.AfterLoadDFMValues;
    wdsOdvozVoziloMasaVozila.AfterLoadDFMValues;
    wdsOdvozVoziloMasaNajvecja.AfterLoadDFMValues;
    wdsOdvozVoziloPrtljaznikVolM3.AfterLoadDFMValues;
    wdsOdvozLokacija.AfterLoadDFMValues;
    wdsOdvozLokacijaStranka.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaLokacije.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaId.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaZunanjaOznaka.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaAktivna.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaObracun.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaNaziv.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaNaslov.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaPosta.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaKraj.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaDavSt.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaTel.AfterLoadDFMValues;
    wdsOdvozLokacijaStrankaOpombe.AfterLoadDFMValues;
    wdsOdvozLokacijaOdvozi.AfterLoadDFMValues;
    wdsOdvozLokacijaPosode.AfterLoadDFMValues;
    wdsOdvozLokacijaId.AfterLoadDFMValues;
    wdsOdvozLokacijaAktivna.AfterLoadDFMValues;
    wdsOdvozLokacijaNaziv.AfterLoadDFMValues;
    wdsOdvozLokacijaOpombe.AfterLoadDFMValues;
    wdsOdvozLokacijaLat.AfterLoadDFMValues;
    wdsOdvozLokacijaLon.AfterLoadDFMValues;
    wdsOdvozLokacijaAktivnaOd.AfterLoadDFMValues;
    wdsOdvozLokacijaAktivnaDo.AfterLoadDFMValues;
    wdsOdvozVoznik.AfterLoadDFMValues;
    wdsOdvozVoznikVloga.AfterLoadDFMValues;
    wdsOdvozVoznikVlogaId.AfterLoadDFMValues;
    wdsOdvozVoznikVlogaOpis.AfterLoadDFMValues;
    wdsOdvozVoznikId.AfterLoadDFMValues;
    wdsOdvozVoznikUporabniskoIme.AfterLoadDFMValues;
    wdsOdvozVoznikGeslo.AfterLoadDFMValues;
    wdsOdvozVoznikPIN.AfterLoadDFMValues;
    wdsOdvozVoznikIme.AfterLoadDFMValues;
    wdsOdvozVoznikPriimek.AfterLoadDFMValues;
    wdsOdvozVoznikAktiven.AfterLoadDFMValues;
    wdsOdvozPodrobno2.AfterLoadDFMValues;
    wdsOdvozId.AfterLoadDFMValues;
    wdsOdvozCasOdvoza.AfterLoadDFMValues;
    wdsOdvozPodpisStranke.AfterLoadDFMValues;
    wdsOdvozOpombe.AfterLoadDFMValues;
    wdsOdvozPotId.AfterLoadDFMValues;
    xwcSifranti.AfterLoadDFMValues;
  end;
end;

end.
