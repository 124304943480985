unit Frakcija;

interface

uses
  System.SysUtils, System.Variants, System.Classes, Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.StdCtrls, TerminalForm,
  WEBLib.StdCtrls, WEBLib.ExtCtrls, Data.DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.Controls, WEBLib.Grids, WEBLib.DBCtrls, WEBLib.Forms,
  XData.Web.Connection, Vcl.Grids;

type
  TfrmFrakcija = class(TTerminalForm)
    tmrFind: TTimer;
    wdsFrakcija: TXDataWebDataSet;
    dsFrakcija: TDataSource;
    panFooter: TPanel;
    btnSelect: TButton;
    gridView: TDBGrid;
    btnCancel: TButton;
    procedure tmrFindTimer(Sender: TObject);
    procedure edtFilterChange(Sender: TObject);
    procedure tblStrankeDblClick(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure wdsFrakcijaAfterOpen(DataSet: TDataSet);
    procedure btnCancelClick(Sender: TObject);
  private
    { Private declarations }
    procedure ApplyTheme(Dark: boolean); reintroduce;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses Client.Core, Main, Client.Settings;

procedure TfrmFrakcija.ApplyTheme(Dark: boolean);
begin
  inherited;

end;

procedure TfrmFrakcija.btnCancelClick(Sender: TObject);
begin
  frmMain.ShowVozila;
end;

procedure TfrmFrakcija.edtFilterChange(Sender: TObject);
begin
  tmrFind.Enabled := false;
  tmrFind.Enabled := true;
end;

procedure TfrmFrakcija.tblStrankeDblClick(Sender: TObject);
begin
  modMain.NastaviFrakcijo(wdsFrakcija.FieldByName('Id').AsInteger);
  frmMain.ShowLokacije;
end;

procedure TfrmFrakcija.tmrFindTimer(Sender: TObject);
begin
  tmrFind.Enabled := false;
  wdsFrakcija.Close;
  wdsFrakcija.QueryString := '$filter=Aktivno eq 1 and (Tip eq 1) or (Tip eq 2)&$orderby=Opis';
  wdsFrakcija.Load;
  gridView.Columns[0].Width := gridView.Width;
end;

procedure TfrmFrakcija.wdsFrakcijaAfterOpen(DataSet: TDataSet);
var FrakcijaId: integer;
begin
  btnSelect.Enabled := DataSet.RecordCount > 0;

  FrakcijaId := StrToIntDef(modMain.GetVar('FrakcijaId'),0);
  if FrakcijaId>0 then
    wdsFrakcija.Locate('Id', FrakcijaId, []);
end;

procedure TfrmFrakcija.WebFormShow(Sender: TObject);
begin
  inherited;

  gridView.ColWidths[0] := frmMain.Width - 30;

  ApplyTheme(AppSettings.DarkMode);
end;

procedure TfrmFrakcija.LoadDFMValues;
begin
  inherited LoadDFMValues;

  panFooter := TPanel.Create(Self);
  btnSelect := TButton.Create(Self);
  btnCancel := TButton.Create(Self);
  gridView := TDBGrid.Create(Self);
  tmrFind := TTimer.Create(Self);
  wdsFrakcija := TXDataWebDataSet.Create(Self);
  dsFrakcija := TDataSource.Create(Self);

  panFooter.BeforeLoadDFMValues;
  btnSelect.BeforeLoadDFMValues;
  btnCancel.BeforeLoadDFMValues;
  gridView.BeforeLoadDFMValues;
  tmrFind.BeforeLoadDFMValues;
  wdsFrakcija.BeforeLoadDFMValues;
  dsFrakcija.BeforeLoadDFMValues;
  try
    Name := 'frmFrakcija';
    Left := 0;
    Top := 0;
    Align := alClient;
    Color := clBtnFace;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    panFooter.SetParentComponent(Self);
    panFooter.Name := 'panFooter';
    panFooter.Left := 0;
    panFooter.Top := 764;
    panFooter.Width := 752;
    panFooter.Height := 120;
    panFooter.Align := alBottom;
    panFooter.BorderStyle := bsNone;
    panFooter.ChildOrder := 3;
    panFooter.Color := clWhite;
    panFooter.Padding.Top := 5;
    panFooter.TabOrder := 0;
    btnSelect.SetParentComponent(panFooter);
    btnSelect.Name := 'btnSelect';
    btnSelect.AlignWithMargins := True;
    btnSelect.Left := 577;
    btnSelect.Top := 10;
    btnSelect.Width := 165;
    btnSelect.Height := 107;
    btnSelect.Margins.Top := 5;
    btnSelect.Margins.Right := 10;
    btnSelect.Align := alRight;
    btnSelect.Caption := '<i class="material-icons" style="font-size:40px;">done</i>';
    btnSelect.ChildOrder := 1;
    btnSelect.ElementClassName := 'btn btn-success';
    btnSelect.ElementFont := efCSS;
    btnSelect.Enabled := False;
    btnSelect.HeightPercent := 100.000000000000000000;
    btnSelect.WidthPercent := 100.000000000000000000;
    SetEvent(btnSelect, Self, 'OnClick', 'tblStrankeDblClick');
    btnCancel.SetParentComponent(panFooter);
    btnCancel.Name := 'btnCancel';
    btnCancel.AlignWithMargins := True;
    btnCancel.Left := 10;
    btnCancel.Top := 8;
    btnCancel.Width := 111;
    btnCancel.Height := 109;
    btnCancel.Margins.Left := 10;
    btnCancel.Margins.Right := 10;
    btnCancel.Align := alLeft;
    btnCancel.Caption := '<i class="material-icons" style="font-size:40px;">keyboard_backspace</i>';
    btnCancel.ChildOrder := 1;
    btnCancel.ElementClassName := 'btn btn-danger';
    btnCancel.ElementFont := efCSS;
    btnCancel.HeightPercent := 100.000000000000000000;
    btnCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCancel, Self, 'OnClick', 'btnCancelClick');
    gridView.SetParentComponent(Self);
    gridView.Name := 'gridView';
    gridView.Left := 0;
    gridView.Top := 0;
    gridView.Width := 752;
    gridView.Height := 764;
    gridView.Align := alClient;
    gridView.Color := clWhite;
    gridView.Columns.Clear;
    with gridView.Columns.Add do
    begin
      ElementClassName := 'display-1';
      DataField := 'Opis';
      Title := 'Opis';
      Width := 640;
    end;
    gridView.DataSource := dsFrakcija;
    gridView.FixedFont.Charset := DEFAULT_CHARSET;
    gridView.FixedFont.Color := clWindowText;
    gridView.FixedFont.Height := -12;
    gridView.FixedFont.Name := 'Segoe UI';
    gridView.FixedFont.Style := [];
    gridView.FixedCols := 0;
    gridView.FixedRows := 0;
    gridView.Font.Charset := DEFAULT_CHARSET;
    gridView.Font.Color := clWindowText;
    gridView.Font.Height := -83;
    gridView.Font.Name := 'Tahoma';
    gridView.Font.Style := [];
    gridView.Options := [goColSizing,goRowSelect];
    gridView.ParentFont := False;
    gridView.TabOrder := 1;
    gridView.HeightPercent := 100.000000000000000000;
    gridView.ShowIndicator := False;
    gridView.WidthPercent := 100.000000000000000000;
    SetEvent(gridView, Self, 'OnDblClick', 'tblStrankeDblClick');
    gridView.ColWidths[0] := 640;
    tmrFind.SetParentComponent(Self);
    tmrFind.Name := 'tmrFind';
    tmrFind.Interval := 100;
    SetEvent(tmrFind, Self, 'OnTimer', 'tmrFindTimer');
    tmrFind.Left := 236;
    tmrFind.Top := 216;
    wdsFrakcija.SetParentComponent(Self);
    wdsFrakcija.Name := 'wdsFrakcija';
    wdsFrakcija.AfterOpen := wdsFrakcijaAfterOpen;
    wdsFrakcija.EntitySetName := 'Frakcija';
    wdsFrakcija.Connection := modMain.connServer;
    wdsFrakcija.Left := 104;
    wdsFrakcija.Top := 216;
    dsFrakcija.SetParentComponent(Self);
    dsFrakcija.Name := 'dsFrakcija';
    dsFrakcija.DataSet := wdsFrakcija;
    dsFrakcija.Left := 104;
    dsFrakcija.Top := 272;
  finally
    panFooter.AfterLoadDFMValues;
    btnSelect.AfterLoadDFMValues;
    btnCancel.AfterLoadDFMValues;
    gridView.AfterLoadDFMValues;
    tmrFind.AfterLoadDFMValues;
    wdsFrakcija.AfterLoadDFMValues;
    dsFrakcija.AfterLoadDFMValues;
  end;
end;

end.
